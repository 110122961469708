import React from "react";
import { MediaQuery, Grid, Text } from "@mantine/core";

const BrandSection = () => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  return (
    <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
      <Grid.Col xs={12} sm={6} lg={5} p={0}>
        <div className="log-right">
          <div className="log-img">
            <div className="loc-img loc-1">
              <img
                // src="images/location-icon.svg"
                src={PF + "location-icon.svg"}
                alt="location icon"
              />
            </div>
            <div className="loc-img loc-2">
              <img src={PF + "location-icon.svg"} alt="location icon" />
            </div>
            <div className="loc-img loc-3">
              <img src={PF + "location-icon.svg"} alt="location icon" />
            </div>
            <div className="logo">
              <img src={PF + "logo.png"} alt="logo" />
            </div>
            <div className="text-box">
              <Text className="text" color={"#fff"}>
                Powerful.
                <br /> Insights.
                <br /> Easy.
                <br />
              </Text>
            </div>
            <img src={PF + "login-bg.svg"} alt="login background" />
          </div>
          {/* <div className="log-info">
            <div className="log-info-row">
              <div className="log-avatar">
                <img src={PF + "avatar-dummy.jpeg"} alt="user avatar" />
              </div>
              <div className="info-text">
                <span className="quote">"</span>
                <p>
                  I was able to discover my exact target audience in just a few
                  clicks
                </p>
                <span>Sally Anne - Head of Marketing</span>
              </div>
            </div>
          </div> */}
        </div>
      </Grid.Col>
    </MediaQuery>
  );
};

export default BrandSection;
