import { createStyles, Skeleton } from "@mantine/core";
import React, { FC } from "react";

const useStyles = createStyles((theme) => ({
  tableBody: {
    textAlign: "start",
  },
  boundaryMap: {
    display: "flex",
    alignItems: "center",
  },
  iconDiv: {
    marginRight: "17px",
  },
  userCreation: {
    display: "flex",
    alignItems: "center",
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
  },
  infoMapDiv: {
    flex: "1",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.colors.allColors[2],
  },
  mapDetails: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  updatedAt: {
    color: theme.colors.allColors[8],
  },
  imgDiv: {
    marginRight: "10px",
  },
}));

type loaderProps = {
  rows: number,
}

const MyMapsListSkeleton: FC<loaderProps> = (props) => {
  const { classes } = useStyles();
  const totalRows = [];
  for(let i = 0; i<props.rows; i++)
  {
    totalRows.push( 
    <tr key={i}>
      <td>
        <div className={classes.boundaryMap}>
          <div className={classes.iconDiv}>
            <span>
              <Skeleton height={30} circle />
            </span>
          </div>
          <div className={classes.infoMapDiv}>
            <div className={classes.title}>
              <Skeleton height={13} width="70%" radius="xl" />
            </div>
            <div>
              <div className={classes.mapDetails}>
                <span>
                  <Skeleton height={13} mt={6} radius="xl" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className={classes.userCreation}>
          <div className={classes.imgDiv}>
            <Skeleton height={30} circle />
          </div>
          <div className={classes.textDiv}>
            <Skeleton height={13} mt={0} width="60%" radius="xl" />
            <Skeleton height={13} mt={6} width="90%" radius="xl" />
          </div>
        </div>
      </td>
      <td className={classes.updatedAt}>
        <Skeleton height={13} width="60%" radius="xl" />
      </td>
    </tr>)
  };
  
  return <tbody className={classes.tableBody}>{totalRows}</tbody>;
};

export default MyMapsListSkeleton;
