import { Grid, Group, Text, createStyles } from "@mantine/core";
import { useState, useEffect, useContext } from "react";
import storeContext from "../../store/store";
import { FilterType } from "../filterbar-utils/filtertypes";

const HeatMapKey = ({ selectedData }: { selectedData: any }) => {
  const { state } = useContext<any>(storeContext);
  const [HeatMapLabel, setHeatMapLabel] = useState<string>(
    selectedData.filter_title
  );
  const [minBarValue, setMinBarValue] = useState<number | string>(
    selectedData.min
  );
  const [maxBarValue, setMaxBarValue] = useState<number | string>(
    selectedData.max
  );
  const [benchmarkedAverage, setBenchmarkedAverage] = useState<number>(50);

  const prefix = "";
  const postfix = selectedData.units || "";

  useEffect(() => {
    setHeatMapLabel(selectedData.filter_title);
    setMinBarValue(selectedData.min);
    setMaxBarValue(selectedData.max);
    setBenchmarkedAverage(getBenchmarkedAverage());
  }, [selectedData]);

  function getBenchmarkedAverage(): number {
    let benchmarkedAverage = 0;

    if ("state" in selectedData && "national" in selectedData) {
      if (state.benchmark === "state") {
        benchmarkedAverage = selectedData.state || 0;
      } else {
        benchmarkedAverage = selectedData.national || 0;
      }
    } else if ("responses" in selectedData) {
      const response = selectedData.responses.find(
        (r: { filter_name: any }) => r.filter_name === selectedData.filter_name
      );
      if (response) {
        if (state.benchmark === "state") {
          benchmarkedAverage = response.state || 0;
        } else {
          benchmarkedAverage = response.national || 0;
        }
      }
    }

    // console.log("Benchmarked Average " + benchmarkedAverage);

    let avgPercentage = (benchmarkedAverage / selectedData.max) * 100;

    //console.log("Benchmarked Average Percentage " + avgPercentage);

    // return average as a percentage of the max value and clamp value
    return avgPercentage > 100 ? 100 : avgPercentage < 0 ? 0 : avgPercentage;
  }

  // custom styles for this component
  const useStyle = createStyles((theme) => ({
    row: {
      paddingBottom: 0,
      paddingTop: 5,
      paddingLeft: 0,
      paddingRight: 0,
    },
    polygonSvg: {
      width: 8,
      height: 8,
    },
    polygonSvgAvg: {
      width: 8,
      height: 8,
    },
    benchmarkedStateAverage: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing.xs,
      textAlign: "right",
      fontSize: theme.fontSizes.xs,
      color: theme.colors.dimgray,
    },
  }));

  const { classes } = useStyle();

  return (
    <Grid
      justify="space-between"
      style={{ backgroundColor: "white", padding: 5, borderRadius: 8 }}
    >
      <Grid.Col span={12} className={classes.row}>
        <Text size="md" weight={500}>
          {HeatMapLabel}
        </Text>
      </Grid.Col>

      <Grid.Col span={12} className={classes.row}>
        <Group position="apart">
          <Text size="md" weight="bold">
            {prefix + minBarValue + postfix}
          </Text>
          <Text size="md" weight="bold">
            {prefix + maxBarValue + postfix}
          </Text>
        </Group>

        <div style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: "-20px", // Adjust this value to move the arrow up or down
              left: `${benchmarkedAverage}%`, // Adjust this value to move the arrow left or right
              transform: "translateX(-50%)",
            }}
          >
            <img src="/images/indicator-black.svg" alt="" />
          </div>

          <div
            style={{
              background: `linear-gradient(90deg, ${"#fb4b4c"}, ${"#ffffff"})`,
              height: "12px",
              transform: "rotate(180deg)",
            }}
          ></div>
        </div>
      </Grid.Col>

      <Grid.Col span={12} className={classes.row}>
        <Group position="right" className={classes.benchmarkedStateAverage}>
          <img
            src="/images/indicator-grey.svg"
            alt=""
            className={classes.polygonSvg}
          />
          <Text color="#646464" weight={300}>
            Benchmarked {state.benchmark} average
          </Text>
        </Group>
      </Grid.Col>

      <Grid.Col span={12} className={classes.row}>
        <Group position="apart">
          <Text size="md">Powered by</Text>
          <img src="/images/scopomap-wide-light-1.svg" alt="" height="24" />
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default HeatMapKey;
