import { map } from "jquery";

type CriteriasType = {
  [key: string]: string;
};

let infoWindows: google.maps.InfoWindow[] = [];

interface FilterData {
  filter_name: string;
  filter_title: string;
  value?: number;
  units?: string;
  state?: number;
  national?: number;
  min: number;
  max: number;
}

interface FilterDataWithResponses {
  filter_name: string;
  filter_title: string;
  top_answers: number;
  responses: MultipleResponse[];
}

export interface MultipleResponse {
  area: number;
  min: number;
  max: number;
  state: number;
  national: number;
  filter_name: string;
}

type FilterType = FilterData | FilterDataWithResponses;

const processColorRanges = (dimensionValues: number[]) => {
  const maximum = Math.max(...dimensionValues);
  const minimum = Math.min(...dimensionValues);
  const range = maximum - minimum;

  return dimensionValues.map((value) => {
    const placeInRange = 255 - Math.round(((value - minimum) * 255) / range);
    const gbColorValues = placeInRange.toString(16).padStart(2, "0");
    return `#ff${gbColorValues}${gbColorValues}`;
  });
};

interface PolygonData {
  polygon: google.maps.Polygon;
  infoWindow: google.maps.InfoWindow;
}

const setInfoWindow = (
  polygon: google.maps.Polygon,
  item: any,
  heatmapTitle: string,
  heatmapSelected: string,
  units: string
): google.maps.InfoWindow => {
  const polygonValue = item.data?.[heatmapSelected]?.value ?? 0;
  let unitstring =
    units === "$" ? `${units}${polygonValue}` : `${polygonValue}${units}`;
  let tooltipString = `${heatmapTitle || heatmapSelected} - ${unitstring}`;
  //console.log(units);

  let html = `<div><p class="value-p">${tooltipString}</p></div>`;
  if (item.data["fullData"] === false && polygonValue === 0) {
    html = `<div><p class="value-p">This area is missing demographic data.</p></div>`;
  }

  const polygonBounds = new google.maps.LatLngBounds();
  polygon.getPath().forEach((latLng: google.maps.LatLng) => {
    polygonBounds.extend(latLng);
  });

  const infoWindow = new google.maps.InfoWindow({
    content: html,
    ariaLabel: "sa1s-infowindow",
  });

  polygon.addListener("mouseover", () => {
    infoWindows.forEach((iw) => {
      iw.close();
    });
    const latLng = polygonBounds.getCenter();
    infoWindow.setPosition(latLng);
    infoWindow.open(polygon.getMap());
  });

  polygon.addListener("mouseout", () => {
    infoWindows.forEach((iw) => {
      iw.close();
    });
  });

  return infoWindow;
};

export const setHeatMap = (mapPolygons: any, heatmapValue: FilterType) => {
  //console.log("setHeatMap", heatmapValue);
  const dimensionValues: number[] = [];
  const polygonsToChange: PolygonData[] = [];
  let heatmapSelected = "";
  let units = "";

  //console.log(mapPolygons);

  mapPolygons.forEach((item: any) => {
    const { polygon } = item;
    if (typeof polygon.getPath() === "undefined") return;

    if (heatmapValue !== undefined && heatmapValue !== null) {
      const heatmapTitle = heatmapValue.filter_title || "";
      heatmapSelected = heatmapValue.filter_name;
      const filterUnits = "units" in heatmapValue ? heatmapValue.units : "";
      units = filterUnits || "";

      const infoWindow = setInfoWindow(
        polygon,
        item,
        heatmapTitle,
        heatmapSelected,
        units
      );
      polygonsToChange.push({ polygon, infoWindow });
      infoWindows.push(infoWindow);
    }

    const polygonValue = item.data?.[heatmapSelected]?.value ?? 0;
    dimensionValues.push(polygonValue);
  });

  //console.log("Dimension values", dimensionValues);

  const colors = processColorRanges(dimensionValues);

  //console.log("Colors", colors);

  polygonsToChange.forEach(({ polygon }, index) => {
    polygon.setOptions({ fillColor: colors[index] });
  });
};
