import { useAuth } from "../hooks/useAuth";

import Main from "./Main";

const SA1Map = () => {
  const { user } = useAuth();

  return <Main isSharedMap={false} isLGAMap={false} isSALMap={false} isSA1Map={true} loggedIn={user} />;
};

export default SA1Map;
