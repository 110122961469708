import { useEffect, useRef } from "react";

import mapSetup from "../map-utils/mapSetup";
import DrawingControlls from "./map-components/DrawingControlls";
import { useStore } from "../hooks/useStore";
import { useAuth } from "../hooks/useAuth";
import { useParams } from "react-router-dom";
import { openSavedMap } from "../map-utils/openSavedMap";
import { fetchDemographicsSettingsList } from "../map-utils/filterbarAPI";

function Map() {
  const { id, guid, lgaCode16, salCode21, sa1Code7Digit } = useParams();
  const defaultCenter = { lat: -25.779038, lng: 133.1311444 };
  const { state, dispatch } = useStore();
  const { user } = useAuth();

  const mapRef = useRef<HTMLDivElement>(null);

  const openSaved = async (map: any) => {
    let jobName = await openSavedMap(
      id,
      user,
      map,
      dispatch,
      state,
      user? user.key: '',
      guid,
      lgaCode16,
      salCode21,
      sa1Code7Digit
    );
    dispatch({ type: "setJobName", payload: jobName });
    dispatch({ type: "addJobId", payload: id });
  };

  const openLGA = async (map: any) => {
    // await openLGAMap(lgaCode16, user, map, dispatch, state);
  };

  const loadMap = async (
    zoomLevel: number,
    center: { lat: number; lng: number }
  ) => {
    const { map, drawingMngr } = mapSetup(mapRef, zoomLevel, center);
    if ((id || guid || lgaCode16 || salCode21 || sa1Code7Digit) && map) {
      openSaved(map);
      dispatch({ type: "setMap", payload: map });
      dispatch({ type: "setDrawingManager", payload: drawingMngr });
    } else if (lgaCode16 || (salCode21 && map)) {
      openLGA(map);
      dispatch({ type: "setMap", payload: map });
      dispatch({ type: "setDrawingManager", payload: drawingMngr });
    } else {
      if (mapRef.current) {
        dispatch({ type: "setMap", payload: map });
        dispatch({ type: "setDrawingManager", payload: drawingMngr });
      }
    }

    // pre-fetch the demographics settings list
    fetchDemographicsSettingsList(state, dispatch, { userKey: user? user.key: '' });
  };

  const handleGeolocationError = (error: GeolocationPositionError) => {
    let errorMessage = "";
    switch (error.code) {
      case 1:
        errorMessage = "Location access was denied. Using default location.";
        break;
      case 2:
        errorMessage = "Location information is unavailable. Using default location.";
        break;
      case 3:
        errorMessage = "Location request timed out. Using default location.";
        break;
      default:
        errorMessage = "An unknown error occurred. Using default location.";
    }
    console.warn(errorMessage);
    loadMap(4.4, defaultCenter);
  };

  useEffect(() => {
    // dispatch({ type: "emptyPolygons" });
    // dispatch({ type: "showSummaryBar", payload: false });
    // dispatch({ type: "emptySummaryBarData", payload: null });
    // dispatch({ type: "emptyMarkers", payload: null });
    // dispatch({ type: "resetJobId", payload: null });
    dispatch({ type: "resetStates", payload: null });
  }, [id, dispatch]);

  useEffect(() => {
    if (!navigator.geolocation) {
      console.warn("Geolocation is not supported by this browser. Using default location.");
      loadMap(4.4, defaultCenter);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      function (position) {
        let userCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        loadMap(state.zoomLevel, userCenter);
      },
      handleGeolocationError,
      {
        timeout: 10000,        // 10 second timeout
        maximumAge: 300000,    // Accept cached positions up to 5 minutes old
        enableHighAccuracy: false
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, id]);

  return (
    <>
      <div
        style={{ width: "100%", height: "100%" }}
        ref={mapRef}
        className="map"
      ></div>
      {guid ? null : <DrawingControlls />}
    </>
  );
}

export default Map;
