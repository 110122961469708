/* FilterBuilderModal.tsx */

import {
  Modal,
  Stack,
  Text,
  Button,
  ScrollArea,
  Tabs,
  createStyles,
  Select,
  Box,
  MediaQuery,
  Group,
  Indicator,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import storeContext from "../../../store/store";
import {
  getAvaliableCatalogue,
  UpdateSettingsData,
} from "../../../map-utils/filterbarAPI";
import { useAuth } from "../../../hooks/useAuth";
import OptionsList from "./OptionsList";
import FiltersPanel from "./FiltersPanel";
import Sidebar from "./Sidebar";
import { showNotification } from "@mantine/notifications";
import { updateDemographicsSettings } from "../../../map-utils/filterbarAPI";
import { FilterTable, SavedFilter } from "../../../types";
import FilterSaveModal from "../FilterSaveModal";

interface FilterBuilderModalProps {
  setFilterList: (filters: any) => void;
  filterList: FilterTable[];
  listOfUserSavedFilters: SavedFilter[];
  selectedFilterName: string | null;
  setSelectedFilterName: (name: string | null) => void;
}

const useStyles = createStyles((theme) => ({
  modalContent: {
    padding: 0,
  },
  mainFlex: {
    alignItems: "flex-start",
    minHeight: "60vh",
    width: "100%",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: "column",
    },
  },
  tabsList: {
    borderRight: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.colors.gray[0],
    minWidth: "200px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      display: "none",
    },
  },
  mobileControls: {
    padding: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  mobileSelect: {
    width: '100%',
  },
  mobileFilterInfo: {
    padding: theme.spacing.xs,
    backgroundColor: theme.colors.gray[0],
    borderRadius: theme.radius.sm,
  },
  tab: {
    padding: theme.spacing.md,
    borderRadius: 0,
    color: theme.colors.gray[7],
    fontWeight: 500,
    backgroundColor: theme.white,
    "&[data-active]": {
      backgroundColor: theme.colors.orange[0],
      color: theme.colors.orange[7],
      borderRight: `2px solid ${theme.colors.orange[6]}`,
    },
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  tabLabel: {
    flex: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  mobileTabSelect: {
    width: "100%",
    marginBottom: theme.spacing.md,
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.md,
    backgroundColor: theme.white,
  },
  footerButton: {
    "&:hover": {
      backgroundColor: theme.colors.orange[1],
    },
  },
  indicator: {
    fontWeight: 600,
  },
  contentArea: {
    flex: 1,
    backgroundColor: theme.colors.gray[0],
  },
}));

const FilterBuilderModal: React.FC<FilterBuilderModalProps> = ({
  setFilterList,
  filterList,
  listOfUserSavedFilters,
  selectedFilterName,
  setSelectedFilterName,
}) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const [filters, setFilters] = useState<FilterTable[]>([]);
  const [isEdited, setIsEdited] = useState(false);
  const [demographicsSettingsId, setDemographicsSettingsId] = useState<
    string | null
  >(null);
  const { user } = useAuth();
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState<string | null>(null);

  useEffect(() => {
    getAvaliableCatalogue().then((response) => {
      setFilters(response);
    });
  }, []);

  // Set initial active tab
  useEffect(() => {
    if (filters.length > 0 && !activeTab) {
      setActiveTab(filters[0]?.category_title || null);
    }
  }, [filters, activeTab]);

  // Simplified getCheckedFilters function
  const getCheckedFilters = () => {
    const checkedFilters: FilterTable[] = [];

    filters.forEach((table) => {
      const newTable: FilterTable = {
        table_name: table.table_name,
        category_title: table.category_title,
        sub_categories: [],
      };

      table.sub_categories.forEach((subCategory) => {
        const checkedSubCategoryFilters = subCategory.filters.filter(
          (filter) => filter.checked
        );

        if (checkedSubCategoryFilters.length > 0) {
          newTable.sub_categories.push({
            sub_category_title: subCategory.sub_category_title,
            filters: checkedSubCategoryFilters,
          });
        }
      });

      if (newTable.sub_categories.length > 0) {
        checkedFilters.push(newTable);
      }
    });

    return checkedFilters;
  };

  const areFiltersDifferent = (
    currentFilters: FilterTable[],
    settingFilters: FilterTable[]
  ) => {
    const serializeFilters = (filters: FilterTable[]) =>
      JSON.stringify(
        filters
          .map((f) => ({
            table_name: f.table_name,
            sub_categories: f.sub_categories.map((s) => ({
              sub_category_title: s.sub_category_title,
              filters: s.filters.map((filter) => filter.filter_name), // Only compare filter names
            })),
          }))
          .sort() // Sort for consistent comparison
      );

    return (
      serializeFilters(currentFilters) !== serializeFilters(settingFilters)
    );
  };

  const getFilterPresetByID = (id: string) => {
    return listOfUserSavedFilters.find(
      (filter) => filter.demographics_settings_id === parseInt(id)
    );
  };

  useEffect(() => {
    if (demographicsSettingsId) {
      const selectedFilter = getFilterPresetByID(demographicsSettingsId);
      setSelectedFilterName(selectedFilter?.settings_name ?? "custom");
    }
  }, [demographicsSettingsId, listOfUserSavedFilters, setSelectedFilterName]);

  useEffect(() => {
    if (demographicsSettingsId) {
      const selectedFilter = getFilterPresetByID(demographicsSettingsId);
      if (selectedFilter) {
        const savedFilters = JSON.parse(
          selectedFilter.demographics_settings_json
        );
        setIsEdited(areFiltersDifferent(getCheckedFilters(), savedFilters));
      }
    }
  }, [
    filters,
    demographicsSettingsId,
    listOfUserSavedFilters,
    getCheckedFilters,
  ]);

  const applyFiltersAndClose = () => {
    const checkedFilters = getCheckedFilters();
    setFilterList(checkedFilters);

    if (selectedFilterName === "custom") {
      setSelectedFilterName("custom"); // Ensure "custom" is set correctly
    }

    dispatch({ type: "setDemographicBuilderOpen", payload: false });
    setIsEdited(false); // Reset edited state
  };

  const handleApply = () => {
    applyFiltersAndClose();
  };

  const handleSaveAndApply = async () => {
    const checkedFilters = getCheckedFilters();

    if (!selectedFilterName || selectedFilterName === "custom") {
      showNotification({
        title: "Filter Selection Required",
        message:
          "Please choose an existing filter or create a new one to continue.",
        color: "red",
      });
      return;
    }

    if (!demographicsSettingsId) {
      showNotification({
        title: "Missing demographicsSettingsId",
        message: "Demographics settings ID is missing",
        color: "red",
      });
      return;
    }

    const selectedFilter = getFilterPresetByID(demographicsSettingsId);

    if (!selectedFilter) {
      showNotification({
        title: "Error",
        message: "Selected filter not found",
        color: "red",
      });
      return;
    }

    if (selectedFilter.organisation_default) {
      showNotification({
        title: "Cannot Update Default Filter",
        message:
          "To update the default filter, please create a new filter and set it as default.",
        color: "yellow",
      });
      return;
    }

    const userFilter: UpdateSettingsData = {
      demographicsSettingsId,
      demographicsSettingsJson: JSON.stringify(checkedFilters),
      settingsName: selectedFilterName,
      organisationDefault: selectedFilter.organisation_default
        ? "True"
        : "False",
    };

    try {
      await updateDemographicsSettings(userFilter, user.key);

      const updatedList = listOfUserSavedFilters.map((filter) => {
        if (
          filter.demographics_settings_id === parseInt(demographicsSettingsId)
        ) {
          return {
            ...filter,
            settings_name: selectedFilterName,
            demographics_settings_json: JSON.stringify(checkedFilters),
          };
        }
        return filter;
      });

      setSelectedFilterName(selectedFilterName);

      dispatch({
        type: "setUserDemographicSettingsList",
        payload: updatedList,
      });

      showNotification({
        title: "Filter saved",
        message: "Your filter has been saved",
        color: "green",
      });
      applyFiltersAndClose();
    } catch (error) {
      showNotification({
        title: "Error saving filter",
        message: "There was an error saving the filter",
        color: "red",
      });
    }
  };

  const handleModalClose = () => {
    dispatch({ type: "setDemographicBuilderOpen", payload: false });
    setIsEdited(false); // Reset on close
  };

  const handleSaveClick = () => {
    dispatch({ type: "setDemographicSaveOpen", payload: true });
    const checkedFilters = getCheckedFilters();
    dispatch({ type: "updateCheckedFilters", payload: checkedFilters });
  };

  const selectedFilter = demographicsSettingsId
    ? getFilterPresetByID(demographicsSettingsId)
    : null;
  const isOrgDefault = selectedFilter?.organisation_default ?? false;

  if (!state.demographicBuilderVisible) return null;

  return (
    <>
      <Modal
        opened={state.demographicBuilderVisible}
        onClose={handleModalClose}
        size="90%"
        title={
          <FiltersPanel
            filters={filters}
            isEdited={isEdited}
            handleSaveClick={handleSaveClick}
          />
          
        }
        classNames={{ content: classes.modalContent }}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <Stack spacing={0}>
          <Group className={classes.mainFlex} spacing={0}>
            {/* Desktop View */}
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Tabs
                value={activeTab}
                onTabChange={setActiveTab}
                orientation="vertical"
                classNames={{
                  root: classes.tabsList,
                  tab: classes.tab,
                }}
              >
                <Tabs.List>
                  {filters.map((table) => {
                    const activeFilters = table.sub_categories.reduce(
                      (acc, subCategory) =>
                        acc +
                        subCategory.filters.filter((filter) => filter.checked)
                          .length,
                      0
                    );

                    return (
                      <Tabs.Tab
                        key={table.category_title}
                        value={table.category_title}
                      >
                        <Group position="apart" spacing="xs">
                          {activeFilters > 0 ? (
                            <Indicator
                              size={16}
                              className={classes.indicator}
                              label={activeFilters}
                            >
                              <Text className={classes.tabLabel}>
                                {table.category_title}
                              </Text>
                            </Indicator>
                          ) : (
                            <Text className={classes.tabLabel}>
                              {table.category_title}
                            </Text>
                          )}
                        </Group>
                      </Tabs.Tab>
                    );
                  })}
                </Tabs.List>
              </Tabs>
            </MediaQuery>

            {/* Mobile Controls */}
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Box className={classes.mobileControls}>
                <Stack spacing="sm">
                  <Box>
                    <Text size="sm" weight={500} mb={4}>
                      Category
                    </Text>
                    <Select
                      className={classes.mobileSelect}
                      value={activeTab}
                      onChange={(value) => setActiveTab(value)}
                      data={filters.map((table) => {
                        const activeFilters = table.sub_categories.reduce(
                          (acc, subCategory) =>
                            acc +
                            subCategory.filters.filter((filter) => filter.checked)
                              .length,
                          0
                        );
                        return {
                          value: table.category_title,
                          label: `${table.category_title}${
                            activeFilters > 0 ? ` (${activeFilters})` : ""
                          }`,
                        };
                      })}
                      placeholder="Select demographic category"
                      searchable
                      size="md"
                    />
                  </Box>

                  <Box>
                    <Text size="sm" weight={500} mb={4}>
                      Saved Filters
                    </Text>
                    <Select
                      className={classes.mobileSelect}
                      value={selectedFilterName || ""}
                      onChange={(value) => {
                        if (value) {
                          const filter = listOfUserSavedFilters.find(
                            (f) => f.settings_name === value
                          );
                          if (filter) {
                            setSelectedFilterName(value);
                            setDemographicsSettingsId(
                              filter.demographics_settings_id.toString()
                            );
                            const savedFilters = JSON.parse(
                              filter.demographics_settings_json
                            );
                            setFilters((currentFilters) =>
                              currentFilters.map((table) => ({
                                ...table,
                                sub_categories: table.sub_categories.map(
                                  (subCategory) => ({
                                    ...subCategory,
                                    filters: subCategory.filters.map(
                                      (filter) => ({
                                        ...filter,
                                        checked: savedFilters.some(
                                          (f: FilterTable) =>
                                            f.table_name === table.table_name &&
                                            f.category_title ===
                                              table.category_title &&
                                            f.sub_categories.some(
                                              (sub: {
                                                sub_category_title: string;
                                                filters: {
                                                  filter_name: string;
                                                }[];
                                              }) =>
                                                sub.sub_category_title ===
                                                  subCategory.sub_category_title &&
                                                sub.filters.some(
                                                  (f: { filter_name: string }) =>
                                                    f.filter_name ===
                                                    filter.filter_name
                                                )
                                            )
                                        ),
                                      })
                                    ),
                                  })
                                ),
                              }))
                            );
                          }
                        }
                      }}
                      data={[
                        { value: "custom", label: "Custom Filter" },
                        ...listOfUserSavedFilters.map((filter) => ({
                          value: filter.settings_name,
                          label: `${filter.settings_name}${
                            filter.organisation_default ? " (Default)" : ""
                          }`,
                        })),
                      ]}
                      placeholder="Select saved filter"
                      searchable
                      size="md"
                    />
                  </Box>

                  {selectedFilterName && (
                    <Box className={classes.mobileFilterInfo}>
                      <Group position="apart" spacing="xs">
                        <Text size="sm" color="dimmed">
                          Active filter:
                        </Text>
                        <Text size="sm" weight={500}>
                          {selectedFilterName}
                          {isEdited && (
                            <Text component="span" color="orange" ml={4}>
                              • Edited
                            </Text>
                          )}
                        </Text>
                      </Group>
                    </Box>
                  )}
                </Stack>
              </Box>
            </MediaQuery>

            <Box className={classes.contentArea}>
              {activeTab && (
                <ScrollArea h="60vh">
                  {filters
                    .filter((table) => table.category_title === activeTab)
                    .map((table) => (
                      <OptionsList
                        key={table.table_name}
                        table={table}
                        filters={filters}
                        setFilters={setFilters}
                        setIsEdited={setIsEdited}
                      />
                    ))}
                </ScrollArea>
              )}
            </Box>

            {/* Desktop Sidebar */}
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <ScrollArea h="60vh">
                <Sidebar
                  listOfUserSavedFilters={listOfUserSavedFilters}
                  selectedFilterName={selectedFilterName}
                  setSelectedFilterName={setSelectedFilterName}
                  filters={filters}
                  setFilters={setFilters}
                  setIsEditing={setIsEdited}
                  setDemographicsSettingsId={setDemographicsSettingsId}
                  selectedFilterID={demographicsSettingsId}
                />
              </ScrollArea>
            </MediaQuery>
          </Group>

          <Group position="apart" className={classes.footer}>
            <Group spacing="xs">
              <Text size="sm" color="dimmed">
                Selected filter:
              </Text>
              <Text size="sm" weight={500}>
                {selectedFilterName ?? "None selected"}
              </Text>
              {isEdited && (
                <Text size="sm" color="orange">
                  • Unsaved changes
                </Text>
              )}
            </Group>
            <Group spacing="md">
              <Button
                variant="subtle"
                color="gray"
                onClick={handleModalClose}
                className={classes.footerButton}
              >
                Cancel
              </Button>
              {isOrgDefault ? (
                <Button
                  color="orange"
                  onClick={handleApply}
                  variant="subtle"
                  className={classes.footerButton}
                >
                  Apply
                </Button>
              ) : (
                <Button
                  color="orange"
                  onClick={handleSaveAndApply}
                  variant="subtle"
                  className={classes.footerButton}
                >
                  Save & Apply
                </Button>
              )}
            </Group>
          </Group>
        </Stack>
      </Modal>
      <FilterSaveModal />
    </>
  );
};

export default FilterBuilderModal;
