import { FiltersDataType } from "../types";

export const setFilters = (
  mapPolygons: any[],
  filterDataAlternative: { [key: string]: FiltersDataType },
  state: any,
  dispatch: any
) => {
  const sa1Portions: [string, number][] = [];
  let polygonsEnabled = 0;

  //console.log('checkedOutCatalog', JSON.stringify(state.checkedOutCatalog));
  for (const [key, filterData] of Object.entries(filterDataAlternative)) {
    filterData.activeFilter = false;
    if (Object.keys(state.checkedOutCatalog).length === 0) {
      continue;
    }
    //console.log('filterData: ', key, JSON.stringify(filterData));
    state.checkedOutCatalog.forEach((table: any) => {
      table.sub_categories.forEach((sub_categorie: any) => {
        sub_categorie.filters.forEach((catalogueFilter: any) => {
          if(catalogueFilter.filter_name == key)
            filterData.activeFilter = true;
          if(catalogueFilter.top_answers !== undefined && catalogueFilter.filter_name == filterData.groupName)
            filterData.activeFilter = true;
        });
      });
    });
  };

  mapPolygons.forEach((polygon) => {
    polygon.selected = true;

    for (const [key, filterData] of Object.entries(filterDataAlternative)) {
      // if(filterData.activeFilter === false)
      //   continue;
      //console.log('filterData: ', key, JSON.stringify(filterData));
      let dataEntry = polygon.data[key];

      //console.log("Data entry:", dataEntry);

      if (dataEntry === undefined) {
        //console.log("Missing data:", key, polygon);

        // If data is missing, create a dataentry with a value of 0
        dataEntry = {
          national: 0,
          state: 0,
          units: "",
          value: 0,
        };
      }

      const { minValue, maxValue } = filterData;
      if (dataEntry.value < minValue || dataEntry.value > maxValue) {
        polygon.selected = false;
        //console.log("Polygon:", polygon);
      }
    }

    if (polygon.selected) {
      polygon.polygon.setOptions({ fillColor: "green" });
      sa1Portions.push([polygon.data.sa1_code, polygon.intersected_percentage]);
      polygonsEnabled++;
    } else {
      polygon.polygon.setOptions({ fillColor: "#2F4F4F" });
    }

    polygon.polygon.setOptions({ fillOpacity: 0.7 });
  });

  // Dispatch an action with the updated sa1Portions and polygonsEnabled if needed
};
