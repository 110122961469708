// Imports
import {
  Navbar,
  Title,
  Divider,
  Button,
  Tooltip,
  Grid,
  Col,
  Stack,
  Flex,
  Text,
  Select,
  Box,
  Group,
  Paper,
} from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import axios from "axios";
import storeContext from "../store/store";
import { setFilters } from "../map-utils/setFilters";
import useElementSize from "../hooks/useElementSize";
import { fetchLetterBoxCount } from "../map-utils/fetchLetterBoxCounts";
import { getSummaryData } from "../map-utils/getSummaryData";
import { getBusinessLocations } from "../map-utils/getBusinessLocations";
import { handlePolygonDelete } from "../map-utils/handlePolygonDelete";
import { addTextInsideShape } from "../map-utils/handleTextInsideShape";
import FilterBuilderModal from "./filterbar-utils/FilterBuilder/FilterBuilderModal";
import FilterGroup from "./filterbar-utils//FilterOptions";

import { useAuth } from "../hooks/useAuth";
import {
  Dataset,
  FilterType,
  SubCategory,
  Table,
} from "./filterbar-utils/filtertypes";

import {
  fetchDemographicsSettingsList,
  getAvaliableCatalogue,
} from "../map-utils/filterbarAPI";
import { Filter, FilterTable } from "../types";
import { createStyles } from "@mantine/core";

type Props = {
  openFiltersBar?: boolean;
  openHeatMap?: boolean;
  disableApplyFilters?: boolean;
};

interface SaveSettingsData {
  demographics_settings_id: number;
  demographics_settings_json: string;
  settings_name: string;
  organisation_default: boolean;
}

interface SubCat {
  sub_category_title: string;
  filters: Filter[];
}

export const setCheckedOutCatalog = async (
  userKey: any,
  state: any,
  dispatch: any,
  demographicsSettings: any
) => {
  const [catalogueResponse, demographicSettingsResponse] = await Promise.all([
    getAvaliableCatalogue(),
    fetchDemographicsSettingsList(state, dispatch, { userKey }),
  ]);

  dispatch({ type: "setCompleteCatalog", payload: catalogueResponse });

  // sleep for a second to allow the catalogue to be set
  await new Promise((resolve) => setTimeout(resolve, 3000));

  if (demographicsSettings && demographicsSettings.length > 0) {
    dispatch({
      type: "setCheckedOutCatalog",
      payload: JSON.parse(demographicsSettings),
    });

    return JSON.parse(demographicsSettings);  
  }

  if (demographicSettingsResponse.length > 0) {
    const defaultFilter = demographicSettingsResponse.find(
      (filter: SaveSettingsData) => filter.organisation_default
    );

    if (defaultFilter) {
      dispatch({
        type: "setCheckedOutCatalog",
        payload: JSON.parse(defaultFilter.demographics_settings_json),
      });

      return JSON.parse(defaultFilter.demographics_settings_json);
    } else {
      // TODO: remove this when the default filter is set
      // if there is no default filter, set the first filter
      dispatch({
        type: "setCheckedOutCatalog",
        payload: JSON.parse(
          demographicSettingsResponse[0].demographics_settings_json
        ),
      });
      return JSON.parse(
        demographicSettingsResponse[0].demographics_settings_json
      );
    }
  }

  return null;
};

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.white,
    transition: 'all 0.3s ease',
    borderRight: `1px solid ${theme.colors.gray[2]}`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing.md,
  },
  categoryTitle: {
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    marginBottom: theme.spacing.xs,
    color: theme.colors.gray[7],
    fontWeight: 600,
  },
  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
  },
  actionButton: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.colors.orange[6]}`,
    color: theme.colors.orange[6],
    '&:hover': {
      backgroundColor: theme.colors.orange[0],
    },
    '&:disabled': {
      backgroundColor: theme.colors.gray[1],
      borderColor: theme.colors.gray[4],
      color: theme.colors.gray[6],
    },
  }
}));

const FiltersBar: FC<Props> = ({
  openFiltersBar,
  openHeatMap,
  disableApplyFilters,
}: Props) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const [reloadHandlers, setReloadHandlers] = useState<boolean>(false);
  const { height } = useElementSize("header-main");
  const [tooManySA1s, setTooManySA1s] = useState(false);
  const [openFilterListAlreadyLoaded, setOpenFilterListAlreadyLoaded] =
    useState(false);
  // Removing this as we now update the store directly through the modal
  // const [listOfUserSavedFilters, setListOfUserSavedFilters] = useState<SaveSettingsData[]>([]);
  const [selectedFilterName, setSelectedFilterName] = useState<string | null>(
    null
  ); // Track selected filter name

  const maximumNumberOfPolygonsToFilter = 30;
  const { user } = useAuth();
  const { classes } = useStyles();

  useEffect(() => {
    if (openFiltersBar || openHeatMap) {
      setReloadHandlers(true);
    } else {
      setReloadHandlers(false);
    }
  }, [state.jobId, openFiltersBar, openHeatMap]);

  useEffect(() => {
    if (openFiltersBar && !openHeatMap)
      setFilters(state.mapPolygons, state.filterDataAlternative, state, dispatch);
  }, [
    state.filterDataVersion,
    openFiltersBar,
    openHeatMap,
    dispatch,
    state.mapPolygons,
  ]);

  useEffect(() => {
    let filteredPolygonsCount = 0;
    state.mapPolygons.forEach((p: any) => {
      if (p.selected) filteredPolygonsCount++;
    });
    if (filteredPolygonsCount > maximumNumberOfPolygonsToFilter)
      setTooManySA1s(true);
    else setTooManySA1s(false);
  }, [state.mapPolygons, state.filtersDataToSave]);

  useEffect(() => {
    if (reloadHandlers) {
      state?.mapPolygons?.forEach((p: any) => p.polygon.setMap(null));
      state?.polygons?.forEach((p: any) => {
        state?.mapPolygons.forEach((p: any) => p.polygon.setMap(state.map));
        p.setMap(null);
        p.setEditable(false);
      });
    } else {
      state?.mapPolygons.forEach((p: any) => p.polygon.setMap(null));
      state?.polygons.forEach((p: any) => {
        p.setEditable(false);
        p.setMap(state.map);
      });
    }
  }, [state.map, state.mapPolygons, state.polygons, reloadHandlers]);

  // refresh filters list - refresh the list of filters when a new filter is saved
  useEffect(() => {
    getFiltersList();
  }, [state.demographicSaveVisible]);

  // responds to any changes in the checkedOutCatalog and calls getSummaryData
  useEffect(() => {
    if (Object.entries(state.checkedOutCatalog).length !== 0) {
      dispatch({
        type: "setFilterSelection",
        payload: state.checkedOutCatalog,
      });

      let allPolygons: string = "";
      window.polygons.forEach((polygon: any) => {
        let polygonEncodedPath = google.maps.geometry.encoding.encodePath(
          polygon.getPath()
        );
        if (allPolygons.length > 0) allPolygons += ",";
        allPolygons += polygonEncodedPath;
      });

      // update the summary data if there are polygons
      if (state.mapPolygons.length > 0 && window.polygons.length > 0) {
        getSummaryData(
          state.mapPolygons[0],
          allPolygons,
          state.map,
          dispatch,
          state,
          user.key,
          state.map
        );
      }
    }
  }, [state.checkedOutCatalog]);

  const resetFiltersHandler = async () => {
    document.querySelectorAll("input[type=checkbox]").forEach((el) => {
      const checkbox = el as HTMLInputElement;
      if (checkbox.checked) checkbox.click();
    });
    dispatch({
      type: "setFilterDataEntire",
      payload: {},
    });
  };

  const applyFiltersHandler = async () => {
    if (!state.checkedOutCatalog || state.checkedOutCatalog.length === 0) {
      alert("Please select demographics settings before applying filters. Click the tool icon to add data points.");
      return;
    }

    const accepted = window.confirm(
      `Are you sure you want to apply these filters, this action is irreversible.`
    );
    if (!accepted) return;

    state?.polygons?.forEach((p: any) => {
      handlePolygonDelete(p, dispatch, state, user.key,state.map);
    });
    const filteredPolygons = state.mapPolygons.filter(
      (polygon: any) => polygon.selected
    );
    dispatch({ type: "setPolygons", payload: null });
    let allPolygons: string = "";
    filteredPolygons.forEach((polygon: any) => {
      let gmPolygon = polygon.polygon;
      let polPath = gmPolygon.getPath();
      if(polPath === undefined)
        return;
      let encodedPolygon = google.maps.geometry.encoding.encodePath(polPath);
      allPolygons += encodedPolygon + ",";
      gmPolygon.id = encodedPolygon;
      dispatch({ type: "setPolygons", payload: gmPolygon });
      var polygonBounds = new google.maps.LatLngBounds();
      for (let i = 0; i < polygon.polygon.getPath().getLength(); i++) {
        polygonBounds.extend(polygon.polygon.getPath().getAt(i));
      }
      fetchLetterBoxCount(encodedPolygon, dispatch, encodedPolygon)
        .then((response: any) => {
          if (response) {
            addTextInsideShape(
              polygon.polygon,
              polygonBounds.getCenter(),
              state.map,
              response["Residential count"] + response["Business exact count"]
            );
          }
          return;
        })
        .catch((err) => console.log("error in fetching summary data", err));
    });
    allPolygons = allPolygons.slice(0, -1);
    let annotationList: any[] = [];
    state.annotations.forEach((annotation: any) => {
      let annotationCoordinates = annotation["marker"].getPosition();
      annotationList.push({
        id: 0,
        lat: annotationCoordinates.lat(),
        lng: annotationCoordinates.lng(),
        comment: annotation["comment"],
      });
    });
  
    let formData = new FormData();
    formData.append("job_id", state.jobId || 0);
    formData.append("job_name", state.jobName);
    formData.append("encoded_polygons", allPolygons);
    formData.append("annotationList", JSON.stringify(annotationList));
    formData.append("filter_data", JSON.stringify(state.filterDataAlternative));
    formData.append("demographics_settings", JSON.stringify(state.checkedOutCatalog));

    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}save_job/`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `token ${user.key}`,
      },
    });
    window.location.reload();
    return;
  };

  const createFilterGroups = () => {
    const data: Dataset = JSON.parse(JSON.stringify(state.summaryDataNewAPI));

    if (!data.tables) return;

    if (!Array.isArray(data.tables) || data.tables.length === 0) return;

    if (!state.checkedOutCatalog) return;

    const selected = state.checkedOutCatalog;

    const subCategoriesWithCategory: {
      categoryTitle: string;
      subCategory: SubCategory;
    }[] = [];

    selected?.forEach(
      (table: { sub_categories: any[]; table_name: string }) => {
        table.sub_categories.forEach(
          (subCategory: { sub_category_title: string; filters: any[] }) => {
            let findSubcat = data.tables
              .find((t) => t.table_name === table.table_name)
              ?.sub_categories.find(
                (s) => s.sub_category_title === subCategory.sub_category_title
              ) ?? {
              sub_category_title: subCategory.sub_category_title,
              // Map filters to the correct type
              filters: subCategory.filters
                .filter((filter: { checked: any }) => filter.checked)
                .map((filter: any) => ({
                  ...filter,
                  top_answers: [], // Provide default values for missing properties
                  responses: [],
                })),
            };

            let categoryTitle =
              data.tables.find((t) => t.table_name === table.table_name)
                ?.category_title ?? "";

            // add subcategory with its table category to the list
            subCategoriesWithCategory.push({
              categoryTitle,
              subCategory: findSubcat,
            });
          }
        );
      }
    );

    // group subcategories by their category title
    const groupedSubCategories = subCategoriesWithCategory.reduce(
      (groups, item) => {
        if (!groups[item.categoryTitle]) {
          groups[item.categoryTitle] = [];
        }
        groups[item.categoryTitle].push(item.subCategory);
        return groups;
      },
      {} as { [key: string]: SubCategory[] }
    );

    // create filter groups with category titles
    return Object.keys(groupedSubCategories).map((categoryTitle) => (
      <div key={categoryTitle}>
        <Flex pl="10px" pt="4px" pb="8px">
          <Text ta="left" fw={700} fz="md" tt="capitalize">
            {categoryTitle}
          </Text>
        </Flex>

        {groupedSubCategories[categoryTitle].map((subCategory) => (
          <FilterGroup
            key={subCategory.sub_category_title}
            category={categoryTitle}
            groupName={subCategory.sub_category_title}
            filters={subCategory.filters}
          />
        ))}
      </div>
    ));
  };

  // get filters list
  const getFiltersList = async () => {
    const response = await fetchDemographicsSettingsList(state, dispatch, {
      userKey: user? user.key: '',
    });

    dispatch({
      type: "setUserDemographicSettingsList",
      payload: response,
    });
  };

  function handleExport(): void {
    dispatch({
      type: "setViewExportMapModal",
      payload: { visible: true, type: "filter" },
    });
  }

  // apply filters button label
  let appllyFiltersLabel;
  if (disableApplyFilters) {
    appllyFiltersLabel = "cannot apply filters on shared maps";
  } else if (tooManySA1s) {
    appllyFiltersLabel =
      "Too many areas selected (maximum is " +
      maximumNumberOfPolygonsToFilter.toString() +
      ")";
  } else if (!state.jobId) {
    appllyFiltersLabel = "Filters can only be applied on saved maps"
  } else {
    appllyFiltersLabel = "Apply Filters";
  }

  return (
    <div style={openFiltersBar ? {} : { display: "none" }}>
      <FilterBuilderModal
        setFilterList={(filters: any) => {
          // This function is now used to apply the filters, update the store
          // check if filters is equal to setCheckedOutCatalog and update only if not idetical
          if (
            JSON.stringify(filters) !== JSON.stringify(state.checkedOutCatalog)
          ) {
            dispatch({
              type: "setCheckedOutCatalog",
              payload: filters,
            });
          }
        }}
        filterList={state.checkedOutCatalog}
        listOfUserSavedFilters={
          // check if empty object.entries
          state.userDemographicSettingsList ? (Object.entries(state.userDemographicSettingsList).length ? state.userDemographicSettingsList : []) : []
        }
        selectedFilterName={selectedFilterName}
        setSelectedFilterName={setSelectedFilterName}
      />
      <Navbar
        className={classes.navbar}
        width={{ xs: 350 }}
        sx={{ height: `calc(100vh - ${height}px)`, top: `${height}px` }}
      >
        {state.filterData.setCheck ? (
          <>
            <Box className={classes.header}>
              <Group position="apart">
                <Title order={4}>Filters</Title>
                <Tooltip
                  label="Build your filter set"
                  position="right"
                  withArrow
                  transitionProps={{ transition: "scale-x", duration: 300 }}
                  openDelay={500}
                >
                  <Button
                    className={classes.actionButton}
                    compact
                    onClick={() => handleExport()}
                  >
                    Export
                  </Button>
                </Tooltip>
              </Group>
            </Box>

            <Box className={classes.content}>
              {state.checkedOutCatalog && state.checkedOutCatalog.length > 0 ? (
                <Stack spacing="md">
                  {createFilterGroups()}
                </Stack>
              ) : (
                <Paper p="xl" withBorder>
                  <Text size="sm" align="center" color="dimmed">
                    No demographics selected, click on the tool icon to add data points
                  </Text>
                </Paper>
              )}
            </Box>

            <Box className={classes.footer}>
              <Group position="apart">
                <Button
                  className={classes.actionButton}
                  onClick={resetFiltersHandler}
                >
                  Reset Filters
                </Button>

                <Tooltip
                  closeDelay={1000}
                  width={disableApplyFilters ? 230 : 95}
                  arrowSize={3}
                  withArrow
                  position="right"
                  transitionProps={{ transition: "scale-x", duration: 300 }}
                  openDelay={500}
                  label={appllyFiltersLabel}
                >
                  <Button
                    className={classes.actionButton}
                    onClick={applyFiltersHandler}
                    disabled={disableApplyFilters || tooManySA1s || !state.jobId}
                  >
                    Apply Filters
                  </Button>
                </Tooltip>
              </Group>
            </Box>
          </>
        ) : (
          <Box className={classes.content}>
            <Title order={4} mb="md">Filters</Title>
            <Paper p="xl" withBorder>
              <Text align="center" color="dimmed">
                Please Draw Shape to use filters
              </Text>
            </Paper>
          </Box>
        )}
      </Navbar>
    </div>
  );
};

export default FiltersBar;
