import {
  Aside,
  Title,
  Divider,
  Group,
  LoadingOverlay,
  Text,
  SimpleGrid,
  createStyles,
  ActionIcon,
  Menu,
  Button,
  Tooltip,
  Dialog,
  Stack,
  Skeleton,
  Flex,
  Collapse,
  Progress,
  Indicator,
  Paper,
  ThemeIcon,
  Transition,
  Box,
  rem,
} from "@mantine/core";
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Mailbox,
  BuildingStore,
  Inbox,
  Users,
  ArrowBarLeft,
  ArrowBarRight,
  Download,
  FileSpreadsheet,
  FileAnalytics,
  File3d,
  ChartBubble,
  ChevronDown,
  ChevronUp,
  X,
  Check,
  Loader,
  MessageChatbot,
} from "tabler-icons-react";
import storeContext from "../store/store";
import useElemetHeight from "../hooks/useElementHeight";
import useElementSize from "../hooks/useElementSize";
import ValueAndComparisonToAverage from "./ValueAndComparisonToAverage";
import AIChatWindow from "./AIChatWindow";
import { downloadAsWord, downloadSummary } from "../map-utils/downloadSummary";
import { useAuth } from "../hooks/useAuth";
import {
  Dataset,
  FilterType,
  SubCategory,
} from "./filterbar-utils/filtertypes";
import { AppContextType, State } from "../types";
import { ReportGenerationModal } from './ReportGenerationModal';
import { ReportsDrawer } from './ReportsDrawer';

const useStyles = createStyles((theme) => ({
  aside: {
    backgroundColor: theme.white,
    transition: 'all 0.3s ease',
    borderLeft: `1px solid ${theme.colors.gray[2]}`,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  statsCard: {
    backgroundColor: theme.white,
    padding: theme.spacing.lg,
    borderRadius: theme.radius.md,
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: theme.shadows.sm,
    },
  },
  statValue: {
    fontSize: rem(28),
    fontWeight: 700,
    color: theme.colors.gray[9],
  },
  statLabel: {
    fontSize: rem(14),
    color: theme.colors.gray[6],
    fontWeight: 500,
  },
  summarySection: {
    backgroundColor: theme.white,
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.sm,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
  show: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(0%)",
    },
  },
  hide: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(100%)",
    },
  },
  summaryToggleBtn: {
    display: "none",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(-0%)",
      display: "block",
      height: "37px",
      position: "fixed",
      top: "37.3%",
      right: "0",
      padding: "3px",
      width: "33px",
      zIndex: 1000,
      backgroundColor: theme.colors.allColors[2],
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
  },
}));

function downloadAsCSV(context: AppContextType, user: any) {
  downloadSummary("CSV", context, user);
}

async function requestWordDocument(context: AppContextType, user: any, dispatch: any) {
  let payload = await downloadAsWord(context, user, dispatch);
  return payload;
}

function downloadSA1sData(context: AppContextType, user: any) {
  downloadSummary("sa1s", context, user);
}

interface SummarySectionProps {
  content: React.ReactNode;
  title: string;
}

const StatCard = ({ icon: Icon, label, value }: { icon: any, label: string, value: number | string }) => {
  const { classes } = useStyles();
  
  return (
    <Paper className={classes.statsCard} shadow="xs">
      <Group spacing="sm">
        <ThemeIcon size="lg" radius="md" variant="light" color="orange">
          <Icon size={20} />
        </ThemeIcon>
        <Box>
          <Text className={classes.statValue}>{value}</Text>
          <Text className={classes.statLabel}>{label}</Text>
        </Box>
      </Group>
    </Paper>
  );
};

const SummarySection: React.FC<SummarySectionProps> = ({ content, title }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(true);
  const { classes } = useStyles();

  return (
    <Paper className={classes.summarySection}>
      <div 
        className={classes.sectionHeader}
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
      >
        <Text fw={600} size="sm">{title}</Text>
        <ActionIcon
          variant="subtle"
          color="gray"
          size="sm"
        >
          {isDropDownOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </ActionIcon>
      </div>
      <Collapse in={isDropDownOpen}>
        <Box pt="sm">
          {content}
        </Box>
      </Collapse>
    </Paper>
  );
};

const LoadingSummaryContent = () => {
  const { classes } = useStyles();
  
  return (
    <Stack spacing="md">
      {/* Simulate multiple categories */}
      {Array(5).fill(0).map((_, categoryIndex) => (
        <Fragment key={`loading-category-${categoryIndex}`}>
          {/* Category Title */}
          <Flex pl="4px" pt="16px" pb="8px">
            <Skeleton height={24} width="40%" animate />
          </Flex>

          {/* Simulate 2-3 subcategories per category */}
          {Array(Math.floor(Math.random() * 2) + 2).fill(0).map((_, subCatIndex) => (
            <Paper className={classes.summarySection} key={`loading-subcategory-${categoryIndex}-${subCatIndex}`}>
              {/* Subcategory header */}
              <div className={classes.sectionHeader}>
                <Skeleton height={20} width="60%" animate />
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  size="sm"
                >
                  <ChevronUp size={16} />
                </ActionIcon>
              </div>

              <Box pt="sm">
                <Stack spacing="sm">
                  {Array(Math.floor(Math.random() * 2) + 3).fill(0).map((_, itemIndex) => (
                    <Group position="apart" key={`loading-item-${categoryIndex}-${subCatIndex}-${itemIndex}`}>
                      <Skeleton height={16} width="40%" animate />
                      <Group spacing="xs">
                        <Skeleton height={16} width={60} animate />
                        <Skeleton height={16} width={40} animate />
                        <Skeleton height={16} width={40} animate />
                      </Group>
                    </Group>
                  ))}
                </Stack>
              </Box>
            </Paper>
          ))}
        </Fragment>
      ))}
    </Stack>
  );
};

const buildSummaryValueComparison = (context: AppContextType) => {
  const data: Dataset = context.state.summaryDataNewAPI;

  if (!data?.tables || !Array.isArray(data.tables)) {
    return <LoadingSummaryContent />;
  }

  return (
    <Fragment>
      {data.tables.map((table) => (
        <Fragment key={`table-${table.category_title}`}>
          <Flex pl="4px" pt="16px" pb="8px">
            <Text ta="left" fw={700} fz="lg" tt="capitalize">
              {table.category_title}
            </Text>
          </Flex>

          {table.sub_categories.map((subCategory: SubCategory) => (
            <Fragment key={`subcat-${subCategory.sub_category_title}`}>
              <SummarySection
                title={subCategory.sub_category_title}
                content={
                  <Fragment>
                    {subCategory.filters.map((filter) => subCatList(filter))}
                  </Fragment>
                }
              />
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
};

const subCatList = (filter: FilterType) => {
  if ("responses" in filter) {
    const sortedResponses = [...filter.responses]
      .sort((a, b) => b.area - a.area)
      .slice(0, filter.top_answers);

    return sortedResponses.map((response) => (
      <ValueAndComparisonToAverage
        key={`response-${filter.filter_title}-${response.filter_name}`}
        title={response.filter_name}
        value={response.area * 100}
        stateAverageDiff={response.state * 100}
        nationalAverageDiff={response.national * 100}
        prefixChar={""}
        suffixChar={"%"}
      />
    ));
  }

  return (
    <ValueAndComparisonToAverage
      key={`filter-${filter.filter_title}`}
      title={filter.filter_title}
      value={filter.value ?? 0}
      stateAverageDiff={filter.state ?? 0}
      nationalAverageDiff={filter.national ?? 0}
      prefixChar={filter.units === "$" ? "$" : ""}
      suffixChar={filter.units === "%" ? "%" : ""}
    />
  );
};

const renderProgress = (state: State): React.ReactNode => {
  if (state.reportGenerating || state.datarequeststate > 0) {
    return (
      <Box mb="md">
        {state.reportGenerating && (
          <Stack spacing="xs">
            <Text size="sm" color="dimmed">
              {state.reportProgress?.currentStep || 'Preparing report...'}
            </Text>
            <Progress
              value={state.reportProgress?.progress || 0}
              color="orange"
              size="sm"
              radius="xl"
              striped
              animate
            />
          </Stack>
        )}
        {state.datarequeststate > 0 && state.datarequeststate !== 100 && (
          <Flex w={"100%"} align={"center"} gap={"1rem"}>
            <Text size="sm" style={{ color: "gray" }}>
              Updating Data:
            </Text>
            <Progress
              color="orange"
              radius="xl"
              size="sm"
              animate
              value={state.datarequeststate}
              sx={{ flex: "1" }}
              striped
            />
          </Flex>
        )}
      </Box>
    );
  }
  return null;
};

const DownloadButton = ({ state }: { state: State }) => {
  if (state.reportGenerating) {
    return (
      <Indicator
        inline
        processing
        color="orange"
        size={8}
        offset={4}
        position="bottom-end"
      >
        <Button
          color="#FF8E16"
          sx={(theme) => ({
            backgroundColor: theme.colors.allColors[7],
            "&:hover": {
              backgroundColor: "#f76707",
            },
          })}
        >
          <Download />
        </Button>
      </Indicator>
    );
  }

  return (
    <Button
      color="#FF8E16"
      sx={(theme) => ({
        backgroundColor: theme.colors.allColors[7],
        "&:hover": {
          backgroundColor: "#f76707",
        },
      })}
    >
      <Download />
    </Button>
  );
};

const DownloadMenu = ({ context, user, state, onReportClick }: {
  context: AppContextType;
  user: any;
  state: State;
  onReportClick: () => void;
}) => {
  const premiumTierSubscription = true;
  const tooltipLabel = state.reportGenerating 
    ? `Generating report: ${Math.round(state.reportProgress?.progress || 0)}%` 
    : "Download options";

  const handleDownload = (action: () => void) => {
    if (!user?.key) {
      notifications.show({
        color: "red",
        title: "Authentication Required",
        message: "Please log in to download reports",
        icon: <X size="1rem" />,
        autoClose: 5000,
      });
      return;
    }
    action();
  };

  const handleReportClick = () => {
    if (!user?.key) {
      notifications.show({
        color: "red",
        title: "Authentication Required",
        message: "Please log in to generate reports",
        icon: <X size="1rem" />,
        autoClose: 5000,
      });
      return;
    }

    if (state.reportProgress?.status === 'processing') {
      onReportClick();
    } else {
      context.dispatch({ 
        type: "setModalState", 
        payload: { modal: "reportGeneration", state: true } 
      });
    }
  };

  return (
    <Menu styles={{
      itemIcon: { color: "#414042" },
      itemLabel: { color: "#414042" },
    }}>
      <Menu.Target>
        <Tooltip label={tooltipLabel} position="left" withArrow>
          <div>
            <DownloadButton state={state} />
          </div>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Download formats</Menu.Label>

        <Menu.Item
          icon={<File3d size={14} />}
          onClick={() => handleDownload(() => downloadSA1sData(context, user))}
        >
          Detailed Report
        </Menu.Item>
        <Menu.Item
          icon={<FileSpreadsheet size={14} />}
          onClick={() => handleDownload(() => downloadAsCSV(context, user))}
        >
          Summary CSV
        </Menu.Item>
        
        {state.reportGenerating ? (
          <Menu.Item
            closeMenuOnClick={false}
            icon={<Loader size={14} color="orange" />}
            onClick={handleReportClick}
          >
            <Group spacing="xs" position="apart">
              <Text size="sm">Report Progress</Text>
              <Text size="xs" color="dimmed">{Math.round(state.reportProgress?.progress || 0)}%</Text>
            </Group>
          </Menu.Item>
        ) : (
          <Menu.Item
            icon={<FileAnalytics size={14} />}
            onClick={handleReportClick}
            disabled={state.reportGenerating}
          >
            Detailed Analysis Report
          </Menu.Item>
        )}

        <Tooltip label={!user?.key ? "Please log in to use AI features" : "Chat with Bruce - AI Urban Planner"}>
          <Menu.Item
            icon={<MessageChatbot size={14} />}
            onClick={() => {
              if (user?.key) {
                context.dispatch({ 
                  type: "setModalState", 
                  payload: { modal: "aiChat", state: true } 
                });
              } else {
                notifications.show({
                  color: "red",
                  title: "Authentication Required",
                  message: "Please log in to use AI features",
                  icon: <X size="1rem" />,
                  autoClose: 5000,
                });
              }
            }}
            disabled={!user?.key}
          >
            Chat with Bruce - AI Urban Planner
          </Menu.Item>
        </Tooltip>
      </Menu.Dropdown>
    </Menu>
  );
};

const SummaryBar = () => {
  const [showHideOnSmScreen, setShowHideOnSmScreen] = useState<boolean>(false);

  const context = useContext(storeContext);
  if (!context) {
    throw new Error("SummaryBar must be used within a StoreProvider");
  }
  const { state, dispatch } = context;
  const { user } = useAuth();

  // Add polling logic for report progress
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let retryCount = 0;
    const MAX_RETRIES = 3;

    const pollProgress = async () => {
      const progress_id = state.reportProgress?.progress_id;
      if (!progress_id || !state.reportGenerating || !user?.key) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}demographics/report_progress/${progress_id}/`,
          {
            headers: {
              Authorization: `token ${user.key}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch progress');
        }

        // Reset retry count on successful request
        retryCount = 0;

        const data = await response.json();
        const scaledProgress = data.progress || 0;

        // Update global state
        dispatch({
          type: "setReportProgress",
          payload: {
            progress: scaledProgress,
            status: data.status,
            currentStep: data.current_step || "Processing report...",
            error_message: data.error_message,
            progress_id: progress_id,
            report_id: data.report_id
          }
        });

        // Handle completion
        if (data.status === 'completed') {
          clearInterval(intervalId);
          notifications.show({
            color: "green",
            title: "Report Generated Successfully",
            message: "Your report is ready to download",
            icon: <Check size="1rem" />,
            autoClose: 3000,
          });
          // Refresh the reports drawer
          window.dispatchEvent(new CustomEvent('refreshReportsDrawer'));
        }

        // Handle failure
        if (data.status === 'failed') {
          clearInterval(intervalId);
          notifications.show({
            color: "red",
            title: "Report Generation Failed",
            message: data.error_message || "Failed to generate report",
            icon: <X size="1rem" />,
            autoClose: 5000,
          });
          dispatch({ type: "resetReportState" });
        }
      } catch (error) {
        console.error('Error polling progress:', error);
        retryCount++;

        if (retryCount >= MAX_RETRIES) {
          clearInterval(intervalId);
          
          const errorMessage = 'Lost connection to the server';
          
          dispatch({
            type: "setReportProgress",
            payload: {
              progress: 0,
              status: 'failed',
              currentStep: 'Connection failed',
              error_message: errorMessage,
              progress_id: progress_id
            }
          });

          notifications.show({
            color: "red",
            title: "Connection Lost",
            message: errorMessage,
            icon: <X size="1rem" />,
            autoClose: 5000,
          });
        }
      }
    };

    if (state.reportGenerating && state.reportProgress?.progress_id) {
      // Start polling immediately
      pollProgress();
      // Then set up the interval
      intervalId = setInterval(pollProgress, 2000); // Poll every 2 seconds
    }

    // Cleanup on unmount or when report is no longer generating
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [state.reportGenerating, state.reportProgress?.progress_id, user?.key, dispatch]);

  const handleGenerateReport = async () => {
    if (state.reportGenerating || !user?.key) {
      if (!user?.key) {
        notifications.show({
          color: "red",
          title: "Authentication Required",
          message: "Please log in to generate reports",
          icon: <X size="1rem" />,
          autoClose: 5000,
        });
      }
      return;
    }
    
    try {
      notifications.show({
        id: "report-progress",
        loading: true,
        title: "Starting Report Generation",
        message: "Preparing your demographic report...",
        autoClose: 5000,
        withCloseButton: false,
      });

      const result = await requestWordDocument(context, user, dispatch);
      
      // Don't close the modal - it will show progress and close automatically when complete
    } catch (error) {
      console.error('Failed to generate report:', error);
      notifications.show({
        color: "red",
        title: "Error",
        message: "Failed to start report generation",
        icon: <X size="1rem" />,
        autoClose: 5000,
      });
      dispatch({ type: "resetReportState" });
    }
  };

  const handleCancelReport = async () => {
    if (!state.reportProgress?.progress_id || !user?.key) return;
    
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}demographics/summary_report/${state.reportProgress.progress_id}`, {
        headers: {
          Authorization: `token ${user.key}`,
        },
      });
      dispatch({ type: "resetReportState" });
      notifications.show({
        color: "green",
        title: "Report Cancelled",
        message: "The report generation has been cancelled.",
        icon: <Check size="1rem" />,
        autoClose: 3000,
      });
    } catch (error) {
      notifications.show({
        color: "red",
        title: "Error",
        message: "Failed to cancel the report generation.",
        icon: <X size="1rem" />,
        autoClose: 5000,
      });
    }
  };

  const elementHeight = useElemetHeight();
  const { height } = useElementSize("header-main");
  const { classes } = useStyles();

  return (
    <>
      {state.showSummaryBar ? (
        <>
          <ActionIcon
            onClick={() => {
              setShowHideOnSmScreen(!showHideOnSmScreen);
            }}
            className={classes.summaryToggleBtn}
          >
            {showHideOnSmScreen ? (
              <ArrowBarRight color="#fff" />
            ) : (
              <ArrowBarLeft color="#fff" />
            )}
          </ActionIcon>
          <Aside
            className={`${classes.aside} ${showHideOnSmScreen ? classes.show : classes.hide}`}
            sx={{ height: `calc(100vh - ${height}px)`, top: `${height}px` }}
            width={{ base: 300, sm: 350, lg: 400 }}
          >
            <Box className={classes.header}>
              <Group position="apart" mb="md">
                <Title order={4}>Summary</Title>
                <Group spacing="xs">
                  <Tooltip label={!user?.key ? "Please log in to use AI features" : "Chat with Bruce - AI Urban Planner"}>
                    <ActionIcon
                      color="orange"
                      variant="light"
                      onClick={() => {
                        if (user?.key) {
                          context.dispatch({ 
                            type: "setModalState", 
                            payload: { modal: "aiChat", state: true } 
                          });
                        } else {
                          notifications.show({
                            color: "red",
                            title: "Authentication Required",
                            message: "Please log in to use AI features",
                            icon: <X size="1rem" />,
                            autoClose: 5000,
                          });
                        }
                      }}
                      disabled={!user?.key}
                      sx={(theme) => ({
                        "&:disabled": {
                          backgroundColor: theme.colors.gray[1],
                          cursor: "not-allowed",
                          opacity: 0.6,
                        },
                      })}
                    >
                      <MessageChatbot size={20} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label={!user?.key ? "Please log in to view reports" : "View Generated Reports"}>
                    <ActionIcon
                      color="orange"
                      variant="light"
                      onClick={() => {
                        if (user?.key) {
                          dispatch({ 
                            type: "setModalState", 
                            payload: { modal: "reportsDrawer", state: true } 
                          });
                        } else {
                          notifications.show({
                            color: "red",
                            title: "Authentication Required",
                            message: "Please log in to view reports",
                            icon: <X size="1rem" />,
                            autoClose: 5000,
                          });
                        }
                      }}
                      disabled={!user?.key}
                      sx={(theme) => ({
                        "&:disabled": {
                          backgroundColor: theme.colors.gray[1],
                          cursor: "not-allowed",
                          opacity: 0.6,
                        },
                      })}
                    >
                      <FileAnalytics size={20} />
                    </ActionIcon>
                  </Tooltip>
                  <DownloadMenu 
                    context={context}
                    user={user}
                    state={state}
                    onReportClick={() => dispatch({ 
                      type: "setModalState", 
                      payload: { modal: "reportGeneration", state: true } 
                    })}
                  />
                </Group>
              </Group>
              
              {renderProgress(state)}
            </Box>

            <Box p="md" sx={{ flex: 1, overflowY: 'auto' }}>
              <Transition
                mounted={!state.summaryDataLoading}
                transition="fade"
                duration={400}
              >
                {(styles) => (
                  <div style={styles}>
                    <SimpleGrid cols={2} spacing="md">
                      <StatCard
                        icon={Mailbox}
                        label="Residential"
                        value={state.letterBoxData?.residential_count || 0}
                      />
                      <StatCard
                        icon={BuildingStore}
                        label="Businesses"
                        value={state.businessesCount?.businesses_count || 0}
                      />
                      <StatCard
                        icon={Inbox}
                        label="Letterboxes"
                        value={state.letterBoxCountPAF?.letterbox_count_PAF || 0}
                      />
                      <StatCard
                        icon={Users}
                        label="Population"
                        value={state.summaryDataNewAPI.population || 0}
                      />
                    </SimpleGrid>

                    <Box mt="xl">
                      {state.summaryDataLoading ? (
                        <LoadingSummaryContent />
                      ) : (
                        buildSummaryValueComparison(context)
                      )}
                    </Box>
                  </div>
                )}
              </Transition>
            </Box>
          </Aside>

          <ReportGenerationModal
            onConfirm={handleGenerateReport}
            onCancel={handleCancelReport}
          />
          <ReportsDrawer
            opened={state.modals.reportsDrawer}
            onClose={() => dispatch({ 
              type: "setModalState", 
              payload: { modal: "reportsDrawer", state: false } 
            })}
          />
          <Dialog
            opened={state.modals.aiChat}
            onClose={() => dispatch({ 
              type: "setModalState", 
              payload: { modal: "aiChat", state: false } 
            })}
            radius="md"
            withCloseButton={true}
            size={500}
            position={{ top: 150, right: 350 }}
          >
            <AIChatWindow />
          </Dialog>
        </>
      ) : (
        <div
          style={{
            height: `calc(100vh - var(--mantine-header-height, 0px) - ${elementHeight}px)`,
          }}
        />
      )}
    </>
  );
};

export default SummaryBar;
