import {
  createStyles,
  Grid,
  Modal,
  Pagination,
  Space,
  TextInput,
} from "@mantine/core";
import React, { FC, useState, useEffect } from "react";
import { Table } from "@mantine/core";
import { Search } from "tabler-icons-react";

import CreateNewMapModal from "./CreateNewMapModal";

import { useAuth } from "../hooks/useAuth";
import { useStore } from "../hooks/useStore";
import { useNavigate } from "react-router-dom";
import ShareMapModal from "./ShareMapModal";
import axios from "axios";
import ScopoLoader from "./ScopoLoader";

const useStyles = createStyles((theme) => ({
  tableBody: {
    textAlign: "start",
  },
  boundaryMap: {
    display: "flex",
    alignItems: "center",
  },
  iconDiv: {
    marginRight: "17px",
  },
  userCreation: {
    display: "flex",
    alignItems: "center",
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.colors.allColors[2],
  },
  mapDetails: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  author: {
    color: theme.colors.allColors[8],
  },
}));

const MapsSharedWithMe: FC = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [shareMapOpened, setShareMapOpened] = useState(false);
  const [sharedMapId] = useState(0);
  const [userMaps, setUserMaps] = useState([]);
  const [usersFilterMaps, setUsersFilterMaps] = useState([]);
  const { user, teamName } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { state, dispatch } = useStore();
  const [activePage, setActivePage] = useState(1);
  const navigate = useNavigate();

  const PER_PAGE = 5;

  useEffect(() => {
    setUserMaps(state.userMaps);
    setUsersFilterMaps(state.userMaps);
  }, [state.userMaps]);

  useEffect(() => {
    const getSharedMapList = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}maps_shared_with_me/`,
          {
            headers: {
              Authorization: `token ${user.key}`,
            },
          }
        );
    
        dispatch({
          type: "setUserMaps",
          payload: response.data.maps_shared_with_me,
        });
      } catch(err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    getSharedMapList();
  }, [dispatch, user.key]);

  const handleChange = (value: string) => {
    if (value.length >= 3) {
      const newUserMaps = userMaps.filter((ele: any) =>
        ele.job_name.toLowerCase().includes(value.toLowerCase())
      );
      setUsersFilterMaps(newUserMaps);
    }
    if (value.length === 0) {
      setUsersFilterMaps(userMaps);
    }
  };

  // table head JSX
  const ths = (
    <tr>
      <th>MAP</th>
      <th>Who shared it</th>
      <th></th>
    </tr>
  );

  const rows = [...usersFilterMaps]
    .splice((activePage - 1) * PER_PAGE, PER_PAGE)
    .map((map: any) => (
      <tr key={map.uuid}>
        <td
          onClick={() => navigate(`/sharable-maps/${map.uuid}`)}
          style={{ cursor: "pointer" }}
        >
          <div className={classes.boundaryMap}>
            <div className={classes.iconDiv}> </div>
            <div className="info-map-div">
              <div className={classes.title}>{map.job_name}</div>
              <div>
                <div className={classes.mapDetails}></div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className={classes.userCreation}>
            <div className={classes.textDiv}>
              <span className={classes.author}>{map.created_by}</span>{" "}
            </div>
          </div>
        </td>
      </tr>
    ));

  const sharedMapsHeader = (
  <Grid mt="md">
    <Grid.Col
      lg={6}
      sm={4}
      style={{
        textAlign: "start",
        fontSize: "22px",
        fontWeight: "bold",
      }}
    >
      {teamName} - Shared Map List
    </Grid.Col>
      <Grid.Col lg={3} sm={4}>
    </Grid.Col>
  </Grid>
  );

  if (loading) {
    return (
    <>
      {sharedMapsHeader}
      <ScopoLoader rows={3} />
    </>
    )
  }
  
  return (
    <>
      {sharedMapsHeader}
      <Space h="lg" />
      <Space h="lg" />
      {state.userMaps.length > 0 && (
        <div>
          <Grid>
            <Grid.Col lg={3}>
              <TextInput
                radius="lg"
                placeholder="Search"
                icon={<Search size={18} strokeWidth={2} color={"black"} />}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Grid.Col>
          </Grid>
          <div className="table-responsive">
            <Table
              className="table-inner"
              verticalSpacing="xl"
              captionSide="bottom"
              mb="xl"
              mt="sm"
              pb="lg"
            >
              <thead>{ths}</thead>
              {userMaps.length && (
                <tbody className={classes.tableBody}>{rows}</tbody>
              )}
            </Table>
          </div>
          <Pagination
            total={Math.ceil(state.userMaps.length / PER_PAGE)}
            position="center"
            className="pagination"
            value={activePage}
            onChange={setActivePage}
          />
        </div>
      )}
      {!state.userMaps.length && (
        <div style={{ width: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <h2>No maps shared with you yet</h2>
          </div>
        </div>
      )}
      <Modal
        centered
        padding={0}
        opened={shareMapOpened}
        onClose={() => setShareMapOpened(false)}
        title="Share this Map"
      >
        <ShareMapModal close={setShareMapOpened} sharedMapId={sharedMapId} />
      </Modal>
      <Modal
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        padding={0}
        withCloseButton={false}
        size="lg"
      >
        <CreateNewMapModal />
      </Modal>
    </>
  );
};

export default MapsSharedWithMe;
