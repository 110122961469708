import AppShellLayout from "../components/AppShellLayout";
import SALMapsSelectionTable from "../components/SALMapsSelectionTable";

const SALMapSelection = () => {
  return (
    <AppShellLayout>
      <SALMapsSelectionTable />
    </AppShellLayout>
  );
};

export default SALMapSelection;
