import AppShellLayout from "../components/AppShellLayout";
import MySharedMapList from "../components/MySharedMapList";

const SharedMaps = () => {
  return (
    <AppShellLayout>
      <MySharedMapList />
    </AppShellLayout>
  );
};

export default SharedMaps;
