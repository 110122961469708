import AppShellLayout from "../components/AppShellLayout";
import MapsSharedWithMe from "../components/MapsSharedWithMe";

const SharableMaps = () => {
  return (
    <AppShellLayout>
      <MapsSharedWithMe />
    </AppShellLayout>
  );
};

export default SharableMaps;
