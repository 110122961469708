import { FiltersRecordType, Language, TravelMethod } from "../types";

export const loadFilters = (data: any, dispatch: any, polygonId?: string) => {
  /*   let minIncome = Infinity;
  let maxIncome = -Infinity;
  let minUnitPercentage = Infinity;
  let maxUnitPercentage = -Infinity;
  let minMedianAge = Infinity;
  let maxMedianAge = -Infinity;
  let minUnemployed = Infinity;
  let maxUnemployed = -Infinity;
  let minUniDegree = Infinity;
  let maxUniDegree = -Infinity;
  let minRentalProperties = Infinity;
  let maxRentalProperties = -Infinity;
  let minOwnedOutright = Infinity;
  let maxOwnedOutright = -Infinity;
  let minOwnedWithMortgage = Infinity;
  let maxOwnedWithMortgage = -Infinity;
  let minIndigenous = Infinity;
  let maxIndigenous = -Infinity;
  let minHomeInternet = Infinity;
  let maxHomeInternet = -Infinity;
  // let minItalianSpeakers = Infinity;
  // let maxItalianSpeakers = -Infinity;
  // let minGreekSpeakers = Infinity;
  // let maxGreekSpeakers = -Infinity;

  let topLanguages: Language[] = [];
  let topMethodsOfTravel: TravelMethod[] = [];

  data.forEach((data: any) => {
    minIncome = Math.min(data.weekly_household_income, minIncome);
    maxIncome = Math.max(data.weekly_household_income, maxIncome);
    minUnitPercentage = Math.min(data.appartment_percentage, minUnitPercentage);
    maxUnitPercentage = Math.max(data.appartment_percentage, maxUnitPercentage);
    minMedianAge = Math.min(data.median_age, minMedianAge);
    maxMedianAge = Math.max(data.median_age, maxMedianAge);
    minUnemployed = Math.min(data.unemployment_percentage, minUnemployed);
    maxUnemployed = Math.max(data.unemployment_percentage, maxUnemployed);
    minUniDegree = Math.min(data.uni_degree_percentage, minUniDegree);
    maxUniDegree = Math.max(data.uni_degree_percentage, maxUniDegree);
    minRentalProperties = Math.min(data.rental_percentage, minRentalProperties);
    maxRentalProperties = Math.max(data.rental_percentage, maxRentalProperties);
    minOwnedOutright = Math.min(data.owned_outright_percentage, minOwnedOutright);
    maxOwnedOutright = Math.max(data.owned_outright_percentage, maxOwnedOutright);
    minOwnedWithMortgage = Math.min(data.owned_with_a_mortgage_percentage, minOwnedWithMortgage);
    maxOwnedWithMortgage = Math.max(data.owned_with_a_mortgage_percentage, maxOwnedWithMortgage);
    minIndigenous = Math.min(data.aboriginal_percentage, minIndigenous);
    maxIndigenous = Math.max(data.aboriginal_percentage, maxIndigenous);
    minHomeInternet = Math.min(
      data.internet_accessed_percentage,
      minHomeInternet
    );
    maxHomeInternet = Math.max(
      data.internet_accessed_percentage,
      maxHomeInternet
    );
  });

  languages &&
    languages.forEach((language: any) => {
      let minValue = 1;
      let maxValue = 0;
      data.forEach((data: any) => {
        let languageValue = 0;
        if (language.name in data) languageValue = data[language.name];
        minValue = Math.min(languageValue, minValue);
        maxValue = Math.max(languageValue, maxValue);
      });

      let newLanguage = {
        name: language.name,
        minValue: minValue,
        maxValue: maxValue,
      };
      topLanguages.push(newLanguage);
    });
  methodsOfTravel &&
    methodsOfTravel.forEach((travelMethod: any) => {
      let minValue = 1;
      let maxValue = 0;
      data.forEach((data: any) => {
        let travelMethodValue = 0;
        if (travelMethod.name in data) travelMethodValue = data[travelMethod.name];
        minValue = Math.min(travelMethodValue, minValue);
        maxValue = Math.max(travelMethodValue, maxValue);
      });

      let newTravelMethod = {
        name: travelMethod.name,
        minValue: minValue,
        maxValue: maxValue,
      };
      topMethodsOfTravel.push(newTravelMethod);
    }); */
  let filters: FiltersRecordType = {
    /*     minIncome,
    maxIncome,
    minUnitPercentage,
    maxUnitPercentage,
    minMedianAge,
    maxMedianAge,
    minUnemployed,
    maxUnemployed,
    minUniDegree,
    maxUniDegree,
    minRentalProperties,
    maxRentalProperties,
    minOwnedOutright,
    maxOwnedOutright,
    minOwnedWithMortgage,
    maxOwnedWithMortgage,
    minIndigenous,
    maxIndigenous,
    minHomeInternet,
    maxHomeInternet, */
    setCheck: true,
  };
  dispatch({
    type: "setFilterData",
    payload: filters,
  });
  dispatch({
    type: "setTotalFiltersRecords",
    payload: { polygonId: polygonId, filters: filters },
  });
};
