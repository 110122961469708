import axios from "axios";

const fetchUserSubscriptionDetails = async (key: string, username = '', orgName = '', stripeCustomerId = '') => {
  let params = {}
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}subscriptions/subscription_details/`, {
    headers: {
      Authorization: `token ${key}`,
    },
    params: { username: username, org_name: orgName, stripe_customer_id: stripeCustomerId },
  });
  return response.data;
};

export { fetchUserSubscriptionDetails };
