import { fetchSa1sReport } from "./fetchSa1sReport";
import { getPolygonSA1s } from "./getPolygonSA1s";
import { loadSA1sOntoMap } from "./loadSA1sOntoMap";
import { loadFilters } from "./loadFilters";
import { FilterTable, Filter } from "../types";
import { setCheckedOutCatalog } from "../components/FiltersBar";
import { useAuth } from "../hooks/useAuth";
// import { getPolygonClosureUid } from "../map-utils";

// data
interface dataobj {
  filters: SA1Poly;
}

// SA1Poly
interface SA1Poly {
  [key: string]: {
    data: {
      [key: string]:
        | { units: string; value: number; state: number; national: number }
        | { value: number; units: string };
    };
    coords: string[];
    intersected_percentage: number;
    coverage_percentage: number;
    area_type: string;
  };
}

export const getSummaryData = (
  polygon: any,
  allPolygons: any,
  map: any,
  dispatch: any,
  state: any,
  userkey: string,
  demographicsSettings: any
) => {
  let polygons = [];
  polygons.push(polygon);
  let polygonsDone = 0;

  dispatch({ type: "setSummaryDataLoadingLetterboxCounts", payload: true});
  dispatch({ type: "setDataRequestState", payload: 10 });

  if (polygons.length) {
    polygons.forEach(async function (polygon: any) {
      // unsplitPolygons.push(polygon);
      //let newPolygonEncodedPath = google.maps.geometry.encoding.encodePath(
      //polygon.getPath()
      //);

      let checkedOutCatalog = state.checkedOutCatalog;

      if (Object.keys(checkedOutCatalog).length == 0) {
        checkedOutCatalog = await setCheckedOutCatalog(
          userkey,
          state,
          dispatch,
          demographicsSettings
        );
      }

      let newCatalogue: FilterTable[] = JSON.parse(
        JSON.stringify(checkedOutCatalog)
      );

      let catalogtables: any = {
        tables: newCatalogue,
      };

      // drawingManager.setDrawingMode(null);
      if (++polygonsDone === polygons.length) {
        // source for both old and new API data
        let data = await getPolygonSA1s(allPolygons, catalogtables);
        dispatch({ type: "setDataRequestState", payload: 40 });

        // push the new API data to the reducer
        dispatch({ type: "setPolygonSA1Data", payload: data?.newAPI });

        let payload: any = data?.newAPI;

        // convert the payload to the format of the dataobj interface
        let sa1data: SA1Poly = {};

        // loop through the payload and convert it to the format of the dataobj interface
        for (let key in payload.filters) {
          let data = payload.filters[key];
          let coordsArray = data.coords;
          let areaType = data.area_type;
          let intersected_percentage = data.intersection_percentage;
          let coverage_percentage = data.coverage_percentage;
          delete data.coords;
          delete data.intersected_percentage;
          sa1data[data.sa1_7digit] = {
            data: data,
            coords: coordsArray,
            intersected_percentage: intersected_percentage,
            coverage_percentage: coverage_percentage,
            area_type: areaType,
          };
        }

        let polygonId: string = polygon.id;

        allPolygons.split(",").forEach((polygonPath: string) => {
          let polygonId = polygonPath;
          loadSA1sOntoMap(sa1data, map, dispatch, state, polygonId);
        });

        // Filters now loading inside filter bar based on filters selected from catalog
        loadFilters(sa1data, dispatch, polygonId);
        dispatch({ type: "setDataRequestState", payload: 60 });

        let areaPortions = [];
        // LGA/SAL -> covers area completely and takes straight data
        let area_with_complete_coverage: any[] = []; // removed after api transition

        for (let key in sa1data) {
          // Ensure we are not iterating over inherited properties
          if (sa1data.hasOwnProperty(key)) {
            // Assuming sa1data[key] is an object that has 'data' which, in turn, contains 'sa1_7digit' and 'intersected_percentage'
            const sa1Entry = sa1data[key];
            const sa1Code = sa1Entry.data.sa1_7digit; // or 'sa1_code' based on actual property name
            const intersectedPercentage = sa1Entry.intersected_percentage; // Provide a default of 0 if undefined or falsy
            const coveragePercentage = sa1Entry.coverage_percentage; // Provide a default of 0 if undefined or falsy
            const areaType = sa1Entry.area_type;

            areaPortions.push([
              sa1Code,
              intersectedPercentage,
              coveragePercentage,
              areaType,
            ]);
          }
        }

        payload.area.map((polygon: { polygon_id: string; area: number }) => {
          dispatch({
            type: "setPolygonsArea",
            payload: {
              polygonId: polygon.polygon_id,
              area: polygon.area,
            },
          });
        });

        // dispatch({ type: "setSa1Portions", payload: areaPortions });
        const onePolygon: boolean = allPolygons.length == 1;
        fetchSa1sReport(areaPortions, catalogtables, onePolygon)
          .then((payload) => {
            dispatch({ type: "setDataRequestState", payload: 100 });
            //let data = payload?.legacyAPI;

            //dispatch({ type: "setSummaryData", payload: data });

            // setting reducer with new API data
            dispatch({
              type: "setSummaryDataNewAPI",
              payload: payload?.newAPI,
            });

            // dispatch({
            //   type: "setTotalSummaryData",
            //   payload: { polygonId: polygonId, summaryBarData: data },
            // });
          })
          .catch((err) => console.log("error", err))
          .finally(() => {
            dispatch({
              type: "setSummaryDataLoadingDemographics",
              payload: false,
            });
            dispatch({ type: "setDataRequestState", payload: 0 });
            dispatch({ type: "setSummaryDataLoadingLetterboxCounts", payload: false});
          });
      }
    });
  }
};
