import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useStore } from '../hooks/useStore';
import { useAuth } from '../hooks/useAuth';
// import Map from '../components/Map';

function MapCopy() {
  const { id } = useParams();
  const { dispatch } = useStore();
  const { user } = useAuth();
  const navigate = useNavigate();

  const getUserMap = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}demographics/saved_map_copy/${id}/`,
        {
          headers: {
            Authorization: `token ${user.key}`,
          },
        }
      );
      dispatch({ type: 'setUserMap', payload: response.data });
      dispatch({ type: 'addJobId', payload: response.data.job_id });
      navigate(`/${response.data.job_id}`);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserMap();
  }, []);

  return <></>;
}

export default MapCopy;
