/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Card,
  createStyles,
  Badge,
  Grid,
  Group,
  List,
  Text,
  ThemeIcon,
  Title,
  LoadingOverlay,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { Check } from "tabler-icons-react";
import { Tooltip } from "@mantine/core";

// import storeContext from "../store/store";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { fetchUserSubscriptionDetails } from "../map-utils/fetchUserSubscriptionDetails";

const useStyles = createStyles((theme) => ({
  pricingCardWrapper: {
    boxShadow: "0px 2px 10px rgb(0 0 0 / 8%)",
    color: "#292A2C",
    border: "1px solid transparent",
    transition: ".3s ease-in-out",
    borderRadius: "5px",
    "&:hover": {
      border: "1px solid #FF8E16",
    },
  },
  cardTitle: {
    fontSize: "32px",
    width: "100%",
    position: "relative",
    paddingBottom: "4px",
    lineHeight: "42px",
    "&::before": {
      content: '""',
      width: "60px",
      height: "3px",
      position: "absolute",
      bottom: "-8px",
      background: "#FF8E16",
    },
  },

  cardDesc: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  plan: {
    fontSize: "56px",
    width: "100%",
    marginBottom: "5px",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: "36px",
    },
  },
  plan_small: {
    fontSize: "28px",
    width: "100%",
    marginBottom: "5px",
  },
  month: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#222B45",
  },
  cardButton: {
    fontWeight: "normal",
  },
  cardBadge: {
    top: "16px",
    position: "absolute",
    right: "-31px",
    minWidth: "112px",
    background: "#FF8E16",
    color: "#fff",
    transform: "rotate(45deg)",
  },
}));

const PricingCard = () => {
  const { user } = useAuth();

  const [currentSubscription, setCurrentSubscription] = useState<any>(null);
  const [trialAvailable, setTrialAvailable] = useState(() => false);
  const [trialButtonText, setTrialButtonText] = useState(() => "Unavailable");

  const [corporateButtonText, setCorporateButtonText] = useState(
    () => "Request quote"
  );
  const [corporateButtonTooltip, setCorporateButtonTooltip] = useState(
    () => "Contact us for a quote for your organisation"
  );
  const [daysRemainingInSubscriptionPaid, setDaysRemainingInSubscriptionPaid] =
    useState(() => 0);
  const [userInfo, setUserInfo] = useLocalStorage("user", user);
  const [visible, setVisible] = useState(false);

  const handleFetchUserSubscriptionDetails = () => {
    if(!user) return;
    fetchUserSubscriptionDetails(user?.key)
      .then((response) => {
        user.subscriptionDetails = response;
        setUserInfo(user);
        setCurrentSubscription(response);
        setDaysRemainingInSubscriptionPaid(Math.ceil((response.subscription_expires - Date.now()/1000) / (3600 * 24)))
      })
      .catch((err) =>
        console.log("error in fetching subscription details", err)
      );
  };

  useEffect(() => {
    handleFetchUserSubscriptionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  const chooseSubscription = async (subscriptionTierId: string) => {
    console.log(subscriptionTierId);

    let formData = new FormData();
    formData.append("stripe_price_id", subscriptionTierId);
    formData.append(
      "success_url_base",
      window.location.protocol +
        "//" +
        window.location.host +
        "/subscription-success/"
    );
    formData.append(
      "cancel_url_base",
      window.location.protocol +
        "//" +
        window.location.host +
        "/subscription-cancel/"
    );

    try {
      if (user && user.key) {
        setVisible(true);
        if (!user.subscriptionDetails?.stripe_customer_id) {
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}subscriptions/create_subscription_checkout/`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `token ${user?.key}`,
            },
          });
          user.subscriptionDetails = response.data;
          setUserInfo(user);
          setCurrentSubscription(response.data);
          setVisible(false);
          window.location = response.data["checkout_url"];
        } else {
          await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}subscriptions/change_subscription/`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `token ${user.key}`,
            },
          })
            .then((response) => {
              user.subscriptionDetails = response.data;
              setUserInfo(user);
              setCurrentSubscription(response.data);
              window.location = response.data["checkout_url"];
            })
            .catch((error) =>
              console.log("error in changing subscription plan", error)
            )
            .finally(() => {
              setVisible(false);
            });
        }
      }
    } catch (e) {
      console.log(e);
      setVisible(false);
    }
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user")!);
    if (user?.key) {
      setCurrentSubscription(user.subscriptionDetails);
    }
  }, []);

  const { classes } = useStyles();
  return (
    <>
      <LoadingOverlay
        visible={visible}
        loaderProps={{ size: "xl", color: "orange" }}
      />
      <Grid.Col xs={12} sm={6} md={4} lg={3}>
        <Tooltip
          withArrow
          transitionProps={{ transition: "fade", duration: 200 }}
          label={
            currentSubscription?.subscription_name === "Trial"
              ? `You have ${Math.max(
                  Math.ceil(
                    (user.subscriptionDetails.subscription_expires -
                      Date.now() / 1000) /
                      86400
                  ),
                  0
                )} days of your trial remaining`
              : "Your trial is over"
          }
        >
          <Card shadow="lg" p="lg" className={classes.pricingCardWrapper}>
            <Group>
              <Title className={classes.cardTitle} order={3}>
                Trial
              </Title>
              <Text size="sm" className={classes.cardDesc}>
                Test the waters with a 7-day trial with the option to upgrade.
              </Text>
              <Title className={classes.plan} order={1}>
                Free
              </Title>
              <List
                spacing="md"
                size="md"
                mb={20}
                center
                icon={
                  <ThemeIcon
                    sx={(theme) => ({
                      backgroundColor: "#D3F9D8",
                    })}
                    size={24}
                    radius="xl"
                  >
                    <Check size={16} color="#2B8A3E" />
                  </ThemeIcon>
                }
              >
                <List.Item>One user</List.Item>
                <List.Item>Ten projects</List.Item>
                <List.Item>
                  {currentSubscription?.subscription_name === "Trial"
              ? daysRemainingInSubscriptionPaid : 0} days remaining
                </List.Item>
              </List>
              {currentSubscription?.org_admin &&
              <Button
                fullWidth
                disabled={!trialAvailable}
                className={classes.cardButton}
                color={"orange"}
              >
                {trialButtonText}
              </Button>
              }
            </Group>
          </Card>
        </Tooltip>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={4} lg={3}>
        <Tooltip
          withArrow
          transitionProps={{transition: "fade", duration: 200}}
          label={
            currentSubscription?.subscription_name === "Starter"
              ? "This is your current subscription"
              : currentSubscription?.subscription_name === "Team"
              ? 'You can downgrade to the "Starter" subscription'
              : 'Subscribe to "Starter" plan'
          }
        >
          <Card shadow="lg" p="lg" className={classes.pricingCardWrapper}>
            <Group>
              <Title className={classes.cardTitle} order={3}>
                Starter
              </Title>
              <Text size="sm" className={classes.cardDesc}>
                For individuals and smaller teams looking to gain valuable
                insights.
              </Text>
              <Title className={classes.plan} order={1}>
                $149<span className={classes.month}>/ month + GST</span>
              </Title>
              <List
                spacing="md"
                size="md"
                mb={20}
                center
                icon={
                  <ThemeIcon
                    sx={(theme) => ({
                      backgroundColor: "#D3F9D8",
                    })}
                    size={24}
                    radius="xl"
                  >
                    <Check size={16} color="#2B8A3E" />
                  </ThemeIcon>
                }
              >
                <List.Item>One user</List.Item>
                <List.Item>Unlimited projects</List.Item>
                <List.Item>Upgrade anytime</List.Item>
              </List>
              {currentSubscription?.org_admin &&
              <Button
                fullWidth
                disabled={currentSubscription?.subscription_name === "Starter"}
                className={classes.cardButton}
                color={"orange"}
                onClick={() =>
                  chooseSubscription("price_1NQhPsHqjNKMvNOdq7CZCN0b")
                }
              >
                {currentSubscription?.subscription_name === "Starter"
                  ? "Your current subscription"
                  : currentSubscription?.subscription_name === "Team"
                  ? "Downgrade to this"
                  : "Subscribe Now"}
              </Button>
              }
            </Group>
          </Card>
        </Tooltip>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={4} lg={3}>
        <Tooltip
          withArrow
          transitionProps={{transition: "fade", duration: 200}}
          label={
            currentSubscription?.subscription_name === "Team"
              ? "This is your current subscription"
              : currentSubscription?.subscription_name === "Starter"
              ? 'You can upgrade to the "Team" subscription'
              : "Subscribe To 'Team' plan"
          }
        >
          <Card shadow="lg" p="lg" className={classes.pricingCardWrapper}>
            <Badge className={classes.cardBadge}>Popular</Badge>
            <Group>
              <Title className={classes.cardTitle} order={3}>
                Team
              </Title>
              <Text size="sm" className={classes.cardDesc}>
                Perfect for medium-size teams for data-driven collaboration on projects.
              </Text>
              <Title className={classes.plan} order={1}>
                $249<span className={classes.month}>/ month + GST</span>
              </Title>
              <List
                spacing="md"
                size="md"
                mb={20}
                center
                icon={
                  <ThemeIcon
                    sx={(theme) => ({
                      backgroundColor: "#D3F9D8",
                    })}
                    size={24}
                    radius="xl"
                  >
                    <Check size={16} color="#2B8A3E" />
                  </ThemeIcon>
                }
              >
                <List.Item>Five users</List.Item>
                <List.Item>Unlimited projects</List.Item>
                <List.Item>Team collaboration</List.Item>
              </List>
              {currentSubscription?.org_admin &&
              <Button
                fullWidth
                disabled={currentSubscription?.subscription_name === "Team"}
                className={classes.cardButton}
                color={"orange"}
                onClick={() =>
                  chooseSubscription("price_1NQhP9HqjNKMvNOd3F1Nf9tS")
                }
              >
                {currentSubscription?.subscription_name === "Team"
                  ? "This is your current subscription"
                  : currentSubscription?.subscription_name === "Starter"
                  ? "Upgrade to this"
                  : "Subscribe Now"}
              </Button>
              }
            </Group>
          </Card>
        </Tooltip>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={4} lg={3}>
        <Tooltip
          withArrow
          transitionProps={{transition: "fade", duration: 200}}
          label={corporateButtonTooltip}
        >
          <Card shadow="lg" p="lg" className={classes.pricingCardWrapper}>
            <Group>
              <Title className={classes.cardTitle} order={3}>
                Corporate
              </Title>
              <Text size="sm" className={classes.cardDesc}>
                Suitable for larger teams working on multiple projects at a time.
              </Text>
              <Title className={classes.plan} order={1}>
                Quote
              </Title>
              <List
                spacing="md"
                size="md"
                mb={20}
                center
                icon={
                  <ThemeIcon
                    sx={(theme) => ({
                      backgroundColor: "#D3F9D8",
                    })}
                    size={24}
                    radius="xl"
                  >
                    <Check size={16} color="#2B8A3E" />
                  </ThemeIcon>
                }
              >
                <List.Item>Unlimited users</List.Item>
                <List.Item>Unlimited projects</List.Item>
                <List.Item>Contact for quote</List.Item>
              </List>
              {currentSubscription?.org_admin &&
              <Button fullWidth className={classes.cardButton} color={"orange"}>
                {corporateButtonText}
              </Button>
              }
            </Group>
          </Card>
        </Tooltip>
      </Grid.Col>
    </>
  );
};

export default PricingCard;
