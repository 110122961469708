// old code
// function downloadAddressListCSV() {
//   var addressListContent: any = document.getElementById("address-list-content");
//   let data = addressListContent.innerHTML
//     .replace(/<div (class="[^"]*")>/g, "\n")
//     .replace(/<div>/g, "\n")
//     .replace(/<\/div>/g, "\n")
//     .replace(/<br>/g, "\n")
//     .replace("<b>", "")
//     .replace("</b>", "");
//   let filename = "addressList.csv";

//   var blob = new Blob([data], { type: "text/csv" });
//   let navigator = window.navigator as any;
//   if (navigator.msSaveOrOpenBlob) {
//     navigator.msSaveBlob(blob, filename);
//   } else {
//     var elem = window.document.createElement("a");
//     elem.href = window.URL.createObjectURL(blob);
//     elem.download = filename;
//     document.body.appendChild(elem);
//     elem.click();
//     document.body.removeChild(elem);
//   }
// }

// Define the address type to make the function type-safe
interface AddressData {
  unit: string;
  fromStreetNo: string;
  toStreetNo: string;
  streetName: string;
  streetType: string;
  suburb: string;
  state: string;
  postCode: string;
  deliverMailIndicator: string;
  nonDeliveryReasonCode: string;
  advertisingMailIndicator: string;
  residentialIndicator: string;
  nonResidentialIndicator: string;
  [key: string]: string; // Allow for any additional fields
}

// Define a mapping of property names to display headers
const HEADER_MAPPING: Record<string, string> = {
  unit: "Unit",
  fromStreetNo: "Street Number (from)",
  toStreetNo: "Street Number (to)",
  streetName: "Street Name",
  streetType: "Street Type",
  suburb: "Suburb",
  state: "State",
  postCode: "Post Code",
  deliverMailIndicator: "Deliver Mail Indicator",
  nonDeliveryReasonCode: "Non Delivery Reason Code",
  advertisingMailIndicator: "Advertising Mail Indicator",
  residentialIndicator: "Residential Indicator",
  nonResidentialIndicator: "Non Residential Indicator"
};

// Define the expected column order
const COLUMN_ORDER = [
  "unit",
  "fromStreetNo",
  "toStreetNo",
  "streetName",
  "streetType",
  "suburb",
  "state",
  "postCode",
  "deliverMailIndicator",
  "nonDeliveryReasonCode",
  "advertisingMailIndicator",
  "residentialIndicator",
  "nonResidentialIndicator"
];

/**
 * Generate and download a CSV file from address data
 * 
 * @param addressData Array of address objects to convert to CSV
 * @param tableId Optional - Fallback to DOM method if provided (legacy support)
 * @param separator CSV separator character, defaults to comma
 */
function downloadAddressListCSV(
  addressDataOrTableId: AddressData[] | string,
  separator = ","
) {
  try {
    let csv_string = "";
    
    // Check if we're getting an array of data or a tableId string
    if (Array.isArray(addressDataOrTableId)) {
      // New approach: Generate CSV directly from data
      const addressData = addressDataOrTableId;
      
      if (addressData.length === 0) {
        console.error("No address data provided");
        alert("Could not generate CSV file. No address data available.");
        return;
      }
      
      // Use the ordered headers with proper formatting
      const headerRow = COLUMN_ORDER
        .map(key => `"${HEADER_MAPPING[key] || key}"`)
        .join(separator);
      
      // Create data rows following the same column order
      const dataRows = addressData.map(address => {
        return COLUMN_ORDER.map(key => {
          // Clean and escape data
          const data = (address[key] || "")
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ")
            .replace(/"/g, '""');
          return `"${data}"`;
        }).join(separator);
      });
      
      // Combine header and data rows
      csv_string = [headerRow, ...dataRows].join("\n");
      
    } else {
      // Legacy approach: Extract from DOM (fallback)
      console.log("Using legacy DOM extraction method");
      const tableId = addressDataOrTableId;
      
      // Select rows from tableId
      var rows = document.querySelectorAll("table#" + tableId + " tr");
      
      // Try different selectors if needed
      if (rows.length === 0) {
        rows = document.querySelectorAll("table[id='" + tableId + "'] tr");
      }
      
      if (rows.length === 0) {
        rows = document.querySelectorAll("#" + tableId + " tr");
      }
      
      if (rows.length === 0) {
        console.error("Could not find any data rows in the table");
        alert("Could not generate CSV file. No data found. Please try again.");
        return;
      }
      
      // Construct csv
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols: any = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++) {
          // Clean innertext to remove multiple spaces and jumpline (break csv)
          var data = cols[j].innerText
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");
          // Escape double-quote with double-double-quote
          data = data.replace(/"/g, '""');
          // Push escaped string
          row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
      }
      
      csv_string = csv.join("\n");
    }
    
    // Safety check - don't download empty files
    if (csv_string.trim() === '') {
      console.error("CSV string is empty");
      alert("Could not generate CSV file. The data appears to be empty. Please try again.");
      return;
    }
    
    // Download it
    var filename = "addresses-list-" + new Date().toLocaleDateString() + ".csv";
    
    // Create a blob from the CSV string
    const blob = new Blob([csv_string], { type: 'text/csv;charset=utf-8;' });
    
    // Browser detection for downloading
    if (navigator && 'msSaveBlob' in navigator) { 
      // IE 10+
      (navigator as any).msSaveBlob(blob, filename);
    } else {
      // Other browsers
      const link = document.createElement('a');
      
      // Create the URL
      if (link.download !== undefined) {
        // Feature detection
        // Create a temporary URL to the blob
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Clean up the URL created earlier
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 100);
      } else {
        // Fallback for older browsers
        window.open('data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
      }
    }
    
    console.log("CSV download initiated successfully");
  } catch (error) {
    console.error("Error generating CSV file:", error);
    alert("An error occurred while generating the CSV file. Please try again.");
  }
}
export { downloadAddressListCSV };
