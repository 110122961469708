import { Checkbox, Text, createStyles, Group, Stack, Box, Paper } from "@mantine/core";
import { FilterTable } from "../../../types";
import { SetStateAction } from "react";

interface OptionsListProps {
  table: FilterTable;
  filters: FilterTable[];
  setFilters: React.Dispatch<SetStateAction<FilterTable[]>>;
  setIsEdited: React.Dispatch<SetStateAction<boolean>>;
}

const useStyles = createStyles((theme) => ({
  optionsContainer: {
    padding: theme.spacing.xl,
  },
  categoryHeader: {
    color: theme.colors.gray[8],
    marginBottom: theme.spacing.lg,
  },
  filterGroup: {
    backgroundColor: theme.white,
    padding: theme.spacing.lg,
    marginBottom: theme.spacing.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
  },
  filterTitle: {
    color: theme.colors.gray[7],
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    marginBottom: theme.spacing.xs,
  },
  checkboxContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: theme.spacing.sm,
    marginTop: theme.spacing.md,
  },
  checkbox: {
    '& .mantine-Checkbox-label': {
      color: theme.colors.gray[7],
    },
    '&[data-checked]': {
      '& .mantine-Checkbox-label': {
        color: theme.colors.orange[7],
      },
    },
  },
  selectAllCheckbox: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    paddingBottom: theme.spacing.xs,
  }
}));

const OptionsList: React.FC<OptionsListProps> = ({
  table,
  filters,
  setFilters,
  setIsEdited,
}) => {
  const { classes } = useStyles();

  const tableIndex = filters.findIndex(
    (t) => t.category_title === table.category_title
  );

  const handleSubCategoryChange = (
    subCategoryIndex: number,
    checked: boolean
  ) => {
    setFilters((currentFilters) => {
      const newFilters = [...currentFilters];
      newFilters[tableIndex].sub_categories[subCategoryIndex].filters.forEach(
        (filter) => {
          filter.checked = checked;
        }
      );
      return newFilters;
    });
    setIsEdited(true);
  };

  const handleFilterChange = (
    subCategoryIndex: number,
    filterIndex: number,
    checked: boolean
  ) => {
    setFilters((currentFilters) => {
      const newFilters = [...currentFilters];
      newFilters[tableIndex].sub_categories[subCategoryIndex].filters[
        filterIndex
      ].checked = checked;
      return newFilters;
    });
    setIsEdited(true);
  };

  return (
    <Box className={classes.optionsContainer}>
      <Text size="xl" weight={700} className={classes.categoryHeader}>
        {table.category_title}
      </Text>
      <Stack spacing="md">
        {table.sub_categories.map((subCategory, subCategoryIndex) => {
          const subCatIndex = filters[tableIndex].sub_categories.findIndex(
            (sub) => sub.sub_category_title === subCategory.sub_category_title
          );

          const allChecked = subCategory.filters.every((f) => f.checked);
          const someChecked = subCategory.filters.some((f) => f.checked);

          return (
            <Paper key={subCategory.sub_category_title} className={classes.filterGroup} shadow="xs">
              <Stack spacing="xs">
                <Group position="apart" className={classes.selectAllCheckbox}>
                  <Text className={classes.filterTitle}>
                    {subCategory.sub_category_title}
                  </Text>
                  <Checkbox
                    checked={allChecked}
                    indeterminate={someChecked && !allChecked}
                    onChange={(event) =>
                      handleSubCategoryChange(
                        subCatIndex,
                        event.currentTarget.checked
                      )
                    }
                    label="Select All"
                    className={classes.checkbox}
                  />
                </Group>
                <Box className={classes.checkboxContainer}>
                  {subCategory.filters.map((filter, filterIndex) => (
                    <Checkbox
                      key={filter.filter_name}
                      label={filter.filter_title}
                      checked={filter.checked}
                      onChange={(event) =>
                        handleFilterChange(
                          subCatIndex,
                          filterIndex,
                          event.currentTarget.checked
                        )
                      }
                      className={classes.checkbox}
                    />
                  ))}
                </Box>
              </Stack>
            </Paper>
          );
        })}
      </Stack>
    </Box>
  );
};

export default OptionsList;