import {
  Button,
  Group,
  Text,
  createStyles,
  MantineTheme,
  useMantineTheme,
  Avatar,
} from "@mantine/core";
import {
  Upload,
  X,
  Icon as TablerIcon,
  CloudUpload,
  Map,
} from "tabler-icons-react";
import { Dropzone } from "@mantine/dropzone";
import React, { FC, useState } from "react";
import { useContext } from "react";
import storeContext from "../store/store";
import { loadKMLFile } from "../map-utils/loadKMLFile";
import { useAuth } from "../hooks/useAuth";

const useStyles = createStyles((theme) => ({
  createMapWrapper: {
    margin: "0 auto",
    textAlign: "center",
  },
  titleText: {
    fontSize: "28px",
    marginTop: "52px",
    marginBottom: "37px",
    color: "#777777",
  },
  bodyText: {
    marginBottom: "37px",
  },
  fileWrapper: {
    position: "relative",
    top: "10px",
  },
  fileTextWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
}));

type Props = {
  setDropzoneOpened: () => void;
  setOpenedNavbar: () => void | undefined;
};

const DropZoneModal: FC<Props> = ({
  setDropzoneOpened,
  setOpenedNavbar,
}: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [file, setFile] = useState<null | File>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const { state, dispatch } = useContext<any>(storeContext);
  const userkey = useAuth().user.key

  const onDrop = (files: File[]) => {
    setFile(() => files[0]);
  };

  const handleUpload = () => {
    setUploading(true);
    const reader = new FileReader();
    if (file) {
      reader.readAsText(file);
      // var mapping = manageMy.Mapping.getInstance();
      reader.onabort = function () {
        alert("File read cancelled");
      };

      let readResult: any = "";
      reader.onload = async () => {
        readResult = reader.result;
        var parser = new DOMParser();

        var xmlDoc = parser.parseFromString(readResult, "text/xml");
        if (xmlDoc.documentElement.nodeName === "kml") {
          loadKMLFile(xmlDoc, state, dispatch, userkey);
          setOpenedNavbar();
          setUploading(false);
          setDropzoneOpened();
        } else {
          throw new Error("Something went wrong");
        }
      };
    }
  };

  return (
    <div className={classes.createMapWrapper}>
      {file ? (
        <>
          <Group
            position="left"
            spacing="xl"
            style={{ minHeight: 220 }}
            align="top"
            className={classes.fileWrapper}
          >
            <Avatar color="orange" radius="xl" size={39}>
              <Map size={24} />
            </Avatar>
            <div className={classes.fileTextWrapper}>
              <Text size="xl" inline>
                {file.name}
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                {file.size}Kb
              </Text>
            </div>
            <Text
              ml="auto"
              color={theme.colors.allColors[4]}
              onClick={() => setFile(null)}
              underline
              style={{ cursor: "pointer" }}
            >
              {" "}
              Remove
            </Text>
          </Group>
        </>
      ) : (
        <Dropzone
          onDrop={(files: File[]) => onDrop(files)}
          // onDrop={(files) => console.log('accepted files', files)}
          onReject={(files: any) => console.log("rejected files", files)}

          classNames={{
            root: "fileWrapper",
          }}
        >
          <Dropzone.Accept>
            <Group
              position="center"
              spacing="xl"
              style={{ minHeight: 220, pointerEvents: "none" }}
            >
              <CloudUpload
                size="3.2rem"
                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
              />
              <Text size="xl" inline>
                Drop your KML file here
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                or click to select file
              </Text>
            </Group>
          </Dropzone.Accept>

          <Dropzone.Idle>
            <Group
              position="center"
              spacing="xl"
              style={{ minHeight: 220, pointerEvents: "none" }}
            >
              <div>
                <Text size="xl" inline>
                  Drag Your KML file here or click to select file
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  file must be in the correct format
                </Text>
              </div>
            </Group>
          </Dropzone.Idle>
        </Dropzone>
      )}

      <Group position="right" pt={20}>
        {!uploading && (
          <Button
            style={{ minWidth: "130px" }}
            variant="outline"
            onClick={setDropzoneOpened}
          >
            Cancel
          </Button>
        )}
        <Button
          style={{ minWidth: "130px" }}
          variant="filled"
          onClick={handleUpload}
          disabled={!file}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>
      </Group>
    </div>
  );
};

export default DropZoneModal;
