import {
  createStyles,
  AppShell,
  Burger,
  Header,
  MediaQuery,
  useMantineTheme,
  Image,
} from "@mantine/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import TopNotification from "../components/TopNotification";
import { useAuth } from "../hooks/useAuth";

const useStyles = createStyles((theme) => ({
  mapUserWrapper: {
    padding: "0rem 1.875rem",
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      padding: "0px 30px",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      // padding: `0px 83px ${" "}!important`,
    },
    mainMapShell: {
      backgroundColor: "red",
    },
  },
  navTest: {
    background: "red",
  },
}));

const AppShellLayout = ({ children }: any) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const { user } = useAuth();
  return (
    <AppShell
      classNames={{
        main: "appshell-main",
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={<Navbar opened={opened} extraClassName="nav-secondary"></Navbar>}
      header={
        <MediaQuery
          largerThan="sm"
          styles={{ display: "none", height: "0px", maxHeight: "0px" }}
        >
          <Header
            sx={(theme) => ({
              background: theme.colors.allColors[2],
              border: "0px",
              "@media (max-width: 48rem)": {
                height: "70px",
              },
            })}
            height={70}
            p="md"
          >
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[0]}
                    mr="xl"
                  />
                  <div className="logo-wrapper">
                    <Link to="/">
                      <Image
                        src={
                          process.env.REACT_APP_PUBLIC_FOLDER +
                          "Scopomap_Wide_Dark.svg"
                        }
                        alt="scopomap-logo"
                      />
                    </Link>
                  </div>
                </>
              </MediaQuery>
            </div>
          </Header>
        </MediaQuery>
      }
    >
      {user?.subscriptionDetails?.subscription_name === "Trial" ? (
        <TopNotification subscriptionDetails={user.subscriptionDetails} />
      ) : null}
      <div className={classes.mapUserWrapper}>{children}</div>
    </AppShell>
  );
};

export default AppShellLayout;
