import { addTextInsideShape, removeTextInsideShape } from "./handleTextInsideShape";
import { fetchLetterBoxCount } from "./fetchLetterBoxCounts";
import { getSummaryData } from "./getSummaryData";
import { getBusinessLocations } from "./getBusinessLocations";
import { Dispatch, Map, PolygonCustomType, State } from "../types";
import { polygonValidOrRemove } from "./polygonValidator";

export const handlePolygonEdit = (
  currentPolygon: PolygonCustomType,
  map: Map,
  dispatch: Dispatch,
  userkey: string,
  state?: State
) => {
  let previousPath = currentPolygon.id;
  if (true) {//currentPolygon.updated) {
    let polyIndex = window?.polygons.findIndex(
      (poly: any) => poly.id === previousPath
    );
    let newPolygonEncodedPath = google.maps.geometry.encoding.encodePath(
      currentPolygon.getPath()
    );
    var polygonBounds = new google.maps.LatLngBounds();
    let coords: any[] = [];
    let singlePolygonPath = google.maps.geometry.encoding.decodePath(
      newPolygonEncodedPath
    );
    coords.push(singlePolygonPath);
    let polygon: any = new google.maps.Polygon({
      paths: coords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });
    if(!polygonValidOrRemove(polygon, false))
      return;

    removeTextInsideShape(window.polygons[polyIndex]);
    polygon.id = newPolygonEncodedPath;
    polygon.IdBeforeEdit = previousPath;
    window.polygons[polyIndex] = polygon;
    currentPolygon.id = newPolygonEncodedPath;

    dispatch({ type: "resetSummaryStats", payload: null })

    window?.polygons.forEach(async function (pol: any) {
      if(pol === currentPolygon)
        return;
      console.log(pol);
      fetchLetterBoxCount(pol.id, dispatch, pol.id)
      .then((response: any) => {
        if (response) {
          removeTextInsideShape(pol);
          polygonBounds = new google.maps.LatLngBounds();
          for (let i = 0; i < pol.getPath().getLength(); i++) {
            polygonBounds.extend(pol.getPath().getAt(i));
          }
          addTextInsideShape(
            pol,
            polygonBounds.getCenter(),
            map,
            response["Residential count"] + response["Business exact count"]
          );
        }
        return;
      })
      .catch((err) => console.log("error in fetching summary data", err));
    });

    let allPolygons: string = "";
    window?.polygons.forEach(async function (pol: any) {
      if (allPolygons.length > 0) allPolygons += ",";
      allPolygons += google.maps.geometry.encoding.encodePath(pol.getPath());
    });

    getSummaryData(
      polygon,
      allPolygons,
      map,
      dispatch,
      state,
      userkey,
      state?.mapAssociatedDemographicSettings
      // true,
      // previousPath
    );
    getBusinessLocations(allPolygons, map, dispatch, state);
  }
  currentPolygon.setEditable(false);
};

export const editPolygon = (currentPolygon: PolygonCustomType) => {
  window.polygons.forEach((polygon: any) => {
    polygon.setEditable(false);
  });
  let updated: boolean = false;
  currentPolygon.updated = updated;

  currentPolygon.setEditable(true);
  google.maps.event.addListener(
    currentPolygon.getPath(),
    "set_at",
    function () {
      updated = true;
      currentPolygon.updated = updated;
    }
  );
};
