import React, { FC } from "react";
import { TextInput, Group, Button } from "@mantine/core";

type EmailAddressInput = {
  value: string;
  onChange: (value: string) => void;
  checkAddAddress: () => void;
  isValidAddress: () => boolean;
  isAlreadyInRecepients: () => boolean;
  isArrayEmpty: () => boolean;
  onPressEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;


};

const EmailAddressInput: FC<EmailAddressInput> = ({
  value,
  onChange,
  checkAddAddress,
  isValidAddress,
  isAlreadyInRecepients,
  onPressEnter,
  isArrayEmpty,
}) => {
  return (
    <Group position="center" align="top center">
      <TextInput
        placeholder="Enter email address..."
        value={value}
        onChange={(event) => onChange(event.currentTarget.value)}
        style={{ width: "85%" }}
        onKeyDown={(e) =>  onPressEnter(e) }
        error={
        (value === "" && isArrayEmpty()) ? "Please enter at least 1 email address" : (!isValidAddress()&&(!(value===""))) ? "Invalid email" : isAlreadyInRecepients() ? "Already in mailing list" : ""
        }
      />
      <button className="outlined-button-add-address"
      type="button"
      onClick={checkAddAddress}>
      &#x2713;
      
      </button>
    </Group>
  );
};

export default EmailAddressInput;
