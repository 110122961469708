import { Table } from "@mantine/core";
import { FC } from 'react';
import MyMapsListSkeleton from "./MyMapsListSkeleton";

type loaderProps = {
  rows: number,
}
const ScopoLoader: FC<loaderProps>  = (props) => {
  return (
    <div className="table-responsive">
        <Table
        className="table-inner"
        verticalSpacing="xl"
        captionSide="bottom"
        mb="xl"
        mt="sm"
        pb="lg"
        >
            <MyMapsListSkeleton rows={props?.rows}/>
        </Table>
    </div>
  )
}

export default ScopoLoader