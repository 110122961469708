import {
  Divider,
  Group,
  Navbar,
  Radio,
  Text,
  Title,
  Button,
  Col,
  Grid,
  Tooltip,
  Stack,
  Collapse,
  Flex,
  Box,
  Paper,
  createStyles,
} from "@mantine/core";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import storeContext from "../store/store";
import { setHeatMap } from "../map-utils/setHeatMap";
import useElementSize from "../hooks/useElementSize";
import { ChevronDown, ChevronRight } from "tabler-icons-react";

import {
  Dataset,
  FilterData,
  FilterDataWithResponses,
  FilterType,
  SubCategory,
  Table,
} from "./filterbar-utils/filtertypes";
import { Filter, FilterTable } from "../types";

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.white,
    transition: 'all 0.3s ease',
    borderLeft: `1px solid ${theme.colors.gray[2]}`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing.md,
  },
  section: {
    backgroundColor: theme.white,
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.sm,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
  actionButton: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.colors.orange[6]}`,
    color: theme.colors.orange[6],
    '&:hover': {
      backgroundColor: theme.colors.orange[0],
    },
    '&:disabled': {
      backgroundColor: theme.colors.gray[1],
      borderColor: theme.colors.gray[4],
      color: theme.colors.gray[6],
    },
  }
}));

type HeatMapCategoryProps = {
  title: string;
  children?: React.ReactNode;
};

const HeatMapCategory: React.FC<HeatMapCategoryProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { classes } = useStyles();

  return (
    <Paper className={classes.section}>
      <div 
        className={classes.sectionHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text fw={600} size="sm">{title}</Text>
        {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
      </div>
      <Collapse in={isOpen}>
        <Box p="sm">
          <Stack spacing="xs">
            {children}
          </Stack>
        </Box>
      </Collapse>
    </Paper>
  );
};

type Props = {
  openHeatMap?: boolean;
  openFiltersBar?: boolean;
};

const HeatMap: FC<Props> = ({ openHeatMap }: Props) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const { height } = useElementSize("header-main");
  const [valueSelected, setValueSelected] = useState(false);
  const { classes } = useStyles();
  // set the subcategories
  const [subCategoriesWithCategory, setSubCategoriesWithCategory] = useState<
    { categoryTitle: string; subCategory: SubCategory }[]
  >([]);

  useEffect(() => {
    if (openHeatMap && state.mapPolygons.length > 0) {
      setHeatMap(state.mapPolygons, state.heatmapValue);
    }
  }, [
    state.heatmapValue,
    openHeatMap,
    state.mapPolygons,
    state.filterSelection,
  ]);

  useEffect(() => {
    // build subcategories here
    // Assuming state.filterSelection is an array of selected filters
    const data: Dataset = JSON.parse(JSON.stringify(state.summaryDataNewAPI));

    // if data is {} or undefined, return
    // TODO: Find an alternative way to know when to display the data to avoid iterating over an empty state variable
    if (!data.tables) return;

    if (!Array.isArray(data.tables) || data.tables.length === 0) return;

    // check if the filter selection is empty
    if (Object.keys(state.filterSelection).length == 0) return;

    const selected: FilterTable[] = JSON.parse(
      JSON.stringify(state.filterSelection)
    );

    console.log("filter selection", state.filterSelection);

    const subCategoriesWithCategory: {
      categoryTitle: string;
      subCategory: SubCategory;
    }[] = [];

    selected?.forEach((table) => {
      table.sub_categories.forEach((subCategory) => {
        // find subcategory in dataset
        let findSubcat = data.tables
          .find((t) => t.table_name === table.table_name)
          ?.sub_categories.find(
            (s) => s.sub_category_title === subCategory.sub_category_title
          ) ?? {
          sub_category_title: subCategory.sub_category_title,
          filters: [],
        };

        // remove filters that are not selected
        findSubcat.filters = findSubcat.filters.filter((filter) => {
          return subCategory.filters.find(
            (selectedFilter) =>
              selectedFilter.filter_name === filter.filter_name
          );
        });

        // find the table's category title
        let categoryTitle =
          data.tables.find((t) => t.table_name === table.table_name)
            ?.category_title ?? "";

        // add subcategory with its table category to the list
        subCategoriesWithCategory.push({
          categoryTitle,
          subCategory: findSubcat,
        });
      });
    });

    console.log("subCategories", subCategoriesWithCategory);
    setSubCategoriesWithCategory(subCategoriesWithCategory);
  }, [state.filterSelection, openHeatMap]);

  const handleExport = () => {
    dispatch({
      type: "setViewExportMapModal",
      payload: { visible: true, type: "heat" },
    });
  };

  const handleRadioChange = (e: any) => {
    // find the filter in the subCategories accounting for the top answers
    const filter = subCategoriesWithCategory
      .map((item) =>
        item.subCategory.filters.find((f) =>
          "responses" in f
            ? f.responses.find((r) => r.filter_name === e)
            : f.filter_name === e
        )
      )
      .filter((f) => f)[0];

    // If the filter has responses and the response with the name exists, return it
    if (filter && "responses" in filter) {
      const response = filter.responses.find((r) => r.filter_name === e);
      if (response) {
        dispatch({
          type: "setHeatmapValue",
          payload: response,
        });
      }
    }
    // If the filter does not have responses, return the filter name
    else if (filter) {
      dispatch({
        type: "setHeatmapValue",
        payload: filter,
      });
    }
    setValueSelected(true);
  };

  const renderHeatMapCategories = () => {
    // Group subcategories by their category title
    const groupedSubCategories = subCategoriesWithCategory.reduce(
      (groups, item) => {
        if (!groups[item.categoryTitle]) {
          groups[item.categoryTitle] = [];
        }
        groups[item.categoryTitle].push(item.subCategory);
        return groups;
      },
      {} as { [key: string]: SubCategory[] }
    );

    // Create HeatMapCategories with category titles
    return Object.keys(groupedSubCategories).map((categoryTitle) => (
      <div key={categoryTitle}>
        <Flex pl="10px" pt="4px" pb="8px">
          <Text ta="left" fw={700} fz="md" tt="capitalize">
            {categoryTitle}
          </Text>
        </Flex>
        {groupedSubCategories[categoryTitle].map((subCategory) => (
          <Fragment key={subCategory.sub_category_title}>
            <HeatMapCategory title={subCategory.sub_category_title}>
              <Group>{buildRadioGroup(subCategory.filters)}</Group>
            </HeatMapCategory>
          </Fragment>
        ))}
      </div>
    ));
  };

  const buildRadioGroup = (
    filters: (FilterData | FilterDataWithResponses)[]
  ) => {
    return (
      <Stack sx={(theme) => ({ gap: "md" })}>
        {filters.map((filter) => {
          // add check of if undefined
          if (filter) {
            if ("responses" in filter) {
              // console.log("heatmap", filter); // Debugging
              return filter.responses
                .sort((a, b) => b.area - a.area)
                .slice(0, filter.top_answers)
                .map((response) => (
                  <Fragment key={response.filter_name}>
                    <Radio
                      value={response.filter_name}
                      label={response.filter_name}
                    />
                  </Fragment>
                ));
            } else {
              return (
                <Fragment key={filter.filter_name}>
                  <Radio
                    value={filter.filter_name}
                    label={filter.filter_title}
                  />
                </Fragment>
              );
            }
          }
        })}
      </Stack>
    );
  };

  return (
    <div style={openHeatMap ? {} : { display: "none" }}>
      <Navbar
        className={classes.navbar}
        width={{ xs: 350 }}
        sx={{ height: `calc(100vh - ${height}px)`, top: `${height}px` }}
      >
        {state.filterData.setCheck ? (
          <>
            <Box className={classes.header}>
              <Group position="apart">
                <Title order={4}>Heatmap</Title>
                <Tooltip
                  label="Currently in Beta"
                  position="right"
                  withArrow
                  transitionProps={{ transition: "scale-x", duration: 300 }}
                  openDelay={500}
                >
                  <Button
                    className={classes.actionButton}
                    compact
                    onClick={handleExport}
                    disabled={!valueSelected}
                  >
                    Export
                  </Button>
                </Tooltip>
              </Group>
            </Box>

            <Box className={classes.content}>
              {subCategoriesWithCategory.length > 0 ? (
                <Radio.Group
                  onChange={(e) => handleRadioChange(e)}
                  size="sm"
                  required
                >
                  <Stack spacing="md">
                    {renderHeatMapCategories()}
                  </Stack>
                </Radio.Group>
              ) : (
                <Paper p="xl" withBorder>
                  <Text size="sm" align="center" color="dimmed">
                    Select your demographics to view a list of heatmaps to choose from
                  </Text>
                </Paper>
              )}
            </Box>
          </>
        ) : (
          <Box className={classes.content}>
            <Title order={4} mb="md">Heatmap</Title>
            <Paper p="xl" withBorder>
              <Text align="center" color="dimmed">
                Please Draw Shape to see heatmap
              </Text>
            </Paper>
          </Box>
        )}
      </Navbar>
    </div>
  );
};

export default HeatMap;
