import { Anchor, Group, Stack, Title } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const NotFound: FC = () => {
  return (
    <Stack
      align="center"
      style={{ minHeight: '100vh', justifyContent: 'center' }}
    >
      <Title align="center" order={1} style={{ width: '100%' }}>
        Page Not Found
      </Title>
      <Anchor
        component={Link}
        to="/"
        sx={(theme) => ({
          color: theme.colors.allColors[7],
        })}
      >
        Back to home page
      </Anchor>
    </Stack>
  );
};

export default NotFound;
