import { Wrapper, Status } from '@googlemaps/react-wrapper';

import Map from './Map';
// import Map from './TestMap';

const MapWrapper = () => {
  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <h1>Loading Map...</h1>;
      case Status.FAILURE:
        return <h1>Something Went Wrong!</h1>;
      case Status.SUCCESS:
        return <Map />;
    }
  };
  return (
    <Wrapper
      apiKey={`${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
      libraries={['drawing', 'places', 'geometry']}
      render={render}
    />
  );
};

export default MapWrapper;
