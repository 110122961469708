import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useGoogleLogout } from "react-google-login";
import { fetchUserSubscriptionDetails } from "../map-utils/fetchUserSubscriptionDetails";

const AuthContext = createContext<any>(undefined);

export const AuthProvider = (props: any) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [teamName, setTeamName] = useLocalStorage("team", null);

  // const MINUTE_MS = 3600000;

  // const fetchSubscriptionDetails = useCallback(async (key: string) => {
  //   const response = await axios.get(`${process.env.REACT_APP_BASE_URL}subscriptions/subscription_details/`, {
  //     headers: {
  //       Authorization: `token ${key}`,
  //     },
  //   });
  //   return response.data;
  // }, []);

  // useEffect(() => {
  //   let user = JSON.parse(localStorage.getItem("user")!);
  //   if (user?.key) {
  //     const interval = setInterval(() => {
  //       fetchSubscriptionDetails(user.key)
  //         .then((response) => {
  //           user.subscriptionDetails = response;
  //           setUser(user);
  //         })
  //         .catch((error) => console.log("error in fetching details", error));
  //     }, MINUTE_MS);
  //     return () => clearInterval(interval);
  //   }
  // }, [fetchSubscriptionDetails, setUser]);

  const onLogoutSuccess = () => {
    console.log("logout success");
  };

  const onFailure = () => {
    console.log("logout failure");
  };

  const { signOut } = useGoogleLogout({
    clientId: `${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}`,
    onLogoutSuccess: onLogoutSuccess,
    onFailure: onFailure,
  });

  const navigate = useNavigate();

  const changeLocalUsername = async (newUsername: string) => {
    let currentUserDetails = user;
    currentUserDetails.username = newUsername;
    setUser(currentUserDetails);
  };

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    if (data.key) {
      // fetchSubscriptionDetails(data.key)
      fetchUserSubscriptionDetails(data.key)
        .then((response) => {
          data.subscriptionDetails = response;
          setTeamName(response.org_name);
          setUser(data);
          if (data.redirect !== undefined && typeof data.redirect === "string")
            navigate(data.redirect);
          else navigate("/maplist");
        })
        .catch((err) =>
          console.log("error in fetching subscription details", err)
        );
      // const response = await axios.get(
      //   `${process.env.REACT_APP_BASE_URL}subscriptions/subscription_details/`,
      //   {
      //     headers: {
      //       Authorization: `token ${data.key}`,
      //     },
      //   }
      // );
      // data.subscriptionDetails = response.data;
      // setTeamName(response.data.org_name);
    }
    // setUser(data);
    // if (data.redirect !== undefined && typeof data.redirect === "string")
    //   navigate(data.redirect);
    // else navigate("/maplist");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setTeamName(null);
    // navigate("/login", { replace: true });
    signOut();
  };

  const value = useMemo(
    () => ({
      user,
      teamName,
      setTeamName,
      login,
      logout,
      changeLocalUsername,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, teamName]
  );
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
