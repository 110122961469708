import {
  Modal,
  Text,
  Group,
  Button,
  Stack,
  List,
  ThemeIcon,
  LoadingOverlay,
} from "@mantine/core";
import { useContext, useState } from "react";
import storeContext from "../store/store";
import { Table, SubCategory } from "./filterbar-utils/filtertypes";
import { Check, FileAnalytics } from "tabler-icons-react";
import { ReportProgress } from "./ReportProgress";
import { useAuth } from "../hooks/useAuth";

interface ReportGenerationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function ReportGenerationModal({ onConfirm, onCancel }: ReportGenerationModalProps) {
  const context = useContext(storeContext);
  const { user } = useAuth();
  const [isInitiating, setIsInitiating] = useState(false);
  
  if (!context) {
    throw new Error(
      "ReportGenerationModal must be used within a StoreProvider"
    );
  }
  const { state, dispatch } = context;

  const handleClose = () => {
    if (isInitiating) return;
    
    if (state.reportProgress?.status === 'completed' || state.reportProgress?.status === 'failed') {
      dispatch({ type: "resetReportState" });
    }
    
    dispatch({ 
      type: "setModalState", 
      payload: { modal: "reportGeneration", state: false } 
    });
  };

  const handleConfirm = async () => {
    setIsInitiating(true);
    try {
      await onConfirm();
    } finally {
      setIsInitiating(false);
    }
  };

  const tables = state.summaryDataNewAPI?.tables || [];
  const totalCategories = tables.reduce((acc: number, table: Table) => {
    const subCatCount = table.sub_categories?.length || 0;
    return acc + subCatCount;
  }, 0);

  const estimatedTime = Math.ceil((totalCategories * 10) / 60);

  return (
    <Modal
      opened={state.modals.reportGeneration}
      onClose={handleClose}
      title={
        <Group spacing="xs">
          <FileAnalytics size={20} />
          <Text>Generate Demographic Report</Text>
        </Group>
      }
      centered
      size="lg"
      closeOnClickOutside={!isInitiating}
      closeOnEscape={!isInitiating}
      withCloseButton={!isInitiating}
      trapFocus
    >
      <LoadingOverlay visible={isInitiating} overlayBlur={1} />
      {state.reportProgress?.progress_id ? (
        <ReportProgress 
          progress_id={state.reportProgress.progress_id}
          user={user}
          onClose={handleClose}
        />
      ) : (
        <Stack>
          <Text size="sm">
            This will generate a detailed demographic report with the following
            categories:
          </Text>

          <List size="sm" spacing="xs" withPadding>
            {tables.map((table: Table) => (
              <List.Item 
                key={table.category_title}
                icon={
                  <ThemeIcon color="orange" size={20} radius="xl">
                    <Check size={12} />
                  </ThemeIcon>
                }
              >
                <Text weight={500}>{table.category_title}</Text>
                <List size="xs" withPadding ml={20}>
                  {table.sub_categories?.map((subCat: SubCategory) => (
                    <List.Item key={subCat.sub_category_title}>
                      {subCat.sub_category_title}
                    </List.Item>
                  ))}
                </List>
              </List.Item>
            ))}
          </List>

          <Text size="sm" mt="md">
            Processing will take approximately {estimatedTime} minutes (
            {totalCategories} categories × ~10 seconds each).
          </Text>

          <Group position="right" mt="xl">
            <Button variant="default" onClick={handleClose} disabled={isInitiating}>
              Cancel
            </Button>
            <Button 
              color="orange" 
              onClick={handleConfirm}
              leftIcon={<FileAnalytics size={16} />}
              loading={isInitiating}
              disabled={isInitiating}
            >
              Generate Report
            </Button>
          </Group>
        </Stack>
      )}
    </Modal>
  );
}
