import { FC, useEffect, useRef } from "react";
import { createStyles, Notification } from "@mantine/core";
import { useAuth } from "../hooks/useAuth";

type Props = {
  subscriptionDetails?: any;
  clientHeight?: any
};
const useStyles = createStyles((theme) => ({

  notificationRoot: {
    "&:before": {
      backgroundColor: "transparent",
    },
  },
}));

const TopNotification: FC<Props> = ({subscriptionDetails}:Props) => {
  const { classes } = useStyles();
  const { user } = useAuth();

  return (
    <>
      <Notification
        styles={{
          root: {
            backgroundColor: "#DFF8FF",
            boxShadow: "none",
            border: "0px",
            paddingBottom: "6px",
            paddingTop: "6px",
            textAlign: "center",
          },
          description: { color: "#1098AD !important" },
          closeButton: { color: "orange" } 
        }}
        className={classes.notificationRoot}
        withCloseButton={false}
      >
        <a href="/pricing">
          You have {Math.max(Math.ceil((user.subscriptionDetails.subscription_expires - Date.now() / 1000)/86400), 0)} days of your trial remaining
        </a>
      </Notification>
    </>
  );
};

export default TopNotification;
