import { Dispatch, LatLngType, PolygonCustomType, State, Map } from "../types";
import { handlePolygonDelete } from "./handlePolygonDelete";
import { editPolygon } from "./handlePolygonEdit";

export const showPolygonActionsModal = (
  polygon: PolygonCustomType,
  dispatch: Dispatch,
  state: State,
  latLng: LatLngType,
  userkey: string,
  map?: Map
) => {
  let bounds = new google.maps.LatLngBounds();
  const htmlMsg = `
    <div class="actions-modal">
      <div class="action-modal-inner">
        <button onclick="handleEdit()" class="action-btn">  
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00abfb" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
            <line x1="16" y1="5" x2="19" y2="8" />
          </svg>Edit
        </button>
        <button onclick="handleDelete()" class="action-btn">  
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="4" y1="7" x2="20" y2="7" />
            <line x1="10" y1="11" x2="10" y2="17" />
            <line x1="14" y1="11" x2="14" y2="17" />
            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
        </svg>Delete
        </button>
      </div>
    </div>
    `;
  if (window.polygonsInfoWindows) {
    window.polygonsInfoWindows.close();
  }
  for (let i = 0; i < polygon.getPath().getLength(); i++) {
    bounds.extend(polygon.getPath().getAt(i));
  }
  polygon.infoWindow = new google.maps.InfoWindow({
    content: htmlMsg,
    ariaLabel: "polygon-options",
    pixelOffset: new google.maps.Size(0, 115),
  });
  window.polygonsInfoWindows = polygon.infoWindow;
  polygon.infoWindow.setPosition(latLng);
  polygon.infoWindow.open(map ? map : polygon.getMap());
  window.handleDelete = async function handleDelete() {
    handlePolygonDelete(polygon, dispatch, state, userkey, map);
    polygon.infoWindow.close();
  };

  window.handleEdit = async function handleEdit() {
    editPolygon(polygon);
    polygon.infoWindow.close();
  };
  document.addEventListener("click", () => polygon.infoWindow.close());
};
