import { Modal, Group, Button, Collapse, Text, useMantineTheme, List, Stack } from '@mantine/core';
import React from 'react';
import { MapPin } from 'tabler-icons-react';

function TutorialModal({ opened, setOpened, title, animationSrc, instructions }: { opened: boolean, setOpened: React.Dispatch<React.SetStateAction<boolean>>, title: string, animationSrc: string, instructions: string[] }) {
  const theme = useMantineTheme();
  // Collapsible state for step-by-step instructions
  const [collapsed, setCollapsed] = React.useState(false);

  // Render instructions as list items
  const renderInstructions = (instructions: any[]) => {
    return (
      <List
        icon={<MapPin size={16} />}
        styles={{ item: { paddingLeft: 20 }}}
        center={true}
        spacing={"md"}
      >
        {instructions.map((instruction, index) => (
          <List.Item
          key={index}
          >{instruction}</List.Item>
        ))}
      </List>
    );
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title={title}
      size="xl"
      styles={{
        body: { paddingBottom: 20 },
        title: { marginBottom: 20 },
        close: { marginBottom: 20 },
      }}
    >
      <Stack spacing={20}>

        {/* Animated gif/video demonstration */}
        

        {/* Collapsible instructions with list */}
        <Text size="sm" onClick={() => setCollapsed(!collapsed)} style={{ cursor: 'pointer', color: theme.colors.orange[6] }}>
          {collapsed ? 'Show Instructions' : 'Hide Instructions'}
        </Text>
        <Collapse in={!collapsed}>
          {renderInstructions(instructions)}
        </Collapse>

      </Stack>

      {/* Confirmation or "Got it" button */}
      <Button color="orange" onClick={() => setOpened(false)} style={{ marginTop: 40 }}>Got it</Button>

    </Modal>
  );
}

export default TutorialModal;