import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, isNotEmpty, isEmail, isInRange, hasLength, matches } from '@mantine/form';
import {
  Grid,
  TextInput,
  Text,
  Group,
  Button,
  Divider,
  Anchor,
  // MediaQuery,
  Alert,
  Stack,
} from "@mantine/core";
import "../css/login.css";
import { GLogin } from "../components/GoogleAuth";
import { gapi } from "gapi-script";
import { AlertCircle } from "tabler-icons-react";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import BrandSection from "../components/BrandSection";

const Login: FC = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { login } = useAuth();

  useEffect(() => {
    function start() {
      gapi.auth2.init({
        clientId: `${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}`,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },

    validate: {
      // username: (val) => /^\S+@\S+$/.test(val),
      username: hasLength({ min: 6}),
      password: hasLength({ min: 6}),
    },
  });
  const handleSubmit = async (values: any) => {
    setShowAlert(false);
    let formdata = new FormData();
    try {
      formdata.append("username", values.username);
      formdata.append("password", values.password);

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}dj-rest-auth/login/`,
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });

      const user_data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}dj-rest-auth/user/`,
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + response.data.key,
        },
      });

      login({
        key: response.data.key,
        username: user_data.data.username,
        user_id: user_data.data.pk,
      });
    } catch {
      setShowAlert(true);
    }
  };
  return (
    // <section className="login-wrap">
    //   <Container my="md" mt={0} mb={0} className="main-container">
    <Grid p={0} m={0}>
      <Grid.Col xs={12} sm={6} lg={7} p={0} className="left-container">
        <div className="log-left">
          <div className="main-box">
            {showAlert && (
              <Alert
                icon={<AlertCircle size={16} />}
                title="Sign in failed"
                color="red"
                style={{ marginBottom: "1rem" }}
              >
                Username or Password is incorrect
              </Alert>
            )}
            <Text size={"xl"} className="title-text">
              Sign In
            </Text>
            <GLogin title="Sign In" />
            <Divider
              label="Or"
              labelPosition="center"
              styles={{
                label: { fontSize: 16, color: "black !important" },
              }}
              className="hr-divider"
            />
            <Text size="md" weight={300} className="welcome-text">
              Welcome back to Scopomap. Enter your details below:
            </Text>
            <form onSubmit={form.onSubmit((values: any) => handleSubmit(values))}>
              <Stack>
                <TextInput
                  type="text"
                  id="username"
                  required
                  placeholder="Username"
                  value={form.values.username}
                  mt={20}
                  onChange={(event) =>
                    form.setFieldValue("username", event.currentTarget.value)
                  }
                  error={form.errors.username && "Invalid username"}
                />

                <TextInput
                  type="password"
                  id="password"
                  required
                  placeholder="Password"
                  value={form.values.password}
                  onChange={(event) =>
                    form.setFieldValue("password", event.currentTarget.value)
                  }
                  error={
                    form.errors.password &&
                    "Password should include at least 6 characters"
                  }
                />
                <Button type="submit" color={"orange"} mt={10} id="submitform">
                  Log In
                </Button>
              </Stack>

              <Group position="apart" mt="xl">
                <Anchor
                  component={Link}
                  to="/forgot-password"
                  type="button"
                  color="gray"
                  size="xs"
                  className="anchor-text"
                  underline={true}
                >
                  Forgot Password?
                </Anchor>
                <Anchor
                  component={Link}
                  to="/signup"
                  type="button"
                  color="gray"
                  size="xs"
                  className="anchor-text"
                  underline={true}
                >
                  Don't have an account?
                </Anchor>
              </Group>
            </form>
          </div>
        </div>
      </Grid.Col>
      {/* <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Grid.Col xs={12} sm={6} lg={5} p={0}>
          <div className="log-right">
            <div className="log-img">
              <div className="loc-img loc-1">
                <img src="images/location-icon.svg" alt="location icon" />
              </div>
              <div className="loc-img loc-2">
                <img src="images/location-icon.svg" alt="location icon" />
              </div>
              <div className="loc-img loc-3">
                <img src="images/location-icon.svg" alt="location icon" />
              </div>
              <div className="logo">
                <img src="images/logo.png" alt="logo" />
              </div>
              <div className="text-box">
                <Text className="text" color={"#fff"}>
                  Powerful.
                  <br /> Insights.
                  <br /> Easy.
                  <br />
                </Text>
              </div>
              <img src="images/login-bg.svg" alt="" />
            </div>
            <div className="log-info">
              <div className="log-info-row">
                <div className="log-avatar">
                  <img src="images/avatar-dummy.jpeg" alt="" />
                </div>
                <div className="info-text">
                  <span className="quote">"</span>
                  <p>
                    I was able to discover my exact target audience in just a
                    few clicks
                  </p>
                  <span>Sally Anne - Head of Marketing</span>
                </div>
              </div>
            </div>
          </div>
        </Grid.Col>
      </MediaQuery> */}
      <BrandSection />
    </Grid>
    //   </Container>
    // </section>
  );
};

export default Login;
