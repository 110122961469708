import AppShellLayout from "../components/AppShellLayout";
import MyArchivedMapList from "../components/MyArchivedMapList";

const ArchivedMaps = () => {
  return (
    <AppShellLayout>
      <MyArchivedMapList />
    </AppShellLayout>
  );
};

export default ArchivedMaps;
