import { Group, Title, Button, createStyles, Box, Divider } from "@mantine/core";
import { FilterTable } from "../../../types";
import { HeartPlus } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    padding: theme.spacing.md,
    backgroundColor: theme.white,
  },
  title: {
    color: theme.colors.gray[8],
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
  },
  button: {
    '&:hover': {
      backgroundColor: theme.colors.orange[1],
    },
  },
}));

interface FiltersPanelProps {
  filters: FilterTable[];
  isEdited: boolean;
  handleSaveClick: () => void;
}

const FiltersPanel: React.FC<FiltersPanelProps> = ({
  filters,
  isEdited,
  handleSaveClick,
}) => {
  const { classes } = useStyles();

  return (
    <Box>
      <Group position="apart" className={classes.container}>
        <Title className={classes.title}>Demographics Builder</Title>
        <Button
          leftIcon={<HeartPlus size={16} />}
          variant="subtle"
          color="orange"
          onClick={handleSaveClick}
          disabled={!filters.length}
          className={classes.button}
        >
          Create Filter
        </Button>
      </Group>
    </Box>
  );
};

export default FiltersPanel;