import {
  Group,
  Checkbox,
  createStyles,
  RangeSlider,
  NumberInput,
  Stack,
  SimpleGrid,
} from "@mantine/core";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import storeContext from "../../store/store";

const useStyles = createStyles((theme) => ({
  sliderRoot: {
    maxWidth: 260,
    width: "100%",
  },
  sliderTrack: {
    backgroundColor: "#E9EAED",
    height: 5,
    borderRadius: 3,
  },
  sliderBar: {
    backgroundColor: "#FF8E16",
  },
  sliderThumb: {
    borderColor: "#FF8E16",
  },
  checkBox: {
    "&:checked": {
      backgroundColor: theme.colors.allColors[7],
      borderColor: theme.colors.allColors[7],
    },
  },
}));

function SliderGroup(props: any) {
  const { classes } = useStyles();
  const { state, dispatch } = useContext<any>(storeContext);
  const { value, groupName, defaultValue } = props;
  const { guid } = useParams();

  // keep track of the range of the slider
  const presetMax = value[1];
  const presetMin = value[0];

  let range = value[1] - value[0];

  // set the step value for the slider
  let step = 1;
  if (range > 100) step = 10;
  if (range < 10) step = 0.5;
  if (range < 3) step = 0.1;
  if (range < 1) step = 0.01;
  if (range < 0.3) step = 0.005;
  const [check, setCheck] = useState(props.defaultChecked || false);
  const [changeValue, setChangeValue] = useState(value);

  //console.log("props", state.filterDataAlternative);

  // set decimal precision for the min and max values - accounts for numbers like 2.900000002
  value[0] = parseFloat(value[0].toFixed(2));
  value[1] = parseFloat(value[1].toFixed(2));

  // dispatch the changed values to the store
  const dispatchChangedValues = (
    set: boolean,
    min: number | "",
    max: number | ""
  ) => {
    // if the set is false, set the values to preset values
    if (!set) {
      min = presetMin;
      max = presetMax;
    }

    const minValue = min === "" ? value[0] : min;
    const maxValue = max === "" ? value[1] : max;

    dispatch({
      type: "setFiltersDataToSave",
      payload: {
        title: props.title,
        set,
        min: minValue,
        max: maxValue,
      },
    });

    dispatch({
      type: "setFilterDataAlternative",
      payload: {
        title: props.propertyName,
        set,
        minValue,
        maxValue,
        maximumSelected: value[1],
        minimumSelected: value[0],
        groupName: groupName,
        units: props.units,
      },
    });
  };

  return (
    <Stack p="lg" pt="md">
      <Checkbox
        classNames={{
          input: `${classes.checkBox}`,
        }}
        size="sm"
        radius={0}
        label={props.title}
        checked={check}
        onChange={(e) => {
          setCheck(e.currentTarget.checked);
          dispatchChangedValues(
            e.currentTarget.checked,
            changeValue[0],
            changeValue[1]
          );
        }}
        disabled={guid ? true : false}
      />
      {check && (
        <>
          <RangeSlider
            // value={changeValue}
            size={"sm"}
            classNames={{
              root: `${classes.sliderRoot}`,
              track: `${classes.sliderTrack}`,
              bar: `${classes.sliderBar}`,
              thumb: `${classes.sliderThumb}`,
            }}
            thumbSize={16}
            min={value[0]}
            max={value[1]}
            defaultValue={defaultValue}
            step={step}
            label={props.sliderLabel}
            onChange={(e) => {
              setChangeValue(e);
              dispatchChangedValues(check, e[0], e[1]);
            }}
            minRange={step}
            disabled={guid ? true : false}
          />
          <SimpleGrid cols={2}>
            <NumberInput
              value={changeValue[0]}
              onChange={(e) => {
                setChangeValue([e, changeValue[1]]);
                dispatchChangedValues(check, e, changeValue[1]);
              }}
              disabled={guid ? true : false}
            />
            <NumberInput
              value={changeValue[1]}
              onChange={(e) => {
                setChangeValue([changeValue[0], e]);
                dispatchChangedValues(check, changeValue[0], e);
              }}
              disabled={guid ? true : false}
            />
          </SimpleGrid>
        </>
      )}
    </Stack>
  );
}

export default SliderGroup;
