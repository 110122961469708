const calculateSum = (arr: any[], key: string) => {
  let sum = arr.reduce((total: any, obj: any) => obj[key] + total, 0);
  return sum;
};

const getPolygonClosureUid = (polygon: any) => {
  let key = Object.keys(polygon).find((key: any) => {
    let found = key.includes("closure_uid");
    if (found) {
      return key;
    }
    return null;
  });
  if (key) {
    return polygon[key];
  }
};

function sumTotal(ob1: any, ob2: any) {
  let sum: any = {};
  let replaceItems = [
    "females",
    "males",
    "median_age",
    "median_age_diff_from_state",
    "median_income_diff_from_state",
    "percentage_aboriginals",
    "percentage_appartments",
    "apartment_percentage_diff_from_state",
    "percentage_internet_accessed",
    "percentage_rental",
    "rentals_diff_from_state",
    "unemployment_for_area",
    "unemployed_percentage_diff_from_state",
    "uni_degree_percentage",
    "uni_degree_percentage_diff_from_state",
    "weekly_household_income",
    "languages",
  ];

  Object.keys(ob1).forEach((key) => {
    if (ob2.hasOwnProperty(key)) {
      if (replaceItems.includes(key)) sum[key] = ob2[key];
      else sum[key] = ob1[key] + ob2[key];
    }
  });
  return sum;
}

const calculateDifference = (ob1: any, ob2: any) => {
  let difference: any = {};
  let replaceItems = [
    "females",
    "males",
    "median_age",
    "percentage_aboriginals",
    "percentage_appartments",
    "percentage_internet_accessed",
    "percentage_rental",
    "unemployment_for_area",
    "uni_degree_percentage",
    "weekly_household_income",
    "languages",
  ];

  Object.keys(ob1).forEach((key) => {
    if (ob2.hasOwnProperty(key)) {
      difference[key] = ob1[key] - ob2[key];
      // if (replaceItems.includes(key)) difference[key] = ob2[key];
      if (replaceItems.includes(key)) difference[key] = ob1[key];
      else difference[key] = ob1[key] - ob2[key];
    }
  });
  return difference;
};

export {
  calculateSum,
  getPolygonClosureUid,
  calculateDifference,
  sumTotal,
};
