import axios from "axios";
export const getBusinessLocations = (
  allPolygons: any,
  map: any,
  dispatch: any,
  state: any
) => {
  let polygons = allPolygons !== "" ? allPolygons.split(",") : [];
  window?.businessLocations?.forEach((loc: any) => {
    loc.setMap(null);
  });

  // state.businessLocations.forEach(function (businessLocation: any) {
  //   businessLocation.setMap(null);
  // });
  dispatch({ type: "resetBusinessLocation", payload: [] });

  let response = null;
  if (polygons.length) {
    polygons.forEach(async function (polygon: any) {
      let formData = new FormData();
      formData.append("encoded_polygon_str", polygon);

      response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}business_addresses/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      response.data.business_addresses.forEach(async function (
        businessLocation: any
      ) {
        businessLocation = businessLocation.replace("(", "").replace(")", "");
        let businessCoordinates = businessLocation.split(",");
        const latLang = new google.maps.LatLng(
          businessCoordinates[0],
          businessCoordinates[1]
        );
        const businessMarker = new google.maps.Marker({
          position: latLang,
          //map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "blue",
            fillOpacity: 0.4,
            scale: 4.5,
            strokeColor: "white",
            strokeWeight: 1,
          },
        });
        if (state.showBusinessLocation) businessMarker.setMap(map);
        dispatch({ type: "addBusinessLocations", payload: businessMarker });
        window?.businessLocations?.push(businessMarker);
      });

      // if (++polygonsDone === polygons.length) {
      // }
    });
  }
};
