/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useContext, useEffect } from "react";
import {
  createStyles,
  getStylesRef,
  Navbar as MUINavbar,
  Group,
  Image,
  Title,
  Switch,
  Menu,
  Modal,
  Button,
  Collapse,
  Grid,
  Radio,
  useMantineTheme,
  SimpleGrid,
  Anchor,
  Text,
  Flex,
  Box,
  ActionIcon,
} from "@mantine/core";
import {
  Settings,
  HeartHandshake,
  Map,
  Archive,
  ArrowForwardUp,
  CloudUpload,
  FileDownload,
  BuildingStore,
  MessageDots,
  User,
  Logout,
  AddressBook,
  Users,
  Copy,
  Polygon,
  Lifebuoy,
  ArrowsUpDown,
  Report,
  ReportMoney,
  ArrowBarRight,
  ArrowBarLeft,
} from "tabler-icons-react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import "../css/navbar.css";
import UserButton from "./UserButton";
import { useAuth } from "../hooks/useAuth";
import storeContext from "../store/store";
import { downloadKML } from "../map-utils/downloadKML";
import DropZoneModal from "../components/DropZoneModal";
import AddressesModal from "./AddressesModal";
import ShareMapModal from "./ShareMapModal";
import axios from "axios";

const useStyles = createStyles((theme) => {
  const icon = getStylesRef('icon');
  return {
    header: {
      padding: theme.spacing.md,
      marginBottom: theme.spacing.md,
      borderBottom: `1px solid ${theme.colors.gray[7]}`,
      backgroundColor: '#414042',
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${theme.colors.gray[7]}`,
    },

    section: {
      backgroundColor: 'transparent',
      padding: theme.spacing.md,
      marginBottom: theme.spacing.md,
      borderRadius: theme.radius.sm,
      border: `1px solid ${theme.colors.gray[7]}`,
    },

    sectionTitle: {
      color: theme.colors.gray[5],
      fontSize: theme.fontSizes.xs,
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      marginBottom: theme.spacing.md,
      paddingLeft: theme.spacing.xs,
    },

    divider: {
      borderTop: `1px solid ${theme.colors.gray[7]}`,
      margin: `${theme.spacing.md}px 0`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      color: theme.white,
      transition: 'all 150ms ease',
      marginBottom: 2,

      '&:hover': {
        backgroundColor: theme.fn.rgba(theme.colors.orange[7], 0.15),
        color: theme.white,
        cursor: 'pointer',
        transform: 'translateX(3px)',
      },
    },

    linkIcon: {
      ref: icon,
      marginRight: theme.spacing.sm,
      width: '0.875rem',
      height: '1.05625rem',
      color: theme.white,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.rgba(theme.colors.orange[7], 0.25),
        color: theme.white,
      },
    },

    displayOptionItem: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '8px 0',
      borderBottom: `1px solid ${theme.colors.gray[7]}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },

    displayOptionIcon: {
      width: '0.875rem',
      height: '1.05625rem',
      marginRight: theme.spacing.sm,
      color: theme.colors.gray[5],
      marginTop: '2px',
    },

    displayOptionContent: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: theme.spacing.xs,
    },

    benchmarkingContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },

    benchmarkingHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '4px',
    },

    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing.lg,
      marginLeft: '4px',
    },

    radioWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
    },

    radio: {
      backgroundColor: theme.colors.dark[6],
      borderColor: theme.colors.dark[4],
      
      '&:checked': {
        backgroundColor: theme.colors.orange[6],
        borderColor: theme.colors.orange[6],
      },
    },

    switchWrapper: {
      width: '100%',
      backgroundColor: 'transparent',
    },

    switchWrapperInner: {
      width: '100%',
      padding: '0',
    },

    switchRoot: {
      lineHeight: '1.55rem',
      flexDirection: 'row-reverse',
      alignItems: 'top',
      alignContent: 'top',
      width: '80%',
      justifyContent: 'space-between',
    },

    switchTrack: {
      backgroundColor: theme.colors.dark[6],
      border: 'none',
      'input:checked ~ &': {
        backgroundColor: theme.colors.dark[6],
      },
    },

    switchThumb: {
      backgroundColor: theme.colors.gray[4],
      border: 'none',
      color: theme.white,

      'input:checked + * > &': {
        backgroundColor: theme.colors.orange[6],
      },
    },

    switchLabel: {
      color: theme.white,
      fontSize: '0.875rem',
    },

    radioLabel: {
      color: theme.white,
      fontSize: '0.875rem',
    },

    radioButton: {
      border: 'none',
      height: '1rem',
      width: '1rem',
    },

    show: {
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        transform: 'translateX(0%)',
      },
    },

    hide: {
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        transform: 'translateX(100%)',
      },
    },

    summaryToggleBtn: {
      display: 'none',
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        transform: 'translateX(-0%)',
        display: 'block',
        height: '37px',
        position: 'fixed',
        top: '37.3%',
        right: '0',
        padding: '3px',
        width: '33px',
        zIndex: 1000,
        backgroundColor: theme.colors.orange[7],
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
      },
    },

    addressesModalheader: {
      backgroundColor: theme.colors.orange[6],
      minHeight: '4.5rem',
      padding: '0.625rem 0.625rem !important',
    },

    title: {
      color: theme.white,
      letterSpacing: '0.1rem',
    },

    close: {
      color: theme.white,
      '&:hover': {
        backgroundColor: theme.white,
        color: theme.colors.orange[7],
      },
    },

    switchIcon: {
      ref: icon,
      marginRight: theme.spacing.sm,
      width: '0.875rem',
      height: '1.05625rem',
      color: theme.white,
      '&:hover': {
        color: theme.colors.orange[7],
        cursor: 'pointer',
      },
    },
  };
});

const data = [
  // { link: "", label: "What's New", icon: BellRinging },
  { link: "/team", label: "Team", icon: Users },
  { link: "/settings", label: "Settings", icon: Settings },
  { link: "/contact-us", label: "Contact Us", icon: HeartHandshake },
];

const mapsLinksData = [
  { link: "/maplist", label: "Map Library", icon: Map },
  { link: "/sharable-maps", label: "Shared Maps", icon: ArrowForwardUp },
  { link: "/archived-maps", label: "Archived Maps", icon: Archive },
];

const adminLinksData = [
  { link: "/subscriptions-report", label: "Subscriptions Report", icon: ReportMoney },
  { link: "/adderss-downloads-report", label: "Address Downloads", icon: Report },
];

interface NavbarProps {
  opened: boolean;
  setOpenedNavbar?: () => void;
  extraClassName?: string;
}

const Navbar: FC<NavbarProps> = ({
  opened,
  setOpenedNavbar = () => {},
  extraClassName,
}) => {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Billing");
  const { state, dispatch } = useContext<any>(storeContext);
  const [dropzoneOpened, setDropzoneOpened] = useState(false);
  const [addressesModalOpened, setAddressesModalOpened] = useState(false);
  const [shareMapOpened, setShareMapOpened] = useState<boolean>(false);
  const [isJobSaved, setIsJobSaved] = useState<boolean>(true);
  const [isMapSetsOpened, setMapSetsOpen] = useState(false);
  const navigate = useNavigate();

  const { user, logout } = useAuth();
  const { id } = useParams();
  let { pathname } = useLocation();

  const theme = useMantineTheme();

  useEffect(() => {
    if (state.showMarkers) {
      state.markers.forEach((comment: any) => {
        comment.marker.setMap(state.map);
      });
    } else {
      state.markers.forEach((comment: any) => {
        comment.marker.setMap(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showMarkers]);

  useEffect(() => {
    if (state.showBusinessLocation) {
      state.businessLocations.forEach((businessLocation: any) => {
        businessLocation.setMap(state.map);
      });
    } else {
      state.businessLocations.forEach((businessLocation: any) => {
        businessLocation.setMap(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showBusinessLocation]);

  useEffect(() => {
    if (state.jobId && (state.jobId > 0 || state.jobId.length > 0)) {
      setIsJobSaved(true);
    } else {
      setIsJobSaved(false);
    }
  }, [isJobSaved, state.jobId]);

  const handleKMLFileDownload = () => {
    if (state.polygons) {
      downloadKML(state.polygons);
    }
  };

  function archiveMap(mapId: string) {
    let formData = new FormData();
    formData.append("map_id", mapId.toString());
    formData.append("archived_flag ", "True");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}set_map_archive_flag/`,
        formData,
        {
          headers: {
            Authorization: `token ${user.key}`,
          },
        }
      )
      .then(() => {
        let unarchivedUserMaps: any[] = [];
        state.userMaps.forEach(function (userMap: any) {
          if (userMap.id !== parseInt(mapId)) unarchivedUserMaps.push(userMap);
        });
        dispatch({ type: "setUserMaps", payload: unarchivedUserMaps });
      });
  }

  const toggleMarkers = () => {
    dispatch({ type: "toggleMarkers", payload: null });
  };

  const toggleBusinessLocation = () => {
    const stylesVisiblePOI = [
      {
        featureType: "poi",
        stylers: [{ visibility: "on" }],
      },
    ]
    const stylesInvisiblePOI = [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
    ]
    let newShowBusinessLocation = !state.showBusinessLocation;
    if(newShowBusinessLocation)
      state.map.setOptions({ styles: stylesVisiblePOI });
    else
      state.map.setOptions({ styles: stylesInvisiblePOI });
      dispatch({ type: "toggleBusinessLocation", payload: newShowBusinessLocation });
    };

  const setBenchmark = (newBenchmark: string) => {
    dispatch({ type: "setBenchmark", payload: newBenchmark });
  };

  const checkIfMapIsSaved = () => {
    if (state.jobId && (state.jobId > 0 || state.jobId.length > 0)) {
      return true;
    } else {
      return false;
    }
  };

  const currentRoute = useLocation().pathname;

  const links = data.map((item) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      // href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        if (currentRoute === "/") {
          if (checkIfMapIsSaved()) {
            setActive(item.label);
            navigate(item.link);
          } else {
            const confirmResult = window.confirm(
              "Your map is unsaved. Are you sure you want to leave?"
            );
            if (confirmResult === true) {
              setActive(item.label);
              navigate(item.link);
            }
          }
        } else {
          setActive(item.label);
          navigate(item.link);
        }
      }}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </a>
  ));

  const mainLinks1 = (
    <div>
      <a
        className={classes.link}
        key="Load KML"
        onClick={(event) => {
          event.preventDefault();
          setDropzoneOpened(true);
        }}
      >
        <CloudUpload className={classes.linkIcon} />
        <span>Load KML</span>
      </a>
      <Modal
        centered
        opened={dropzoneOpened}
        onClose={() => setDropzoneOpened(false)}
        padding={0}
        withCloseButton={true}
        title="Upload KML"
        size="lg"
      >
        <DropZoneModal
          setOpenedNavbar={setOpenedNavbar}
          setDropzoneOpened={() => setDropzoneOpened(false)}
        />
      </Modal>

      <a
        className={classes.link}
        key="Export KML"
        onClick={(event) => {
          event.preventDefault();
          handleKMLFileDownload();
        }}
      >
        <FileDownload className={classes.linkIcon} />
        <span>Export KML</span>
      </a>

      <a
        className={classes.link}
        key="Generate Address List"
        onClick={(event) => {
          event.preventDefault();
          setAddressesModalOpened(true);
        }}
      >
        <AddressBook className={classes.linkIcon} />
        <span>Generate Address List</span>
      </a>
      <Modal
        opened={addressesModalOpened}
        onClose={() => setAddressesModalOpened(false)}
        title="Adresses List"
        size="auto"
        classNames={{
        }}
      >
        <AddressesModal
          opened={addressesModalOpened}
          setOpenedNavbar={setOpenedNavbar}
          setAddressesModalOpened={() => setAddressesModalOpened(false)}
        />
      </Modal>

      {isJobSaved && (
        <a
          className={classes.link}
          key="Duplicate"
          onClick={(event) => {
            navigate(`/${state.jobId}/copy`);
          }}
        >
          <Copy className={classes.linkIcon} />
          <span>Duplicate</span>
        </a>
      )}

      {isJobSaved && (
        <a
          className={classes.link}
          key="Share"
          onClick={(event) => {
            event.preventDefault();
            setShareMapOpened(true);
          }}
        >
          <ArrowForwardUp className={classes.linkIcon} />
          <span>Share</span>
        </a>
      )}
      {/* Share Map */}
      <Modal
        centered
        padding={0}
        opened={shareMapOpened}
        onClose={() => setShareMapOpened(false)}
        title="Share this Map"
      >
        <ShareMapModal close={setShareMapOpened} sharedMapId={state.jobId} />
      </Modal>

      {isJobSaved && (
        <a
          className={classes.link}
          key="Archive"
          onClick={(event) => {
            event.preventDefault();
            if (id) {
              archiveMap(id);
            }
          }}
        >
          <Archive className={classes.linkIcon} />
          <span>Archive</span>
        </a>
      )}
    </div>
  );

  <></>;

  const mapsLinks = mapsLinksData.map((item) => (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        if (currentRoute === "/") {
          if (checkIfMapIsSaved()) {
            // Map is saved, navigate to the link
            setActive(item.label);
            navigate(item.link);
          } else {
            // Map is not saved, prompt user to save or discard
            const confirmResult = window.confirm(
              "Your map is unsaved. Are you sure you want to leave?"
            );
            if (confirmResult === true) {
              setActive(item.label);
              navigate(item.link);
            }
          }
        } else {
          setActive(item.label);
          navigate(item.link);
        }
      }}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </Link>
  ));

  const adminLinks = adminLinksData.map((item) => (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        if (currentRoute === "/") {
          if (checkIfMapIsSaved()) {
            // Map is saved, navigate to the link
            setActive(item.label);
            navigate(item.link);
          } else {
            // Map is not saved, prompt user to save or discard
            const confirmResult = window.confirm(
              "Your map is unsaved. Are you sure you want to leave?"
            );
            if (confirmResult === true) {
              setActive(item.label);
              navigate(item.link);
            }
          }
        } else {
          setActive(item.label);
          navigate(item.link);
        }
      }}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </Link>
  ));

  const handleSetOpenedNavbar = () => {
    if (setOpenedNavbar) {
      setOpenedNavbar();
    }
  };

  return (
    <MUINavbar
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ base: 300 }}
      height={"100vh"}
      p="md"
      style={{
        backgroundColor: "#414042",
        position: "fixed",
        left: 0,
        zIndex: 9,
        top: 0,
        width: '300px',
        minWidth: '300px',
        overflow: 'auto'
      }}
      classNames={{
        hidden: extraClassName ? extraClassName : "nav-main",
      }}
    >
      <MUINavbar.Section grow>
        <Group className={classes.header} position="apart">
          <div className="logo-wrapper">
            <Link
              to="/"
              onClick={() => {
                handleSetOpenedNavbar();
              }}
            >
              <Image
                src={
                  process.env.REACT_APP_PUBLIC_FOLDER + "Scopomap_Wide_Dark.svg"
                }
                // src="images/Scopomap_Wide_Dark.svg"
                width={160}
                height={34}
                alt="scopomap-logo"
              />
            </Link>
          </div>
        </Group>
        {/* {pathname === `/` && (
        )} */}
        {(pathname === `/` ||
          pathname === `/${id}` ||
          /^(\/LGA\/[0-9])/.test(pathname) ||
          /^(\/SAL\/[0-9])/.test(pathname)) && (
          <>
            <Box className={classes.section}>
              <Text className={classes.sectionTitle}>Map Tools</Text>
              <div className="links-wrapper">{mainLinks1}</div>
            </Box>

            <Box className={classes.section}>
              <Text className={classes.sectionTitle}>Display Options</Text>
              <div className={classes.switchWrapper}>
                <div className={classes.switchWrapperInner}>
                  <div className={classes.displayOptionItem}>
                    <BuildingStore className={classes.displayOptionIcon} />
                    <div className={classes.displayOptionContent}>
                      <Text className={classes.switchLabel}>
                        Display Businesses
                      </Text>
                      <Switch
                        size="sm"
                        classNames={{
                          track: classes.switchTrack,
                          thumb: classes.switchThumb,
                        }}
                        checked={state.showBusinessLocation}
                        onChange={toggleBusinessLocation}
                      />
                    </div>
                  </div>

                  <div className={classes.displayOptionItem}>
                    <MessageDots className={classes.displayOptionIcon} />
                    <div className={classes.displayOptionContent}>
                      <Text className={classes.switchLabel}>
                        Display Comments
                      </Text>
                      <Switch
                        size="sm"
                        classNames={{
                          track: classes.switchTrack,
                          thumb: classes.switchThumb,
                        }}
                        checked={state.showMarkers}
                        onChange={toggleMarkers}
                      />
                    </div>
                  </div>

                  <div className={classes.displayOptionItem}>
                    <ArrowsUpDown className={classes.displayOptionIcon} />
                    <div className={classes.benchmarkingContent}>
                      <div className={classes.benchmarkingHeader}>
                        <Text className={classes.switchLabel}>
                          Benchmarking
                        </Text>
                      </div>
                      <Radio.Group
                        value={state.benchmark}
                        onChange={setBenchmark}
                        size="sm"
                        className={classes.radioGroup}
                      >
                        <div className={classes.radioWrapper}>
                          <Radio
                            value="state"
                            label="State"
                            classNames={{ 
                              radio: classes.radio,
                              label: classes.radioLabel 
                            }}
                          />
                        </div>
                        <div className={classes.radioWrapper}>
                          <Radio
                            value="national"
                            label="National"
                            classNames={{ 
                              radio: classes.radio,
                              label: classes.radioLabel 
                            }}
                          />
                        </div>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </>
        )}

        {/* <Title
          className="maps-title"
          pt="xl"
          style={{ color: "#ffffff", fontSize: "20px" }}
        >
          Maps
        </Title> */}
        <Box className={classes.section}>
          <Text className={classes.sectionTitle}>Maps</Text>
          <div className="links-wrapper">
            {mapsLinks}
            <div
              className={cx(classes.link, {
                [classes.linkActive]: false,
              })}
              key={"Map Sets"}
              onClick={() => setMapSetsOpen(!isMapSetsOpened)}
            >
              <Polygon className={classes.linkIcon} />
              <span>Map Sets</span>
            </div>
            <Grid>
              <Grid.Col lg={2} sm={2} />
              <Grid.Col lg={10} sm={10}>
                <Collapse in={isMapSetsOpened}>
                  <Link
                    className={cx(classes.link, {
                      [classes.linkActive]: false,
                    })}
                    to={"/LGAs"}
                    key={"LGAs"}
                  >
                    <AddressBook className={classes.linkIcon} />
                    <span>LGAs</span>
                  </Link>
                  <Link
                    className={cx(classes.link, {
                      [classes.linkActive]: false,
                    })}
                    to={"/SALs"}
                    key={"SALs"}
                  >
                    <AddressBook className={classes.linkIcon} />
                    <span>Suburbs</span>
                  </Link>
                </Collapse>
              </Grid.Col>
            </Grid>
          </div>
        </Box>

        <Box className={classes.section}>
          <Text className={classes.sectionTitle}>Settings</Text>
          <div className="links-wrapper">
            {links}
            {(pathname === `/` ||
              pathname === `/${id}` ||
              /^(\/LGA\/[0-9])/.test(pathname) ||
              /^(\/SAL\/[0-9])/.test(pathname)) && (
              <a
                className={classes.link}
                key={"help"}
                onClick={(event) => {
                  localStorage.removeItem("tour");
                  dispatch({ type: "START" });
                  handleSetOpenedNavbar();
                }}
              >
                <Lifebuoy className={classes.linkIcon} />
                <span>Help</span>
              </a>
            )}
          </div>
        </Box>

        <Box className={classes.section}>
          <Text className={classes.sectionTitle}>Admin</Text>
          <div className="links-wrapper">
            {adminLinks}
          </div>
        </Box>
      </MUINavbar.Section>

      <MUINavbar.Section className={classes.footer}>
        <Group position="left">
          <Menu
            position="top-end"
            arrowPosition="side"
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            zIndex={1001}
          >
            <Menu.Target>
              <UserButton
                name={user?.username}
                image={""}
                email="hspoonlicker@outlook.com"
              />
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item icon={<Users size={14} />}>
                <Anchor href="/team" color="black">
                  Team
                </Anchor>
              </Menu.Item>

              <Menu.Item
                icon={<Logout size={14} />}
                onClick={() => {
                  dispatch({ type: "setCheck", payload: false });
                  logout();
                }}
              >
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </MUINavbar.Section>
    </MUINavbar>
  );
};
export default Navbar;
