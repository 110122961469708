import {
  Header,
  Group,
  Burger,
  createStyles,
  Image,
  Button,
  Breadcrumbs,
  Anchor,
  ActionIcon,
  Modal,
  TextInput,
  Tooltip,
  Flex,
  Text,
  ScrollArea,
  Stack,
  Paper,
  LoadingOverlay,
} from "@mantine/core";
import { FC, useState, useContext, useEffect, Fragment } from "react";
import { AdjustmentsHorizontal, Flame, Tool } from "tabler-icons-react";
import storeContext from "../store/store";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import useAnalyticsEventTracker from "../hooks/useGoogleAnalyticsTracker";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

interface HeaderMainProps {
  links: { link: string; label: string }[];
  toggleNavbar: () => void;
  toggleFiltersBar: () => void;
  toggleHeatMap: () => void;
  showLinks: boolean;
}
const HeaderMain: FC<HeaderMainProps> = ({
  links,
  toggleNavbar,
  toggleFiltersBar,
  toggleHeatMap,
  showLinks,
}) => {
  const [isFiltersBarActive, setIsFiltersBarActive] = useState(false);
  const [isHeatMapActive, setIsHeatMapActive] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [saveMapName, setSaveMapName] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);

  const { state, dispatch } = useContext<any>(storeContext);
  const { user, teamName } = useAuth();
  const { guid } = useParams();
  const gaEventTracker = useAnalyticsEventTracker("Home Page");
  const isDrawingNewPolygon = !(state.jobId > 0 && state.jobId.length > 0);

  const isLoading = state.summaryDataLoading || state.datarequeststate > 0;

  useEffect(() => {
    if (
      !isFiltersBarActive &&
      Object.keys(state.filterDataAlternative).length > 0
    ) {
      setIsFiltersBarActive(() => true);
      setIsHeatMapActive(false);
      toggleFiltersBar();
    }
  }, [state.filterDataAlternative]);

  const openSaveModal = () => {
    setSaveMapName(state.jobName);
    setOpenModal(true);

    if (state.polygons.length > 0) {
      // Initialize preview map after modal opens
      setTimeout(() => {
        const previewMap = new google.maps.Map(
          document.getElementById("save-preview-map") as HTMLElement,
          {
            zoom: 12,
            center: state.polygons[0]?.getPath().getArray()[0] || {
              lat: -33.8688,
              lng: 151.2093,
            },
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            draggable: true,
            scrollwheel: true,
          }
        );

        // Add polygons to preview map
        state.polygons.forEach((polygon: any) => {
          const previewPolygon = new google.maps.Polygon({
            paths: polygon.getPath(),
            strokeColor: "#FF8E16",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF8E16",
            fillOpacity: 0.35,
          });
          previewPolygon.setMap(previewMap);
        });

        // Fit bounds to show all polygons
        if (state.polygons.length > 0) {
          const bounds = new google.maps.LatLngBounds();
          state.polygons.forEach((polygon: any) => {
            polygon.getPath().forEach((coord: any) => {
              bounds.extend(coord);
            });
          });
          previewMap.fitBounds(bounds);
        }
      }, 100);
    }
  };

  const removeInfoWindowFromMapPolygons = () => {
    state.mapPolygons.forEach((polygon: any) => {
      const pPolygon = polygon.polygon;
      delete pPolygon.infoWindow;
    });
  };

  const saveMap = async (event: React.SyntheticEvent) => {
  
    if (!state.checkedOutCatalog || state.checkedOutCatalog.length === 0) {
      alert("Please select demographics settings before saving the map. Click the tool icon to add data points.");
      return;
    }

    event.preventDefault();

    if (saveMapName.length > 50) {
      return;
    }
    setOpenModal(false);
    setIsSaving(true);

    let formData = new FormData();
    let filtersData: any = {};
    let languages: any[] = [];
    let encodedCoords: any[] = [];

    try {
      state.polygons.forEach((polygon: any) => {
        encodedCoords.push(
          google.maps.geometry.encoding.encodePath(polygon.getPath())
        );
      });

      let annotationList: any[] = [];
      state.annotations.forEach((annotation: any) => {
        let annotationCoordinates = annotation["marker"].getPosition();
        annotationList.push({
          id: 0,
          lat: annotationCoordinates.lat(),
          lng: annotationCoordinates.lng(),
          comment: annotation["comment"],
        });
      });

      filtersData.languages = languages;

      Object.keys(state.filterChangeData).find((key: any) => {
        state.filtersDataToSave.forEach((item: string) => {
          let label = item.split(" ");
          let firstPart: string = label[0]?.toLowerCase();
          let secondPart: string = label[1]?.toLowerCase();
          let key1 = key.toLowerCase();
          if (item === "Weekly Income" || item === "Uni Degree") {
            if (key1.includes(secondPart)) {
              filtersData[key] = state.filterChangeData[key];
            }
            return;
          }

          if (item === "Units") {
            if (key1.includes("percentage")) {
              filtersData[key] = state.filterChangeData[key];
            }
            return;
          }

          if (key1.includes(firstPart)) {
            filtersData[key] =
              state.filterChangeData[key] ||
              state.filterChangeData?.languages[key];
          }

          if (key1 === "languages") {
            let langName = item.split(" ")[0];
            state.filterChangeData[key].forEach(
              (lang: { name: string; minValue: number; maxValue: number }) => {
                if (lang.name === langName) {
                  languages.push(lang);
                }
              }
            );
          }
          return null;
        });
        return null;
      });
      formData.append("job_id", state.jobId || 0);
      formData.append("job_name", saveMapName);
      formData.append("encoded_polygons", encodedCoords.join(","));
      formData.append("annotationList", JSON.stringify(annotationList));
      formData.append(
        "filter_data",
        JSON.stringify(state.filterDataAlternative)
      );
      formData.append(
        "demographics_settings",
        JSON.stringify(state.checkedOutCatalog)
      );

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}save_job/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${user.key}`,
        },
      });
      if (state.jobId != response.data.job_id)
        dispatch({ type: "addJobId", payload: response.data.job_id });
      try {
        let hrefParts = window.location.href.split("/");
        if (
          hrefParts?.length < 4 ||
          parseInt(hrefParts[3]) !== response.data.job_id
        )
          window.location.href = "/" + response.data.job_id.toString();
      } catch (e) {
        console.log(e);
      }

      let now = new Date();
      dispatch({
        type: "addUserMap",
        payload: {
          created: now,
          created_by: user.username,
          edited: now,
          id: response.data.job_id,
          job_name: saveMapName,
          last_edited_by: user.username,
        },
      });

      setIsSaving(false);
    } catch (e) {
      console.log(e);
      setIsSaving(false);
    }
  };

  const handleDemographicBuilderClick = () => {
    dispatch({ type: "setDemographicBuilderOpen", payload: true });
  };

  const teamNameToDisplay = () => {
    return (
      <Breadcrumbs
        styles={{
          breadcrumb: { color: "#fff" },
          separator: { color: "#fff" },
        }}
      >
        <Anchor href="/team" key="1">
          {/* Sydney City Council */}
          {teamName?.length > 20 ? teamName.slice(0, 21) + "..." : teamName}
        </Anchor>
        <Anchor
          className={"test"}
          href="#"
          key="2"
          sx={(theme) => ({
            [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
              width: "70px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          })}
        >
          {state.jobName?.length > 20
            ? state.jobName?.slice(0, 21) + "..."
            : state.jobName}
          {/* Inner West Boundary Map */}
        </Anchor>
      </Breadcrumbs>
    );
  };

  return (
    <>
      <LoadingOverlay
        visible={isSaving}
        loaderProps={{ size: "xl", color: "#FF8E16" }}
      />

      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        title="Save This Map"
        size="lg"
        overlayProps={{ blur: 2 }}
      >
        <Stack spacing="md">
          <form onSubmit={saveMap} style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "80%" }}>
              <TextInput
                value={saveMapName}
                onChange={(e) => setSaveMapName(e.target.value)}
                sx={{ width: "100%" }}
                placeholder="Map Name"
                required
                maxLength={50}
              />
              <Text
                size="xs"
                color={saveMapName.length > 45 ? "red" : "dimmed"}
                align="left"
                mt={4}
              >
                {saveMapName.length}/50 characters
              </Text>
            </div>
            <Button
              type="submit"
              color="#FF8E16"
              disabled={saveMapName.length > 50}
              sx={(theme) => ({
                marginLeft: "1rem",
                backgroundColor: theme.colors.allColors[7],
                "&:hover": {
                  backgroundColor: "#f76707",
                },
                "&:disabled": {
                  backgroundColor: theme.colors.gray[6],
                  cursor: "not-allowed",
                },
              })}
            >
              Save
            </Button>
          </form>

          {state.polygons.length > 0 ? (
            <Paper withBorder p="md">
              <Text weight={500} mb="xs">
                Map Preview
              </Text>
              <div
                style={{ height: "200px", width: "100%", position: "relative" }}
              >
                <div
                  id="save-preview-map"
                  style={{ height: "100%", width: "100%" }}
                ></div>
              </div>
            </Paper>
          ) : (
            <Text size="sm" color="dimmed">
              No polygons to preview
            </Text>
          )}

          <Paper withBorder p="md">
            <Text weight={500} mb="xs">
              Selected Demographics
            </Text>
            <ScrollArea h={200}>
              <Stack spacing="md">
                {Array.isArray(state.checkedOutCatalog) &&
                  state.checkedOutCatalog.map(
                    (category: any, index: number) => (
                      <div key={index}>
                        <Text weight={500} size="sm" color="dimmed">
                          {category.category_title}
                        </Text>
                        {category.sub_categories.map(
                          (subCategory: any, subIndex: number) => (
                            <div
                              key={subIndex}
                              style={{
                                marginLeft: "1rem",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Text size="sm" weight={500}>
                                {subCategory.sub_category_title}
                              </Text>
                              <Stack spacing={4} mt={4}>
                                {subCategory.filters
                                  .filter((filter: any) => filter.checked)
                                  .map((filter: any, filterIndex: number) => (
                                    <div
                                      key={filterIndex}
                                      style={{ marginLeft: "1rem" }}
                                    >
                                      <Text size="sm">
                                        • {filter.filter_title}
                                        {filter.top_answers &&
                                          ` (Top ${filter.top_answers})`}
                                      </Text>
                                    </div>
                                  ))}
                              </Stack>
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
              </Stack>
            </ScrollArea>
          </Paper>

          {state.filtersDataToSave.length > 0 && (
            <Paper withBorder p="md">
              <Text weight={500} mb="xs">
                Filter Values
              </Text>
              <ScrollArea h={150}>
                {state.filtersDataToSave.length > 0 ? (
                  <Stack spacing="xs">
                    {state.filtersDataToSave.map(
                      (item: string, index: number) => (
                        <Text key={index} size="sm">
                          {item}
                        </Text>
                      )
                    )}
                  </Stack>
                ) : (
                  <Text size="sm" color="dimmed">
                    No filters applied
                  </Text>
                )}
              </ScrollArea>
            </Paper>
          )}
        </Stack>
      </Modal>

      <Header
        height={{
          base: "8rem",
          xs: "7rem",
          lg: "6.625rem",
          xl: "5.625rem",
        }}
        sx={(theme) => ({
          display: "flex",
          width: "100%",
          padding: "0px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: theme.colors.allColors[2],
        })}
        fixed={false}
        id="header-main"
      >
        <Group
          sx={(theme) => ({
            display: "flex",
            gap: "3.75rem",
            alignItems: "center",
            flex: "1 0 0",
            [`@media (max-width: 92.5rem)`]: {
              gap: "1rem",
              justifyContent: "flex-start",
            },
          })}
        >
          <Flex align="center" gap="1.5rem">
            <Burger
              opened={false}
              onClick={() => {
                toggleNavbar();
              }}
              size="sm"
              color="white"
              id="toggle-menu"
            />

            <div
              style={{ cursor: "pointer", width: "10rem", height: "2.125rem" }}
              onClick={() => {
                if (!isDrawingNewPolygon) {
                  dispatch({ type: "resetJobId" });
                  dispatch({ type: "resetSummaryStats" });
                  window.polygons = [];
                }
              }}
            >
              <Link to="/">
                <Image
                  src={
                    process.env.REACT_APP_PUBLIC_FOLDER +
                    "Scopomap_Wide_Dark.svg"
                  }
                  alt="scopomap-logo"
                />
              </Link>
            </div>
          </Flex>

          {showLinks && (
            <Flex
              style={{
                display: "flex",
                gap: "1.5rem",
                alignItems: "flex-start",
              }}
            >
              <Group
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  gap: "0",
                }}
              >
                <ActionIcon
                  disabled={isLoading}
                  sx={(theme) => ({
                    display: "flex",
                    width: "3.75rem",
                    height: "2rem",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.625rem",
                    borderRadius: "0.25rem 0rem 0rem 0.25rem",
                    background: isFiltersBarActive ? "#FF7223" : "#333333",
                    "&:hover": {
                      background: isLoading ? undefined : "#FF7223",
                    },
                    "&:disabled": {
                      background: "#666666",
                      cursor: "not-allowed",
                      opacity: 0.6,
                    },
                  })}
                  onClick={() => {
                    if (!isLoading) {
                      setIsFiltersBarActive(() => !isFiltersBarActive);
                      setIsHeatMapActive(false);
                      toggleFiltersBar();
                      gaEventTracker("toggling filters", "toggling filters");
                      removeInfoWindowFromMapPolygons();
                    }
                  }}
                >
                  <AdjustmentsHorizontal size={24} color="white" />
                </ActionIcon>
                <ActionIcon
                  disabled={isLoading}
                  sx={(theme) => ({
                    display: "flex",
                    width: "3.75rem",
                    height: "2rem",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.625rem",
                    borderRadius: "0rem 0.25rem 0.25rem 0rem",
                    background: isHeatMapActive ? "#FF7223" : "#333333",
                    "&:hover": {
                      background: isLoading ? undefined : "#FF7223",
                    },
                    "&:disabled": {
                      background: "#666666",
                      cursor: "not-allowed",
                      opacity: 0.6,
                    },
                  })}
                  onClick={() => {
                    if (!isLoading) {
                      setIsHeatMapActive(() => !isHeatMapActive);
                      setIsFiltersBarActive(false);
                      toggleHeatMap();
                      gaEventTracker("toggling heatmap", "toggling heatmap");
                    }
                  }}
                >
                  <Flame size={24} color="white" />
                </ActionIcon>
              </Group>

              <Group
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  gap: "0",
                })}
              >
                <Tooltip label={!user?.key ? "Please log in to use the demographic builder" : "Demographic Builder"} withArrow>
                  <ActionIcon
                    disabled={isLoading || !user?.key}
                    sx={(theme) => ({
                      display: "flex",
                      width: "3.75rem",
                      height: "2rem",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.625rem",
                      borderRadius: "0.25rem",
                      background: "#333333",
                      "&:hover": {
                        background: (isLoading || !user?.key) ? undefined : "#f76707",
                      },
                      "&:disabled": {
                        background: "#666666",
                        cursor: "not-allowed",
                        opacity: 0.6,
                      },
                    })}
                    onClick={() => {
                      if (!isLoading && user?.key) {
                        gaEventTracker(
                          "buildingDemographics",
                          "Demographic Builder"
                        );
                        handleDemographicBuilderClick();
                      }
                    }}
                  >
                    <Tool size={24} color="white" />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </Flex>
          )}
        </Group>

        {showLinks && (
          <>
            <Group
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.625rem",
                flex: "1 0 0",
                [`@media (max-width: ${theme.breakpoints.sm})`]: {
                  display: "none",
                },
              })}
            >
              {teamNameToDisplay()}
            </Group>

            <Flex
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
                flex: "1 0 0",
                [`@media (max-width: ${theme.breakpoints.sm})`]: {
                  gap: "0.625rem",
                },
              })}
            >
              <Group
                sx={(theme) => ({
                  display: "none",
                  [`@media (max-width: ${theme.breakpoints.sm})`]: {
                    display: "flex",
                  },
                })}
              >
                {teamNameToDisplay()}
              </Group>

              {guid ? null : (
                <Button
                  type="submit"
                  id="save-btn"
                  disabled={isLoading}
                  color="#FF8E16"
                  sx={(theme) => ({
                    backgroundColor: theme.colors.allColors[7],
                    "&:hover": {
                      backgroundColor: isLoading ? undefined : "#f76707",
                    },
                    "&:disabled": {
                      backgroundColor: "#666666",
                      cursor: "not-allowed",
                      opacity: 0.6,
                    },
                    minWidth: "130px",
                    [`@media (max-width: ${theme.breakpoints.sm})`]: {
                      minWidth: "0px",
                    },
                  })}
                  onClick={() => !isLoading && openSaveModal()}
                >
                  Save
                </Button>
              )}
            </Flex>
          </>
        )}
      </Header>
    </>
  );
};

export default HeaderMain;
