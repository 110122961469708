import {
  Avatar,
  Button,
  createStyles,
  Grid,
  Menu,
  Modal,
  Pagination,
  Space,
  TextInput,
} from "@mantine/core";
import { FC, useState, useEffect } from "react";
import { Table } from "@mantine/core";
import { Copy, Archive, Share, Trash, Search } from "tabler-icons-react";

import CreateNewMapModal from "./CreateNewMapModal";

import { useAuth } from "../hooks/useAuth";
import { useStore } from "../hooks/useStore";
import { useNavigate } from "react-router-dom";
import ShareMapModal from "./ShareMapModal";
import axios from "axios";
import ScopoLoader from "./ScopoLoader";

const useStyles = createStyles((theme) => ({
  upgradeNowBtn: {
    color: theme.colors.allColors[7],
    backgroundColor: theme.colors.allColors[0],

    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  createNewBtn: {
    color: theme.colors.allColors[0],
    backgroundColor: theme.colors.allColors[7],
    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  tableBody: {
    textAlign: "start",
  },
  boundaryMap: {
    display: "flex",
    alignItems: "center",
  },
  iconDiv: {
    marginRight: "17px",
  },
  userCreation: {
    display: "flex",
    alignItems: "center",
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.colors.allColors[2],
  },
  mapDetails: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  author: {
    color: theme.colors.allColors[8],
  },
  createdAt: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  updatedAt: {
    color: theme.colors.allColors[8],
  },
  imgDiv: {
    marginRight: "10px",
  },
  letterAvatar: {
    backgroundColor: theme.colors.allColors[3],
    color: "black",
  },
  menuItem: {
    color: "blue !important",
  },
  menu: {
    color: "blue !important",
  },
  loginTextSection: {
    width: "100%",
    height: "30%",
  },

  loginButtonSection: {
    width: "100%",
    height: "30%",
    justifyContent: "center",
    alignItems: "center",
  },

  inputText: {
    marginLeft: "20%",
    width: "60%",
  },

  loginButton: {
    backgroundColor: "blue",
    color: "white",
  },
}));

const MyArchivedMapsList: FC = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shareMapOpened, setShareMapOpened] = useState(false);
  const [sharedMapId, setSharedMapId] = useState(0);
  const [userMaps, setUserMaps] = useState([]);
  const [usersFilterMaps, setUsersFilterMaps] = useState([]);
  const { user, teamName } = useAuth();

  const { state, dispatch } = useStore();
  const [activePage, setActivePage] = useState(1);
  const PER_PAGE = 5;

  const navigate = useNavigate();

  

  useEffect(() => {
    setUserMaps(state.userMaps);
    setUsersFilterMaps(state.userMaps);
  }, [state.userMaps]);

  useEffect(() => {
    const getArchivedMapList = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}team_archived_maps/`,
          {
            headers: {
              Authorization: `token ${user.key}`,
            },
          }
        );
    
        dispatch({ type: "setUserMaps", payload: response.data.team_maps });
      } catch(err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    getArchivedMapList();
  }, [dispatch, user.key]);

  function unarchiveMap(mapId: string) {
    setUserMaps([]);
    let formData = new FormData();
    formData.append("map_id", mapId.toString());
    formData.append("archived_flag ", "False");
    const response = axios
      .post(
        `${process.env.REACT_APP_BASE_URL}set_map_archive_flag/`,
        formData,
        {
          headers: {
            Authorization: `token ${user.key}`,
          },
        }
      )
      .then((response) => {
        let unarchivedUserMaps: any[] = [];
        state.userMaps.forEach(function (userMap: any) {
          if (userMap.id != parseInt(mapId)) unarchivedUserMaps.push(userMap);
        });
        dispatch({ type: "setUserMaps", payload: unarchivedUserMaps });
      });
  }

  const handleChange = (value: string) => {
    console.log(value);
    if (value.length >= 3) {
      const newUserMaps = userMaps.filter((ele: any) =>
        ele.job_name.toLowerCase().includes(value.toLowerCase())
      );
      setUsersFilterMaps(newUserMaps);
    }

    if (value.length === 0) {
      setUsersFilterMaps(userMaps);
    }
  };

  // menu JSX
  const menu = (
    <Menu
      styles={{
        itemIcon: { color: "#414042" },
        itemLabel: { color: "#414042" },
      }}
    >
      <Menu.Item icon={<Copy size={14} />}>Duplicate</Menu.Item>
      <Menu.Item icon={<Archive size={14} />}>Archive</Menu.Item>
      <Menu.Item icon={<Share size={14} />}>Share</Menu.Item>
    </Menu>
  );
  // table head JSX
  const ths = (
    <tr>
      <th>MAP</th>
      <th>CREATED</th>
      <th>UPDATED</th>
      <th></th>
    </tr>
  );

  const rows = [...usersFilterMaps]
    .splice((activePage - 1) * PER_PAGE, PER_PAGE)
    .map((map: any) => (
      <tr key={map.id}>
        <td
          onClick={() => {
            dispatch({type: 'resetSummaryStats'})
            navigate(`/${map.id}`)
          }}
          style={{ cursor: "pointer" }}
        >
          <div className={classes.boundaryMap}>
            <div className={classes.iconDiv}> </div>
            <div className="info-map-div">
              <div className={classes.title}>{map.job_name}</div>
              <div>
                <div className={classes.mapDetails}></div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className={classes.userCreation}>
            <div className={classes.textDiv}>
              <span className={classes.author}>{map.created_by}</span>{" "}
              <span className={classes.createdAt}>
                {new Date(map.created).toLocaleDateString()}
              </span>
            </div>
          </div>
        </td>
        <td className={classes.updatedAt}>
          <div className={classes.userCreation}>
            <div className={classes.textDiv}>
              <span className={classes.author}>{map.last_edited_by}</span>{" "}
              <span className={classes.createdAt}>
                {new Date(map.edited).toLocaleDateString()}
              </span>
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <Button
            className={classes.upgradeNowBtn}
            onClick={() => {
              unarchiveMap(`${map.id}`);
            }}
          >
            Restore
          </Button>
        </td>
      </tr>
    ));

  const archivedMapsHeader = (
    <Grid mt="md">
      <Grid.Col
        lg={6}
        sm={4}
        style={{
          textAlign: "start",
          fontSize: "22px",
          fontWeight: "bold",
        }}
      >
        {teamName} - Archived Map List
      </Grid.Col>
      <Grid.Col lg={3} sm={4}>
      </Grid.Col>
    </Grid>
  );

  if (loading) {
    return (
      <>
        {archivedMapsHeader}
        <ScopoLoader rows={4}/>
      </>
    );
  }
  return (
    <>
      {archivedMapsHeader}
      <Space h="lg" />
      <Space h="lg" />
      {state.userMaps.length > 0 && (
        <div>
          <Grid>
            <Grid.Col lg={3}>
              <TextInput
                radius="lg"
                placeholder="Search"
                icon={<Search size={18} strokeWidth={2} color={"black"} />}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Grid.Col>
          </Grid>
          <div className="table-responsive">
            <Table
              className="table-inner"
              verticalSpacing="xl"
              captionSide="bottom"
              mb="xl"
              mt="sm"
              pb="lg"
            >
              <thead>{ths}</thead>
              {userMaps.length && (
                <tbody className={classes.tableBody}>{rows}</tbody>
              )}
            </Table>
          </div>
          <Pagination
            total={Math.ceil(state.userMaps.length / PER_PAGE)}
            position="center"
            className="pagination"
            value={activePage}
            onChange={setActivePage}
          />
        </div>
      )}

      <Modal
        centered
        padding={0}
        opened={shareMapOpened}
        onClose={() => setShareMapOpened(false)}
        title="Share this Map"
      >
        <ShareMapModal close={setShareMapOpened} sharedMapId={sharedMapId} />
      </Modal>

      <Modal
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        padding={0}
        withCloseButton={false}
        size="lg"
      >
        <CreateNewMapModal />
      </Modal>
    </>
  );
};

export default MyArchivedMapsList;
