import {
  Grid,
  Group,
  Text,
  createStyles,
  RangeSlider,
  Box,
  Divider,
  Flex,
} from "@mantine/core";
import { useContext, useState } from "react";
import storeContext from "../../store/store";
import fetchData from "./DataReference";

interface Filter {
  name: string;
  groupName: string;
  minValue: number;
  maxValue: number;
  minimumSelected: number;
  maximumSelected: number;
  units: string;
}

const useStyles = createStyles((theme) => ({
  filterMapKey: {
    backgroundColor: "#fff", // --color-white
    padding: "5px", // --padding-8xs
    borderRadius: "8px", // --br-5xs
    fontSize: "10px", // --font-size-3xs
    color: "#000", // --color-black
    textAlign: "left",
  },
  filterItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "flex-start",
    marginBottom: "5px", // --gap-8xs
  },
  filterLabel: {
    fontWeight: 300,
    fontSize: "14px", // --font-size-xs
    marginRight: "5px", // --gap-8xs
  },
  filterLabelTitle: {
    fontWeight: 700,
    fontSize: "14px", // --font-size-xs
    marginRight: "5px", // --gap-8xs
  },
  selectionText: {
    fontWeight: 500,
    fontSize: "12px", // --font-size-xs
  },
  poweredBy: {
    fontWeight: 200,
    fontSize: "14px", // --font-size-xs, adjust as necessary
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: "5px", // --gap-8xs, space between the "Powered by" text and the logo
    flexShrink: 0,
  },
  logo: {
    width: "auto",
    height: "24px",
    flexShrink: 0,
  },
  filtersApplied: {
    fontWeight: 700,
    fontSize: "16px", // --font-size-xs
    marginBottom: "10px", // --gap-3xs
  },
  sliderX: {
    marginBottom: "5px", // --gap-8xs
  },
  branding: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px", // --gap-3xs
  },
  separator: {
    marginTop: "5px", // --gap-8xs
  },
}));

const FilterMapKey = () => {
  const { classes } = useStyles();
  const { state, dispatch } = useContext<any>(storeContext);

  // group all the filters in the filterDataAlternative object based on the groupName
  const filterDataAlternative = state.filterDataAlternative;

  const generateFilterKeys = (filterDataAlternative: any) => {
    return filterDataAlternative.map((filter: any, index: number) => {
      if (filter) {
        // Cast the filter object to the Filter interface
        const typedFilter = filter as Filter;

        // Destructure from the filter object
        const {
          name: filterName,
          groupName: filterGroupName,
          minValue: selectedMinValue,
          maxValue: selectedMaxValue,
          maximumSelected,
          minimumSelected,
          units,
        } = typedFilter;

        // Use the state.summaryDataNewAPI to get the max and min values for RangeSliders
        let maxValue = maximumSelected;
        let minValue = minimumSelected;

        let prefix = " ";
        let postfix = " ";

        return (
          <Box className={classes.sliderX} key={index}>
            <Box className={classes.filterItem}>
              <Text className={classes.filterLabelTitle} component="span">
                {filterName}
              </Text>
              <Text className={classes.filterLabel} component="span">
                Selected Range {units == "%" ? "" : units}
                {selectedMinValue}
                {units != "%" ? "" : units} to {units == "%" ? "" : units}
                {selectedMaxValue}
                {units != "%" ? "" : units}
              </Text>
            </Box>
            <Box>
              <RangeSlider
                color="orange"
                min={minValue}
                max={maxValue}
                defaultValue={[selectedMinValue, selectedMaxValue]}
              />
            </Box>

            <Box className={classes.separator}>
              <Divider size="xs" variant="dotted" />
            </Box>
          </Box>
        );
      }
      return null;
    });
  };

  const splitFilterDataAlternative = (filterDataAlternative: any) => {
    // split the filterDataAlternative object into an array of arrays with max size 6
    const filterDataAlternativeArray = [];
    let tempArray: any[] = [];
    let count = 0;
    Object.entries(filterDataAlternative).forEach(([key, value]) => {
      if (count === 6) {
        filterDataAlternativeArray.push(tempArray);
        tempArray = [];
        count = 0;
      }
      tempArray.push(value);
      count++;
    });
    filterDataAlternativeArray.push(tempArray);
    return filterDataAlternativeArray;
  };

  return (
    <Box className={classes.filterMapKey}>
      <Text className={classes.filtersApplied}>Active Filters</Text>

      {/* Mapping filters */}
      <Flex direction="row" gap={"lg"}>
        {splitFilterDataAlternative(filterDataAlternative).map(
          (filterDataAlternativeArray, index) => (
            <Flex key={index} direction={"column"} w={"100%"}>
              {generateFilterKeys(filterDataAlternativeArray)}
            </Flex>
          )
        )}
      </Flex>

      {/* Branding Section */}
      <Box className={classes.branding}>
        <Text className={classes.poweredBy}>Powered by</Text>
        <img
          src="/images/scopomap-wide-light-1.svg"
          alt="ScopeMap Logo"
          className={classes.logo}
        />
      </Box>
    </Box>
  );
};

export default FilterMapKey;
