import axios from "axios";
import domtoimage from "dom-to-image";
import { AppContextType } from "../types";
import {
  FilterData,
  FilterDataWithResponses,
  Table,
  SubCategory,
} from "../components/filterbar-utils/filtertypes";
import ExportMapComponent from "../components/report-export-components/ExportMapComponents";
import { createRoot } from "react-dom/client";
import type { State } from "../types";
import { notifications } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";

interface MapProps {
  polygonList: any[];
  paddingPercent: number[];
  context: { state: State; dispatch: (action: any) => void };
}

interface ReportResponse {
  progress_id: string;
  status: "pending" | "processing" | "completed" | "failed";
}

interface Language {
  name: string;
}

interface TravelMethod {
  name: string;
}

const getMapSA1CSV = (context: { state: any; dispatch: any }) => {
  const { state, dispatch } = context;
  const sa1LevelRawData = getSA1LevelRawData(state);
  const rawData = getRawData(state);
  var dataText = "";
  dataText = "Summary Data\n";
  dataText += "Powered by Scopomap\n";
  dataText +=
    "SA1 Code,SA1 on Scopomap,Population Included,Median Age,Weekly Household Income,Unemployment Percentage,People with University Degree,Indigenous Population,Apartments Percentage,Rental Properties Percentage,Dwellings Owner Outright Percentage,Dwellings Owned with Mortgage Percentage," +
    state.summaryData.languages[0].name +
    "," +
    state.summaryData.languages[1].name +
    "," +
    state.summaryData.languages[2].name +
    "," +
    state.summaryData.methodsOfTravel[0].name +
    "," +
    state.summaryData.methodsOfTravel[1].name +
    "," +
    state.summaryData.methodsOfTravel[2].name +
    "," +
    state.summaryData.methodsOfTravel[3].name +
    "," +
    state.summaryData.methodsOfTravel[4].name +
    "\n";
  sa1LevelRawData.forEach((sa1: any) => {
    dataText +=
      sa1.sa1_code +
      ",https://app.scopomap.com.au/SA1/" +
      sa1.sa1_code +
      "," +
      sa1.population_count.toString() +
      "," +
      sa1.medianAge.toString() +
      "," +
      sa1.income.toString() +
      "," +
      sa1.unemployed.toString() +
      "," +
      sa1.uniDegree.toString() +
      "," +
      sa1.indigenous.toString() +
      "," +
      sa1.unitPercentage.toString() +
      "," +
      sa1.rentalProperties.toString() +
      "," +
      sa1.ownedOutright.toString() +
      "," +
      sa1.ownedWithMortgage.toString() +
      "," +
      (sa1[state.summaryData.languages[0].name] || 0).toString() +
      "," +
      (sa1[state.summaryData.languages[1].name] || 0).toString() +
      "," +
      (sa1[state.summaryData.languages[2].name] || 0).toString() +
      "," +
      (sa1[state.summaryData.methodsOfTravel[0].name] || 0).toString() +
      "," +
      (sa1[state.summaryData.methodsOfTravel[1].name] || 0).toString() +
      "," +
      (sa1[state.summaryData.methodsOfTravel[2].name] || 0).toString() +
      "," +
      (sa1[state.summaryData.methodsOfTravel[3].name] || 0).toString() +
      "," +
      (sa1[state.summaryData.methodsOfTravel[4].name] || 0).toString() +
      "\n";
  });
  return dataText;
};

const getNewAPIMapSummaryCSV = (context: { state: any; dispatch: any }) => {
  const { state, dispatch } = context;
  const rawData = state.summaryDataNewAPI;
  var dataText = "Summary Data\n";
  dataText += "Powered by Scopomap\n";
  dataText += "Category,Area,State Average,National Average\n";
  rawData.tables.forEach((table: any) => {
    dataText += table.category_title + "\n";
    table.sub_categories.forEach((subCategory: any) => {
      subCategory.filters.forEach((filter: any) => {
        const filterData = filter as FilterDataWithResponses;
        console.log(filter.filter_title);

        if (filterData.responses === undefined) {
          const simpleFilterData = filter as FilterData;
          dataText +=
            filterData.filter_title +
            "," +
            (simpleFilterData.value || 0 * 100) +
            "%," +
            (simpleFilterData.state || 0 * 100) +
            "%," +
            (simpleFilterData.national || 0 * 100) +
            "%\n";
        } else {
          dataText += filter.filter_title + "\n";
          filterData.responses.sort((a, b) => b.area - a.area);
          for (var i = 0; i < filterData.top_answers; i++) {
            dataText +=
              filterData.responses[i].filter_name +
              "," +
              filterData.responses[i].area * 100 +
              "%," +
              filterData.responses[i].state * 100 +
              "%," +
              filterData.responses[i].national * 100 +
              "%\n";
          }
        }
      });
    });
  });
  return dataText;
};

const getMapSummaryCSV = (context: { state: any; dispatch: any }) => {
  const { state, dispatch } = context;
  const rawData = getRawData(state);
  var dataText = "Summary Data\n";
  dataText += "Powered by Scopomap\n";

  // Header
  dataText = "Summary Data\n";
  dataText += "Powered by Scopomap\n";
  dataText += "Item,Area,State Average,National Average,Units\n";

  // At a glance
  dataText +=
    "Residential dwellings" + "," + rawData.residential.toString() + "\n";
  dataText += "Businesses" + "," + rawData.businesses.toString() + "\n";
  dataText += "Letterboxes" + "," + rawData.letterboxes.toString() + "\n";
  dataText += "Population" + "," + rawData.population.toString() + "\n";

  let summary_data = state.summaryDataNewAPI;
  summary_data.tables.forEach((table: any) => {
    let filterCount = 0;
    let categoryTitle = table.category_title;
    // dataText += '\n' + categoryTitle + '\n';
    table.sub_categories.forEach((subCategory: any) => {
      let subCategoryTitle = subCategory.sub_category_title;
      // dataText += ',' + subCategoryTitle + '\n';
      subCategory.filters.forEach((filter: any) => {
        filterCount++;
        let filterTitle = filter.filter_title;
        if (filter.responses === undefined)
          dataText +=
            filterTitle +
            "," +
            filter.value.toString() +
            "," +
            filter.state.toString() +
            "," +
            filter.national.toString() +
            "," +
            filter.units +
            "\n";
        else {
          // dataText += ',,' + filterTitle + '\n'
          let topResponses = filter.responses.slice(0, filter.top_answers);
          topResponses.forEach((topResponse: any) => {
            dataText +=
              filterTitle +
              " - " +
              topResponse.filter_name +
              "," +
              topResponse.area +
              "," +
              topResponse.state +
              "," +
              topResponse.national +
              "," +
              topResponse.units +
              "\n";
          });
        }
      });
    });
  });

  return dataText;
};

const processFilter = (
  filter: FilterData | FilterDataWithResponses,

  dataText: string
) => {
  if (!("responses" in filter)) {
    const { filter_title, value = 0, state = 0, national = 0, units } = filter;
    dataText += `${filter_title},${value},${state},${national},${units}\n`;
  } else {
    const { filter_title, responses, top_answers } = filter;
    const topResponses = responses.slice(0, top_answers);
    topResponses.forEach((response) => {
      dataText += `${filter_title} - ${response.filter_name},${response.area},${response.state},${response.national},%\n`;
    });
  }
};

const processSubCategory = (subCategory: SubCategory, dataText: string) => {
  subCategory.filters.forEach((filter) => processFilter(filter, dataText));
};

const processTable = (table: Table, dataText: string) => {
  dataText += table.category_title + "\n";
  table.sub_categories.forEach((subCategory) =>
    processSubCategory(subCategory, dataText)
  );
};

const downloadSummary = async (
  format: string,
  context: AppContextType,
  user: any
) => {
  // decompose context
  const { state } = context;


  var dataText = "Summary Data\n";

  if (format === "sa1s") {
    // console.log(JSON.stringify(state.mapPolygons));
    dataText = "CSV Report\n";
    dataText += "Powered by Scopomap\n";
    dataText += "SA1 Code,SA1 on Scopomap,Population Included,";
    let filterNameList: string[] = [];
    let summary_data = state.summaryDataNewAPI;
    summary_data.tables.forEach((table: any) => {
      table.sub_categories.forEach((subCategory: any) => {
        subCategory.filters.forEach((filter: any) => {
          if (filter.responses === undefined) {
            let filterName = filter.filter_name;
            let filterTitle = filter.filter_title;
            filterNameList.push(filterName);
            dataText += filterTitle + ",";
          } else {
            let topResponses = filter.responses.slice(0, filter.top_answers);
            topResponses.forEach((topResponse: any) => {
              let filterName = topResponse.filter_name;
              filterNameList.push(filterName);
              dataText += filterName + ",";
            });
          }
        });
      });
    });
    dataText += "\n";
    state.mapPolygons.forEach((sa1: any) => {
      console.log(JSON.stringify(sa1.data));
      dataText +=
        sa1.data.sa1_code +
        ",https://app.scopomap.com.au/SA1/" +
        sa1.data.sa1_code +
        "," +
        sa1.data.population_count.toString() +
        ",";
      filterNameList.forEach((filterName: string) => {
        if (Object.keys(sa1.data).indexOf(filterName) > -1)
          dataText += sa1.data[filterName].value.toString() + ",";
        else dataText += "0,";
      });
      dataText += "\n";
    });
    let filename = "SA1s.csv";
    var blob = new Blob([dataText], { type: "text/csv" });
    downloadDataAsFile(blob, filename);
    return;
  }

  // Remove Word report generation from here since it's now handled by downloadAsWord
  if (format === "Word") {
    throw new Error("Word report generation should use downloadAsWord instead");
  }

  if (format === "CSV") {
    const dataText = getMapSummaryCSV(context);
    const blob = new Blob([dataText], { type: "text/csv" });
    downloadDataAsFile(blob, "summary.csv");
  }
};

async function downloadAsWord(
  context: AppContextType,
  user: any,
  dispatch: any
): Promise<ReportResponse> {
  try {
    const { state } = context;
    const rawData = getRawData(state);
    const properties = {
      polygonList: state.polygons,
      paddingPercent: [25, 25, 25, 25],
      context: { state, dispatch },
    };

    console.log("Downloading map image");
    const mapImageString = await downloadMapAsBase64(state, properties);
    console.log(mapImageString);

    const formData = new FormData();
    formData.append("raw_data", JSON.stringify(rawData));
    formData.append("map_name", state.jobName);
    formData.append("summary_data", JSON.stringify(state.summaryDataNewAPI));
    formData.append("map_image_string", mapImageString ?? "");

    let response_data;
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}demographics/summary_report/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `token ${user.key}`,
        },
      });
      response_data = {
        progress_id: response.data.progress_id,
        status: response.data.status,
      };
    } catch (error: any) {
      if (
        error.response?.data?.error?.includes(
          "A report is already being generated"
        ) &&
        error.response?.data?.progress_id
      ) {
        response_data = {
          progress_id: error.response.data.progress_id,
          status: "processing",
        };
        notifications.update({
          id: "report-progress",
          loading: true,
          title: "Report Already in Progress",
          message: "Connecting to existing report generation...",
          autoClose: false,
        });
      } else {
        throw error;
      }
    }

    if (!response_data?.progress_id) {
      throw new Error("No progress ID received from server");
    }

    // Initialize progress
    dispatch({
      type: "setReportProgress",
      payload: {
        progress: 0,
        status: response_data.status,
        currentStep: "Starting report generation...",
        progress_id: response_data.progress_id,
      },
    });

    return response_data;
  } catch (error: any) {
    console.log(error);
    notifications.show({
      color: "red",
      title: "Error",
      message:
        error.response?.data?.error ||
        "Failed to generate report. Please try again.",
      icon: <X size="1rem" />,
      autoClose: 5000,
    });

    dispatch({
      type: "setReportProgress",
      payload: {
        progress: 0,
        status: "failed",
        currentStep: "Failed to start report generation",
        error_message:
          error.response?.data?.error || "Failed to start report generation",
      },
    });
    throw error;
  }
}

const downloadDataAsFile = (blob: Blob, filename: string) => {
  const navigator = window.navigator as any;
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

function getSA1LevelRawData(state: any) {
  let sa1s: any[] = [];
  state?.mapPolygons?.forEach((p: any) => {
    sa1s.push(p.data);
  });
  return sa1s;
}

function getRawData(state: any) {
  const rawData = {
    residential: state.letterBoxData?.residential_count || 0,
    businesses: state.businessesCount?.businesses_count || 0,
    letterboxes: state.letterBoxCountPAF?.letterbox_count_PAF,
    population: state.summaryDataNewAPI.population || 0,
    area: state.polygonsArea,
  };
  return rawData;
}

/**
 *
 *  downloadMapAsBase64(yourExportMapComponentProps)
 *.then(base64String => {
 *  // Use the base64String for whatever you need here
 *  console.log(base64String);
 *})
 *.catch(error => {
 *  // Handle the error here
 *  console.error(error);
 * });
 *
 * @param props
 * @returns
 */

const downloadMapAsBase64 = async (
  state: State,
  props: MapProps
): Promise<string | null> => {
  return new Promise<string | null>((resolve, reject) => {
    try {
      const exportMapComponent = document.createElement("div");
      exportMapComponent.setAttribute("id", "exportmapComponent");
      document.body.appendChild(exportMapComponent);

      const root = createRoot(exportMapComponent);
      root.render(<ExportMapComponent {...props} />);

      // Increased timeout to ensure map and images are fully loaded
      setTimeout(() => {
        const container = document.getElementById("exportmapComponent");
        
        if (!container) {
          document.body.removeChild(exportMapComponent);
          reject(new Error("Export map container not found"));
          return;
        }

        // Wait for all images to load
        const images = container.getElementsByTagName('img');
        const imagePromises = Array.from(images).map(img => {
          if (img.complete) return Promise.resolve(void 0);
          return new Promise<void>((resolve, reject) => {
            img.onload = () => resolve();
            img.onerror = () => {
              // Don't reject on image error, just log and continue
              console.warn('Failed to load image:', img.src);
              resolve();
            };
          });
        });

        // Once all images are loaded (or failed gracefully), generate the PNG
        Promise.all(imagePromises)
          .then(() => {
            return domtoimage.toPng(container, {
              quality: 0.95,
              bgcolor: '#ffffff',
              imagePlaceholder: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7', // 1x1 transparent GIF
            });
          })
          .then((dataUrl: string) => {
            document.body.removeChild(exportMapComponent);
            resolve(dataUrl);
          })
          .catch((error: Error) => {
            console.error('Error generating map image:', error);
            document.body.removeChild(exportMapComponent);
            reject(error);
          });
      }, 2000); // Increased timeout to 2 seconds

    } catch (error) {
      reject(
        error instanceof Error ? error : new Error("Unknown error occurred")
      );
    }
  });
};

export {
  downloadSummary,
  downloadMapAsBase64,
  getMapSA1CSV,
  getMapSummaryCSV,
  getNewAPIMapSummaryCSV,
  downloadAsWord,
};
