import { Badge, SimpleGrid, HoverCard, Text, Tooltip, Group, Box } from "@mantine/core";
import React, { useContext } from "react";
import { ArrowNarrowDown, ArrowNarrowUp } from "tabler-icons-react";

import storeContext from "../store/store";

function ValueAndComparisonToAverage(props: any) {
  const { state } = useContext<any>(storeContext);
  const {
    title,
    value,
    stateAverageDiff,
    nationalAverageDiff,
    prefixChar,
    suffixChar,
  } = props;

  function roundToTwo(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  function roundToOne(num: number) {
    return Math.round((num + Number.EPSILON) * 10) / 10;
  }

  function roundToInt(num: number) {
    return Math.round(num);
  }

  // Determine the rounded value based on the unit
  let displayValue;
  if (prefixChar === "$") {
    displayValue = roundToInt(value);
  } else if (prefixChar === "") {
    displayValue = roundToOne(value);
  } else {
    displayValue = roundToTwo(value);
  }
  // Calculate and truncate the benchmark differences, using ratios instead of deltas
  let stateDiffRatio = (value / stateAverageDiff - 1) * 100;
  let nationalDiffRatio = (value / nationalAverageDiff - 1) * 100;

  // truncate the decimal places to 2
  stateDiffRatio = roundToTwo(stateDiffRatio);
  nationalDiffRatio = roundToTwo(nationalDiffRatio);

  // Benchmark direction and color logic
  const getBenchmarkColor = (diffRatio: number) =>
    diffRatio >= 0 ? "#D3F9D8" : "#FF653257";
  const getBenchmarkTextColor = (diffRatio: number) =>
    diffRatio >= 0 ? "#2B8A3E" : "#FF6532";
  const getBenchmarkIcon = (diffRatio: number) =>
    diffRatio >= 0 ? (
      <ArrowNarrowUp size={11} style={{ display: "flex" }} strokeWidth={2} color="#2B8A3E" />
    ) : (
      <ArrowNarrowDown size={11} style={{ display: "flex" }} strokeWidth={2} color="#FF6532" />
    );

  return (
    <Box py={6} mb={2}>
      <SimpleGrid cols={2} spacing={4}>
        <Box>
          <Tooltip
            label={title}
            position="left"
            withArrow
            offset={5}
            transitionProps={{ transition: "slide-right", duration: 200 }}
            openDelay={200}
          >
            <Text size="sm" truncate lineClamp={1} sx={{ paddingRight: 4 }}>
              {title}
            </Text>
          </Tooltip>
        </Box>
        <Group position="right" spacing={6} noWrap>
          <Text weight={600} size="sm">
            {prefixChar}{displayValue}{suffixChar}
          </Text>
          {state.benchmark === "state" && (
            <HoverCard withinPortal>
              <HoverCard.Target>
                <Badge
                  variant="filled"
                  size="sm"
                  px={6}
                  sx={{
                    lineHeight: 1.5,
                    backgroundColor: getBenchmarkColor(stateDiffRatio),
                    color: getBenchmarkTextColor(stateDiffRatio),
                  }}
                  leftSection={getBenchmarkIcon(stateDiffRatio)}
                >
                  {stateDiffRatio === Infinity ? 0 : stateDiffRatio || 0}%
                </Badge>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">
                  State Value Benchmark: {prefixChar}{prefixChar === '$' ? roundToInt(stateAverageDiff) : roundToOne(stateAverageDiff)}{suffixChar}
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
          {state.benchmark === "national" && (
            <HoverCard withinPortal>
              <HoverCard.Target>
                <Badge
                  variant="filled"
                  size="sm"
                  px={6}
                  sx={{
                    lineHeight: 1.5,
                    backgroundColor: getBenchmarkColor(nationalDiffRatio),
                    color: getBenchmarkTextColor(nationalDiffRatio),
                  }}
                  leftSection={getBenchmarkIcon(nationalDiffRatio)}
                >
                  {nationalDiffRatio === Infinity ? 0 : nationalDiffRatio || 0}%
                </Badge>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">
                  National Value Benchmark: {prefixChar}{prefixChar === '$' ? roundToInt(nationalAverageDiff) : roundToOne(nationalAverageDiff)}{suffixChar}
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
        </Group>
      </SimpleGrid>
    </Box>
  );
}

export default ValueAndComparisonToAverage;
