import { useContext } from "react";
import {
  Progress,
  Text,
  Alert,
  Stack,
  Group,
  Button,
  Paper,
  Divider,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  Check,
  AlertCircle,
  Refresh,
  FileAnalytics,
  Download,
} from "tabler-icons-react";
import storeContext from "../store/store";
import axios from "axios";

interface ReportProgressProps {
  progress_id: string;
  user: any;
  onClose: () => void;
}

export function ReportProgress({
  progress_id,
  user,
  onClose,
}: ReportProgressProps) {
  const context = useContext(storeContext);
  if (!context) {
    throw new Error("ReportProgress must be used within a StoreProvider");
  }
  const { state, dispatch } = context;

  const progress = state.reportProgress || {
    status: "processing",
    currentStep: "Initializing report generation...",
    progress: 0,
    error_message: null,
  };

  const handleClose = () => {
    // If report is completed or failed, reset the state
    if (progress.status === "completed" || progress.status === "failed") {
      dispatch({ type: "resetReportState" });
    }
    onClose();
  };

  const handleDownload = async () => {
    if (!progress.report_id) {
      notifications.show({
        color: "red",
        title: "Download Failed",
        message:
          "Report ID not available. Please try using the Reports drawer.",
        icon: <AlertCircle size="1rem" />,
        autoClose: 5000,
      });
      return;
    }

    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}demographics/download-report/${progress.report_id}/`,
        responseType: "blob",
        timeout: 30000, // 30 second timeout
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document, */*",
          Authorization: `token ${user.key}`,
        },
      });

      // Get the filename from Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let filename = "demographic-report.docx";
      if (contentDisposition) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDisposition
        );
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      // Create blob URL and trigger download
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

      // Close modal and clean up state after successful download
      dispatch({ type: "resetReportState" });
      dispatch({
        type: "setModalState",
        payload: { modal: "reportGeneration", state: false },
      });
      onClose();
    } catch (error) {
      notifications.show({
        color: "red",
        title: "Download Failed",
        message:
          "Failed to download the report. Please try using the Reports drawer.",
        icon: <AlertCircle size="1rem" />,
        autoClose: 5000,
      });
    }
  };

  // Always render the progress UI
  return (
    <Paper p="md" radius="md" withBorder>
      {progress.status === "failed" ? (
        <Alert
          icon={<AlertCircle size={16} />}
          title="Report Generation Failed"
          color="red"
          variant="filled"
        >
          <Stack spacing="sm">
            <Text size="sm">
              {progress.error_message ||
                "An error occurred during report generation."}
            </Text>
            <Button
              leftIcon={<Refresh size={16} />}
              variant="white"
              onClick={() => window.location.reload()}
              size="sm"
            >
              Try Again
            </Button>
          </Stack>
        </Alert>
      ) : (
        <Stack spacing="md">
          <Group position="apart" mb="xs">
            <Group spacing="xs">
              <FileAnalytics size={20} />
              <Text weight={500}>
                {progress.status === "completed"
                  ? "Report Generated"
                  : "Generating Report"}
              </Text>
            </Group>
          </Group>

          <Progress
            value={progress.progress}
            size="xl"
            radius="xl"
            color="orange"
            striped
            animate
          />

          <Text size="sm" color="dimmed" align="center">
            {progress.currentStep}
          </Text>

          {progress.status === "completed" ? (
            <>
              <Alert
                icon={<Check size={16} />}
                title="Report Generated Successfully"
                color="green"
                variant="light"
              >
                Your report has been generated successfully. You can download it
                now or access it later through the Reports drawer.
              </Alert>
              <Divider my="sm" />
              <Group position="apart">
                <Button variant="subtle" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  color="orange"
                  leftIcon={<Download size={16} />}
                  onClick={handleDownload}
                >
                  Download Report
                </Button>
              </Group>
            </>
          ) : (
            <Text size="xs" color="dimmed" align="center">
              This may take a few minutes. You can close this modal and track
              progress in the Reports drawer.
            </Text>
          )}
        </Stack>
      )}
    </Paper>
  );
}
