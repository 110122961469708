import {
  Avatar,
  Button,
  createStyles,
  Grid,
  Menu,
  Modal,
  Pagination,
  Space,
  TextInput,
} from "@mantine/core";
import { FC, useState, useEffect } from "react";
import { Table } from "@mantine/core";
import { Copy, Archive, Share, Trash, Search } from "tabler-icons-react";

import CreateNewMapModal from "./CreateNewMapModal";

import { useAuth } from "../hooks/useAuth";
import { useStore } from "../hooks/useStore";
import { useNavigate } from "react-router-dom";
import ShareMapModal from "./ShareMapModal";
import axios from "axios";
import ScopoLoader from "./ScopoLoader";

const useStyles = createStyles((theme) => ({
  upgradeNowBtn: {
    color: theme.colors.allColors[7],
    backgroundColor: theme.colors.allColors[0],

    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  createNewBtn: {
    color: theme.colors.allColors[0],
    backgroundColor: theme.colors.allColors[7],
    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  tableBody: {
    textAlign: "start",
  },
  boundaryMap: {
    display: "flex",
    alignItems: "center",
  },
  iconDiv: {
    marginRight: "17px",
  },
  mapDetailsCell: {
    display: "flex",
    alignItems: "center",
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.colors.allColors[2],
  },
  mapDetails: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  mapCount: {
    color: theme.colors.allColors[8],
  },
  mapDetailsRow: {
    color: theme.colors.allColors[8],
  },
  imgDiv: {
    marginRight: "10px",
  },
  letterAvatar: {
    backgroundColor: theme.colors.allColors[3],
    color: "black",
  },
  menuItem: {
    color: "blue !important",
  },
  menu: {
    color: "blue !important",
  },
  loginTextSection: {
    width: "100%",
    height: "30%",
  },

  loginButtonSection: {
    width: "100%",
    height: "30%",
    justifyContent: "center",
    alignItems: "center",
  },

  inputText: {
    marginLeft: "20%",
    width: "60%",
  },

  loginButton: {
    backgroundColor: "blue",
    color: "white",
  },
}));

const LGAMapsSelectionTable: FC = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userFilterListOfLGAs, setUserFilterListOfLGAs] = useState([]);
  const [listOfLGAs, setListOfLGAs] = useState([]);

  const { state, dispatch } = useStore();
  const [activePage, setActivePage] = useState(1);
  const PER_PAGE = 20;

  const navigate = useNavigate();

  useEffect(() => {
    setUserFilterListOfLGAs(state.listOfLGAs);
    setListOfLGAs(state.listOfLGAs);
  }, [state.listOfLGAs]);

  useEffect(() => {
    const getLGAList = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}demographics/lga_list`
        );
        
        dispatch({ type: "setLGAList", payload: response.data });
        dispatch({ type: "emptyPolygons", payload: [] });
      } catch(err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    getLGAList();
  }, [dispatch]);

  function loadLGAMap(lgaCode16: string) {
    setListOfLGAs([]);
    let formData = new FormData();
    formData.append("lga_code16", lgaCode16.toString());
    const response = axios.get(
      `${process.env.REACT_APP_BASE_URL}demographics/load_lga/`,
    );
  }

  const handleChange = (value: string) => {
    console.log(value);
    if (value.length >= 3) {
      const newListOfLGAs = listOfLGAs.filter((ele: any) =>
        ele.lga_name.toLowerCase().includes(value.toLowerCase())
      );
      setUserFilterListOfLGAs(newListOfLGAs);
    }

    if (value.length === 0) {
      setUserFilterListOfLGAs(listOfLGAs);
    }
  };

  const ths = (
    <tr>
      <th>LGA Name</th>
      <th>State</th>
      <th>Area (km<sup>2</sup>)</th>
    </tr>
  );

  const rows = [...userFilterListOfLGAs]
    .splice((activePage - 1) * PER_PAGE, PER_PAGE)
    .map((lga: any) => (
      <tr key={lga.lga_code16}>
        <td
          onClick={() => {
            dispatch({type: 'resetSummaryStats'})
            navigate(`/LGA/${lga.lga_code16}`)
          }}
          style={{ cursor: "pointer" }}
        >
          <div className={classes.boundaryMap}>
            <div className={classes.iconDiv}> </div>
            <div className="info-map-div">
              <div className={classes.title}>{lga.lga_name}</div>
              <div>
                <div className={classes.mapDetails}></div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className={classes.mapDetailsCell}>
            <div className={classes.textDiv}>
              <span className={classes.mapCount}>{lga.state_name}</span>{" "}
            </div>
          </div>
        </td>
        <td className={classes.mapDetailsRow}>
          <div className={classes.mapDetailsCell}>
            <div className={classes.textDiv}>
              <span className={classes.mapCount}>{lga.area_sqkm}</span>{" "}
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <Button
            className={classes.upgradeNowBtn}
            onClick={() => {
              dispatch({type: 'resetSummaryStats'})
              navigate(`/LGA/${lga.lga_code16}`)
            }}
            >
            Load LGA
          </Button>
        </td>
      </tr>
    ));

  const archivedMapsHeader = (
    <Grid mt="md">
      <Grid.Col
        lg={6}
        sm={4}
        style={{
          textAlign: "start",
          fontSize: "22px",
          fontWeight: "bold",
        }}
      >
        LGAs
      </Grid.Col>
    </Grid>
  );

  if (loading) {
    return (
      <>
        {archivedMapsHeader}
        <ScopoLoader rows={4}/>
      </>
    );
  }
  return (
    <>
      {archivedMapsHeader}
      <Space h="lg" />
      <Space h="lg" />
      {state.listOfLGAs.length > 0 && (
        <div>
          <Grid>
            <Grid.Col lg={3}>
              <TextInput
                radius="lg"
                placeholder="Search"
                icon={<Search size={18} strokeWidth={2} color={"black"} />}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Grid.Col>
          </Grid>
          <div className="table-responsive">
            <Table
              className="table-inner"
              verticalSpacing="xl"
              captionSide="bottom"
              mb="xl"
              mt="sm"
              pb="lg"
            >
              <thead>{ths}</thead>
              {listOfLGAs.length && (
                <tbody className={classes.tableBody}>{rows}</tbody>
              )}
            </Table>
          </div>
          <Pagination
            total={Math.ceil(state.listOfLGAs.length / PER_PAGE)}
            position="center"
            className="pagination"
            value={activePage}
            onChange={setActivePage}
          />
        </div>
      )}

    </>
  );
};

export default LGAMapsSelectionTable;
