import { FC, useCallback, useState } from "react";
import axios from "axios";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import GoogleBtn from "./GoogleBtn";
import { useAuth } from "../hooks/useAuth";
// import { useLocalStorage } from "../hooks/useLocalStorage";

type Props = {
  title: string;
};

const GLogin: FC<Props> = ({ title }: Props) => {
  const [showAlert, setShowAlert] = useState(false);
  const { login } = useAuth();

  type GoogleAuthData = {
    email: string;
    first_name: string;
    last_name: string;
  };

  const validateTokenAndObtainSession = async (data:GoogleAuthData, idToken:string) => {
    const login_data = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}google_login/`,
      data: data,
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json'
      },
    });

    login({
      key: login_data.data.auth_token,
      username: login_data.data.username,
      user_id: login_data.data.user_id,
    });
  };

  const onLoginSuccess = (response: any) => {
      const idToken = response.tokenId;
      const data = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName
      };
  
      validateTokenAndObtainSession(data, idToken)
        .then()
        .catch();
  };

  const onLoginFailure = (err: string) => {
    console.log("Failed login:", err);
  };

  return (
    <GoogleLogin
      clientId={`${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}`}
      render={(renderProps) => <GoogleBtn onClick={renderProps.onClick} title={title} />}
      onSuccess={onLoginSuccess}
      onFailure={onLoginFailure}
      cookiePolicy={"single_host_origin"}
      isSignedIn={true}
    />
  );
};

const GLogout = () => {
  const { logout } = useAuth();
  const onLogoutSuccess = () => {
    logout();
  };
  return (
    <GoogleLogout
      clientId={`${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}`}
      buttonText="Logout"
      onLogoutSuccess={onLogoutSuccess}
    ></GoogleLogout>
  );
};

export { GLogin, GLogout };
