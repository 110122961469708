import { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

// Define the steps
const TOUR_STEPS = [
  {
    target: ".create-ist-map-btn",
    content: (
      <div className="step-3">
        <h4
          style={{
            textAlign: "center",
            margin: 0,
            padding: 0,
            color: "#FF8E16",
          }}
        >
          Create Map!
        </h4>
        <div>Click on button to create your first map.</div>
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
  },
];

// Define our state
const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Set up the reducer function
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

// Define the Tour component
export const MyMapListTour = () => {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    if (!localStorage.getItem("maplistTour")) {
      dispatch({ type: "START" });
    }
  }, []);

  const callback = (data: any) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
    window.localStorage.setItem(
      "maplistTour",
      JSON.stringify({ action, index, type, status })
    );
  };

  // const startTour = () => {
  //   dispatch({ type: "RESTART" });
  // };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          tooltipFooter: {
            margin: 0,
            padding: 0,
          },
          tooltipContent: {
            padding: "18px 10px",
          },

          buttonBack: {
            marginRight: 10,
            backgroundColor: "#FF8E16",
            color: "#ffffff",
            borderRadius: "3px",
          },
          buttonNext: {
            backgroundColor: "#FF8E16",
          },
        }}
        locale={{
          last: "End tour",
        }}
      />
    </>
  );
};

export default MyMapListTour;
