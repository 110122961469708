import { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Drawer,
  Text,
  Group,
  Stack,
  Card,
  Badge,
  Button,
  TextInput,
  Select,
  Pagination,
  LoadingOverlay,
  ActionIcon,
  Progress,
  Tooltip,
  Loader,
  Skeleton,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  Search,
  Download,
  FileAnalytics,
  SortDescending,
  AlertCircle,
  Check,
  Clock,
  Refresh,
  X,
} from "tabler-icons-react";
import { useAuth } from "../hooks/useAuth";
import storeContext from "../store/store";

interface Report {
  id: string;
  name: string;
  description: string;
  report_type: string;
  created_time: string;
  last_edited_time: string;
  file_size: number;
  download_count: number;
  last_downloaded: string;
  progress: {
    status: "pending" | "processing" | "completed" | "failed";
    progress: number;
    error_message: string | null;
  };
}

interface PaginationData {
  count: number;
  num_pages: number;
  current_page: number;
  page_size: number;
  has_next: boolean;
  has_previous: boolean;
  next_page: number | null;
  previous_page: number | null;
}

interface ReportsDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export function ReportsDrawer({ opened, onClose }: ReportsDrawerProps) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState<Report[]>([]);
  const [pagination, setPagination] = useState<PaginationData | null>(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<string | null>(null);
  const [sort, setSort] = useState<string | null>("created_time");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [page, setPage] = useState(1);
  const context = useContext(storeContext);
  if (!context) {
    throw new Error("ReportsDrawer must be used within a StoreProvider");
  }
  const { state } = context;

  const isReportInProgress = state.reportProgress?.status === 'processing' && 
    Array.isArray(reports) && !reports.some(report => report.id === state.reportProgress?.progress_id);

  const fetchReports = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        page: page.toString(),
        page_size: "10",
        ...(search && { search }),
        ...(status && { status }),
        ...(sort && { sort, order }),
      });

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}demographics/reports/?${params}`,
        {
          headers: {
            Authorization: `token ${user.key}`,
          },
        }
      );

      if (response.data?.results) {
        setReports(response.data.results);
        setPagination(response.data.pagination);
      } else {
        setReports([]);
        setPagination(null);
      }
    } catch (error) {
      console.error('Failed to fetch reports:', error);
      notifications.show({
        color: "red",
        title: "Error",
        message: "Failed to load reports",
        icon: <X size="1rem" />,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (opened) {
      fetchReports();
    }
  }, [opened, page, search, status, sort, order]);

  // Add listener for report completion
  useEffect(() => {
    const handleReportComplete = () => {
      if (opened) {
        fetchReports();
      }
    };

    window.addEventListener('refreshReportsDrawer', handleReportComplete);
    return () => {
      window.removeEventListener('refreshReportsDrawer', handleReportComplete);
    };
  }, [opened]);

  // Add polling for in-progress reports
  useEffect(() => {
    if (!opened || !isReportInProgress) return;

    const interval = setInterval(fetchReports, 5000); // Poll every 5 seconds
    return () => clearInterval(interval);
  }, [opened, isReportInProgress]);

  const handleDownload = async (reportId: string) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}demographics/download-report/${reportId}/`,
        responseType: "blob",
        timeout: 30000, // 30 second timeout
        headers: {
          Accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document, */*",
          Authorization: `token ${user.key}`,
        },
      });

      // Get the filename from Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let filename = "demographic-report.docx";
      if (contentDisposition) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDisposition
        );
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      // Create blob URL and trigger download
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

      // Update download count locally
      setReports(prevReports => 
        prevReports.map(report => 
          report.id === reportId 
            ? { ...report, download_count: report.download_count + 1 }
            : report
        )
      );

    } catch (error) {
      console.error("Download error:", error);
      let errorMessage = "Failed to download the report";

      if (axios.isAxiosError(error)) {
        if (error.code === "ECONNABORTED") {
          errorMessage = "Download timed out. Please try again.";
        } else if (error.response?.status === 406) {
          errorMessage = "Report format not supported";
        } else if (error.response?.status === 400) {
          const blob = error.response.data as Blob;
          const text = await blob.text();
          errorMessage = text || "Report not ready or not found";
        } else if (error.response?.status === 404) {
          errorMessage = "Report not found";
        } else if (error.response?.status === 403) {
          errorMessage = "Not authorized to download this report";
        }
      }

      notifications.show({
        color: "red",
        title: "Download Failed",
        message: errorMessage,
        icon: <AlertCircle size="1rem" />,
        autoClose: 5000,
      });
    }
  };

  const formatFileSize = (bytes: number) => {
    const mb = bytes / (1024 * 1024);
    return `${mb.toFixed(1)} MB`;
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "completed":
        return "green";
      case "processing":
        return "orange";
      case "failed":
        return "red";
      default:
        return "gray";
    }
  };

  const ReportCardSkeleton = () => (
    <Card shadow="sm" p="md">
      <Stack spacing="md">
        <Group position="apart">
          <Skeleton height={20} width="40%" />
          <Skeleton height={20} width={60} />
        </Group>
        <Skeleton height={8} width="100%" radius="xl" />
        <Group spacing="xs">
          <Skeleton height={16} width="30%" />
          <Skeleton height={16} width="20%" />
          <Skeleton height={16} width="25%" />
        </Group>
      </Stack>
    </Card>
  );

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={
        <Group spacing="md">
          <FileAnalytics size={20} />
          <Text>Generated Reports</Text>
          {(loading || isReportInProgress) && (
            <Loader size="sm" color="orange" />
          )}
        </Group>
      }
      padding="md"
      size="xl"
      position="right"
    >
      <Stack spacing="md">
        <Group grow>
          <TextInput
            placeholder="Search reports..."
            icon={<Search size={14} />}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <Select
            placeholder="Filter by status"
            value={status}
            onChange={setStatus}
            data={[
              { value: "", label: "All" },
              { value: "completed", label: "Completed" },
              { value: "processing", label: "Processing" },
              { value: "failed", label: "Failed" },
            ]}
          />
          <Select
            placeholder="Sort by"
            value={sort}
            onChange={setSort}
            data={[
              { value: "created_time", label: "Creation Date" },
              { value: "download_count", label: "Downloads" },
              { value: "file_size", label: "File Size" },
            ]}
            rightSection={
              <ActionIcon
                onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
              >
                <SortDescending
                  size={14}
                  style={{
                    transform: order === "asc" ? "rotate(180deg)" : "none",
                  }}
                />
              </ActionIcon>
            }
          />
        </Group>

        <Stack spacing="sm">
          {loading ? (
            <>
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <ReportCardSkeleton key={`skeleton-${i}`} />
              ))}
            </>
          ) : (
            <>
              {isReportInProgress && (
                <Card shadow="sm" p="md">
                  <Group position="apart" mb="xs">
                    <Text weight={500}>Report in Progress</Text>
                    <Badge color="orange">Processing</Badge>
                  </Group>
                  <Progress
                    value={state.reportProgress?.progress || 0}
                    size="sm"
                    color="orange"
                    striped
                    animate
                    mb="sm"
                  />
                  <Text size="sm" color="dimmed">
                    {state.reportProgress?.currentStep || 'Generating report...'}
                  </Text>
                </Card>
              )}
              
              {reports.map((report) => (
                <Card key={report.id} shadow="sm" p="md">
                  <Group position="apart" mb="xs">
                    <Text weight={500}>{report.name}</Text>
                    <Badge color={getStatusColor(report.progress.status)}>
                      {report.progress.status}
                    </Badge>
                  </Group>

                  {report.progress.status === "processing" && (
                    <Progress
                      value={report.progress.progress}
                      size="sm"
                      color="orange"
                      striped
                      animate
                      mb="sm"
                    />
                  )}

                  <Group spacing="xs" position="apart">
                    <Group spacing="xs">
                      <Tooltip label="Created">
                        <Group spacing={4}>
                          <Clock size={14} />
                          <Text size="sm" color="dimmed">
                            {formatDate(report.created_time)}
                          </Text>
                        </Group>
                      </Tooltip>
                      <Text size="sm" color="dimmed">
                        •
                      </Text>
                      <Text size="sm" color="dimmed">
                        {formatFileSize(report.file_size)}
                      </Text>
                      <Text size="sm" color="dimmed">
                        •
                      </Text>
                      <Text size="sm" color="dimmed">
                        {report.download_count} downloads
                      </Text>
                    </Group>

                    {report.progress.status === "completed" && (
                      <Button
                        variant="light"
                        size="xs"
                        leftIcon={<Download size={14} />}
                        onClick={() => handleDownload(report.id)}
                      >
                        Download
                      </Button>
                    )}

                    {report.progress.status === "failed" && (
                      <Tooltip
                        label={report.progress.error_message || "Generation failed"}
                      >
                        <Text size="sm" color="red">
                          {report.progress.error_message}
                        </Text>
                      </Tooltip>
                    )}
                  </Group>
                </Card>
              ))}

              {reports.length === 0 && !loading && (
                <Text align="center" color="dimmed">
                  No reports found
                </Text>
              )}
            </>
          )}
        </Stack>

        {pagination && (
          <Group position="center" mt="md">
            <Pagination
              total={pagination.num_pages}
              value={pagination.current_page}
              onChange={setPage}
              size="sm"
              withEdges
              getItemProps={(page) => ({
                component: 'button',
                disabled: page === pagination.current_page,
              })}
            />
            <Text size="sm" color="dimmed">
              {pagination.count} reports total
            </Text>
          </Group>
        )}
      </Stack>
    </Drawer>
  );
}
