import React, { useState } from "react";
import { AppShell, createStyles, Drawer, useMantineTheme } from "@mantine/core";

import HeaderMain from "../components/HeaderMain";
import FiltersBar from "../components/FiltersBar";
import Navbar from "../components/Navbar";
import HeatMap from "../components/HeatMap";
import useElemetHeight from "../hooks/useElementHeight";

const dummyLinks = [
  { link: "/about", label: "Features" },
  { link: "/pricing", label: "Pricing" },
  { link: "/learn", label: "Learn" },
  { link: "/community", label: "Community" },
];

const useStyles = createStyles((theme) => ({
  appShellMain: {
    background:
      theme.colorScheme === "dark"
        ? theme.colors.allColors[1]
        : theme.colors.allColors[0],
    position: "relative",
  },
}));

const AppHeader = ({ children, extraClassNames }: any) => {
  const theme = useMantineTheme();
  const [openFiltersBar, setOpenFiltersBar] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [openHeatMap, setOpenHeatMap] = useState(false);
  const elementHeight = useElemetHeight();
  const { classes } = useStyles();

  const toggleNavbar = () => {
    setOpenNavbar(() => !openNavbar);
  };

  const toggleFiltersBar = () => {
    if (openHeatMap) {
      setOpenHeatMap(() => false);
    }
    setOpenFiltersBar(() => !openFiltersBar);
  };

  const toggleHeatMap = () => {
    if (openFiltersBar) {
      setOpenFiltersBar(() => false);
    }
    setOpenHeatMap(() => !openHeatMap);
  };

  return (
    <AppShell
      padding={0}
      style={{ paddingLeft: openNavbar ? 0 : "0" }}
      classNames={{
        main: `${classes.appShellMain} ${extraClassNames?.main}`,
        body: `${extraClassNames?.body}`,
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed={false}
      navbar={
        <>
          {" "}
          <Drawer
            size={300}
            opened={openNavbar}
            onClose={() => setOpenNavbar(false)}
            overlayProps={{
              opacity: 0.25,
              color: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#292A2C"
            }}
            classNames={{
              body: "customDrawer",
              close: "drawerCloseButton",
            }}
          >
            <Navbar opened={false} />
          </Drawer>
          {openFiltersBar ? <FiltersBar /> : undefined}
          {openHeatMap ? <HeatMap /> : undefined}
        </>
      }
      header={
        <HeaderMain
          links={dummyLinks}
          toggleNavbar={toggleNavbar}
          toggleFiltersBar={toggleFiltersBar}
          toggleHeatMap={toggleHeatMap}
          showLinks={false}
        />
      }
    >
      {children}
    </AppShell>
  );
};

export default AppHeader;
