// Modal preview of map export

import {
  Group,
  createStyles,
  Image,
  Button,
  Modal,
  Divider,
  Checkbox,
  Text,
  Title,
} from "@mantine/core";
import { FC, useState, useContext, useEffect } from "react";
import storeContext from "../../store/store";
import { downloadAsImage } from "./ComponentExporter";
import ExportMapComponent from "./ExportMapComponents";
import HeatMapKey from "./HeatMapKey";
import FilterMapKey from "./FilterMapKey";

const ExportMapModal = (props: any) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const [mapTitle, setMapTitle] = useState<boolean>(true);
  const mapType = state.viewExportMapModal.type;

  const onClose = () => {
    dispatch({
      type: "setViewExportMapModal",
      payload: { visible: false, type: "" },
    });
  };

  const exportMap = () => {
    downloadAsImage("export-window", "export");
    onClose();
  };

  const useStyle = createStyles((theme) => ({
    exportContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      position: "relative",
      backgroundColor: "transparent",
      pointerEvents: "none",
    },
    buttonbar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: "50px",
      width: "100%",
      padding: "10px",
      backgroundColor: "#f8f9fa",
      border: "1px solid #dee2e6",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
    },
    mapBounds: {
      width: `${state.heatMapExportSize.width}px`,
      height: `${state.heatMapExportSize.height}px`,
      justifyContent: "center",
      alignItems: "center",
      objectFit: "contain",
      //overflow: "hidden",
    },
    mapLegend: {
      position: "absolute",
      minWidth: "250px",
      zIndex: 15,
      bottom: "20px",
      right: "20px",
    },
    mapTitle: {
      position: "absolute",
      top: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "white",
      borderRadius: "8px",
      paddingLeft: "14px",
      paddingRight: "14px",
      paddingTop: "7px",
      paddingBottom: "7px",
      zIndex: 10,
    },
  }));

  const { classes } = useStyle();

  const polygonList: any[] = props.polygonList;

  // If we wanted to export the unsplit polygons
  // var polygonList: any[] = [];
  // state.polygons.forEach((poly: any) => {
  //     polygonList.push({polygon: poly});
  // });
  // If we wanted to export the split polygons
  // var polygonList: any[] = state.mapPolygons;

  return (
    <Modal
      title={`Export map`}
      size="auto"
      opened={state.viewExportMapModal && props.display}
      onClose={() => onClose()}
    >
      <div className={classes.buttonbar}>
        <Checkbox
          defaultChecked={mapTitle && state.jobName !== ""}
          label="Include map name"
          color="orange"
          disabled={state.jobName === ""}
          onChange={(e) => setMapTitle(e.target.checked)}
        />
        <Button
          type="submit"
          id="save-btn"
          color="#FF8E16"
          sx={(theme) => ({
            backgroundColor: theme.colors.allColors[7],
            "&:hover": {
              backgroundColor: "#f76707",
            },
            minWidth: "130px",
          })}
          onClick={() => exportMap()}
        >
          Export
        </Button>
      </div>

      <Group
        position="center"
        id="export-window"
        className={classes.exportContainer}
      >
        <div className={classes.mapBounds}>
          <ExportMapComponent
            polygonList={polygonList}
            paddingPercent={
              // padding is top, right, bottom, left
              mapType === "filter" &&
              Object.entries(state.filterDataAlternative).length < 6
                ? [25, 70, 25, 25]
                : [25, 25, 25, 25]
            }
          />
        </div>

        {mapTitle && state.jobName !== "" && (
          <div className={classes.mapTitle}>
            <Title order={3}>{`Filter Selection on ${state.jobName}`}</Title>
          </div>
        )}

        {/* Box in the bottom right Corner */}

        <div className={classes.mapLegend}>
          {mapType === "heat" && (
            <HeatMapKey selectedData={state.heatmapValue} />
          )}
        </div>

        <div
          className={
            Object.entries(state.filterDataAlternative).length < 6
              ? classes.mapLegend
              : ""
          }
        >
          {mapType === "filter" && <FilterMapKey />}
        </div>
      </Group>
    </Modal>
  );
};

export default ExportMapModal;
