import {
  Group,
  Text,
  Title,
  Container,
  createStyles,
  Grid,
  Stack,
} from "@mantine/core";
import React, { FC } from "react";
import PricingCard from "../components/PricingCard";

const useStyles = createStyles((theme) => ({
  pricingTop: {
    maxWidth: "530px",
    margin: "57px auto 50px",
    justifyContent: "center",
  },
  paragraph: {
    fontFamily: "DIN-2014-Light",
    fontSize: "28px",
    textAlign: "center",
  },
  body: {
    textAlign: "center",
  },
  titleTop: {
    fontFamily: "DIN-2014-Bold",
    textAlign: "center",
    color: "#414042",
    lineHeight: "40px",
    fontSize: "36px",
  },
  cardWrapper: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: "90%",
      margin: "0 auto",
    },
  },
}));

const PricingTable: FC = () => {
  const { classes } = useStyles();
  return (
    <Container size="xl">
      <Stack
        className={classes.pricingTop}
      >
        <Title order={1} className={classes.titleTop}>
          Choose the perfect plan for your business
        </Title>
        <Text size="xl" className={classes.paragraph}>
          Upgrade as your business grows
        </Text>
        <Text size="xl" className={classes.body}>
          Get one month free when choosing the annual subscription
        </Text>
      </Stack>
      <Grid className={classes.cardWrapper} gutter={30}>
        <PricingCard />
      </Grid>
    </Container>
  );
};

export default PricingTable;
