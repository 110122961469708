import {
  Flex,
  Text,
  UnstyledButton,
  ActionIcon,
  Menu,
  Input,
  createStyles,
  Group,
  Stack,
  useMantineTheme,
} from "@mantine/core";
import { TrashX, DotsVertical, Star, Edit, X } from "tabler-icons-react";
import {
  deleteDemographicsSettings,
  updateDemographicsSettings,
} from "../../../map-utils/filterbarAPI";
import { SavedFilter, FilterTable } from "../../../types";
import { useAuth } from "../../../hooks/useAuth";
import { useContext, useEffect, useState, SetStateAction } from "react";
import storeContext from "../../../store/store";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";

interface SidebarProps {
  listOfUserSavedFilters: SavedFilter[];
  selectedFilterName: string | null;
  setSelectedFilterName: (name: string) => void;
  filters: FilterTable[];
  setFilters: (filters: SetStateAction<FilterTable[]>) => void;
  setIsEditing: (value: SetStateAction<boolean>) => void;
  setDemographicsSettingsId: (id: string | null) => void; // Add this prop
  selectedFilterID: string | null;
}

const useStyles = createStyles((theme) => ({
  sidebarContainer: {
    minWidth: "300px",
    maxWidth: "400px",
    borderLeft: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.md,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      borderLeft: "none",
      borderTop: `1px solid ${theme.colors.gray[2]}`,
      minWidth: "100%",
      maxWidth: "100%",
    },
  },
  filterItem: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    marginBottom: theme.spacing.xs, // Space between items
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  filterItemSelected: {
    backgroundColor: theme.colors.gray[0],
    borderColor: theme.colors.orange[6], // Highlight selected
  },
  filterButton: {
    width: "100%",
    justifyContent: "space-between", // Space out text and icon
  },
  filterText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  menuDropdown: {
    // Styles for the menu dropdown
    padding: theme.spacing.xs,
    border: `1px solid ${theme.colors.gray[2]}`,
    boxShadow: theme.shadows.sm,
  },
  menuItem: {
    // Styles for menu items
    borderRadius: theme.radius.sm,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  actionButton: {
    color: theme.colors.gray[6],
    "&:hover": {
      backgroundColor: theme.colors.gray[1],
    },
  },
}));

const Sidebar: React.FC<SidebarProps> = ({
  listOfUserSavedFilters,
  selectedFilterName,
  setSelectedFilterName,
  filters,
  setFilters,
  setIsEditing,
  setDemographicsSettingsId,
  selectedFilterID,
}) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const [loading, setLoading] = useState(false);
  const [editingSettingId, setEditingSettingId] = useState<number | null>(null);
  const [editingSettingName, setEditingSettingName] = useState("");
  const { user } = useAuth();
  const { classes } = useStyles();
  const theme = useMantineTheme();

  // Find the currently selected filter object
  const selectedSetting = listOfUserSavedFilters.find(
    (setting) => setting.settings_name === selectedFilterName
  );

  useEffect(() => {
    if (
      state.mapAssociatedDemographicSettings !== "" &&
      state.mapAssociatedDemographicSettings !== null &&
      state.mapAssociatedDemographicSettings !== undefined &&
      Array.isArray(JSON.parse(state.mapAssociatedDemographicSettings))
    ) {
      // If coming from map load, set to "custom"
      setDemographicsSettingsId(null);
      resetFilters();
      setSelectedFilterName("custom");
      updateFiltersFromSetting(state.mapAssociatedDemographicSettings);
    } else if (!selectedSetting) {
      // If no filter is selected, try to apply the default
      const defaultFilter = listOfUserSavedFilters.find(
        (filter) => filter.organisation_default
      );
      if (defaultFilter) {
        setSelectedFilterName(defaultFilter.settings_name);
        setDemographicsSettingsId(
          defaultFilter.demographics_settings_id.toString()
        );
        updateFiltersFromSetting(defaultFilter.demographics_settings_json);
      }
    }
  }, [
    state.mapAssociatedDemographicSettings,
    listOfUserSavedFilters,
    selectedSetting,
    setSelectedFilterName,
    setDemographicsSettingsId,
  ]);

  // Handles selecting a filter preset
  const handleSelectFilter = (setting: SavedFilter) => {
    if (
      selectedSetting?.demographics_settings_id ===
      setting.demographics_settings_id
    ) {
      // Deselect if clicked again
      setSelectedFilterName("custom");
      setDemographicsSettingsId(null);
      resetFilters();
      return;
    }
    updateFiltersFromSetting(setting.demographics_settings_json);
    setSelectedFilterName(setting.settings_name);
    setDemographicsSettingsId(setting.demographics_settings_id.toString());
  };

  const resetFilters = () => {
    setFilters((currentFilters) =>
      currentFilters.map((table) => ({
        ...table,
        sub_categories: table.sub_categories.map((subCategory) => ({
          ...subCategory,
          filters: subCategory.filters.map((filter) => ({
            ...filter,
            checked: false,
          })),
        })),
      }))
    );
    setIsEditing(false);
  };

  // respond to changes in the selectedFilterID and make sure the selected filter is selectedSetting
  useEffect(() => {
    if (selectedFilterID) {
      const foundSetting = listOfUserSavedFilters.find(
        (setting) => setting.demographics_settings_id === +selectedFilterID
      );

      if (foundSetting) {
        setSelectedFilterName(foundSetting.settings_name);
        setDemographicsSettingsId(selectedFilterID);
        updateFiltersFromSetting(foundSetting.demographics_settings_json);
      }
    }
  }, [selectedFilterID]);

  const updateFiltersFromSetting = (demographicsSettingsJson: any) => {
    if (!demographicsSettingsJson) return;

    const settingFilters: FilterTable[] = JSON.parse(demographicsSettingsJson);

    setFilters((currentFilters) =>
      currentFilters.map((table) => ({
        ...table,
        sub_categories: table.sub_categories.map((subCategory) => ({
          ...subCategory,
          filters: subCategory.filters.map((filter) => ({
            ...filter,
            checked: settingFilters.some(
              (f) =>
                f.table_name === table.table_name &&
                f.category_title === table.category_title &&
                f.sub_categories.some(
                  (sub) =>
                    sub.sub_category_title === subCategory.sub_category_title &&
                    sub.filters.some(
                      (f) => f.filter_name === filter.filter_name
                    )
                )
            ),
          })),
        })),
      }))
    );
    setIsEditing(areFiltersDifferent(filters, settingFilters));
  };

  const areFiltersDifferent = (
    currentFilters: FilterTable[],
    settingFilters: FilterTable[]
  ) => {
    // Handle edge case where settingFilters is empty object or not an array
    if (!Array.isArray(settingFilters) || !settingFilters.length) {
      return true; // Consider different if settingFilters is invalid
    }

    const serializeFilters = (filters: any[]) =>
      JSON.stringify(
        filters.sort((a, b) => (a.table_name > b.table_name ? 1 : -1))
      );

    return (
      serializeFilters(currentFilters) !== serializeFilters(settingFilters)
    );
  };

  const handleDemographicDelete = async (setting: SavedFilter) => {
    const settingId = setting.demographics_settings_id;

    // Check if the setting is the default one
    if (setting.organisation_default) {
      showNotification({
        title: "Cannot Delete Default Filter",
        message:
          "The default filter cannot be deleted. To change the default, set another filter as default first.",
        color: "yellow",
      });
      return;
    }

    try {
      setLoading(true);
      await deleteDemographicsSettings(
        {
          demographicsSettingsId: `${settingId}`,
        },
        user.key
      );

      const updatedList = listOfUserSavedFilters.filter(
        (setting) => setting.demographics_settings_id !== settingId
      );

      // Dispatch updated list to context
      dispatch({
        type: "setUserDemographicSettingsList",
        payload: updatedList,
      });

      setSelectedFilterName("custom");
      setDemographicsSettingsId(null);

      showNotification({
        title: "Success",
        message: "Setting deleted successfully",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Error",
        message:
          error instanceof AxiosError
            ? error.response?.data
            : "Failed to delete setting. Please try again.",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDemographicEdit = async (setting: SavedFilter) => {
    const settingId = setting.demographics_settings_id;

    const foundSetting = listOfUserSavedFilters.find(
      (setting) => setting.demographics_settings_id === settingId
    );

    if (foundSetting) {
      if (foundSetting.organisation_default) {
        showNotification({
          title: "Cannot Edit Default Filter",
          message:
            "To update the default filter, please create a new filter and set it as default.",
          color: "yellow",
        });
        return;
      }

      setEditingSettingId(settingId);
      setEditingSettingName(foundSetting.settings_name);
    }
  };

  const handleSaveSettingName = async () => {
    if (!editingSettingId) return;

    const foundSetting = listOfUserSavedFilters.find(
      (setting) => setting.demographics_settings_id === editingSettingId
    );

    if (foundSetting) {
      if (foundSetting.organisation_default) {
        showNotification({
          title: "Cannot Update Default Filter",
          message:
            "To update the default filter, please create a new filter and set it as default.",
          color: "yellow",
        });
        setEditingSettingId(null);
        setEditingSettingName("");
        return;
      }

      const data = {
        demographicsSettingsJson: foundSetting.demographics_settings_json,
        settingsName: editingSettingName,
        organisationDefault: foundSetting.organisation_default
          ? "True"
          : "False",
        demographicsSettingsId: `${foundSetting.demographics_settings_id}`,
      };

      try {
        await updateDemographicsSettings(data, user.key);

        const updatedList = listOfUserSavedFilters.map((setting) =>
          setting.demographics_settings_id === editingSettingId
            ? { ...setting, settings_name: editingSettingName }
            : setting
        );

        dispatch({
          type: "setUserDemographicSettingsList",
          payload: updatedList,
        });

        setEditingSettingId(null);
        setEditingSettingName("");

        showNotification({
          title: "Success",
          message: "Setting name updated successfully",
          color: "green",
        });
      } catch (error) {
        showNotification({
          title: "Error",
          message: "Failed to update setting name. Please try again.",
          color: "red",
        });
      }
    }
  };

  const handleDemographicSetAsDefault = async (setting: SavedFilter) => {
    const settingId = setting.demographics_settings_id;

    const foundSetting = listOfUserSavedFilters.find(
      (setting) => setting.demographics_settings_id === settingId
    );

    updateDemographicsSettings(
      {
        demographicsSettingsJson: setting.demographics_settings_json,
        settingsName: setting.settings_name,
        organisationDefault: "True",
        demographicsSettingsId: `${settingId}`,
      },
      user.key
    );

    if (foundSetting) {
      const updatedList = listOfUserSavedFilters.map((setting) => {
        if (setting.demographics_settings_id === settingId) {
          return {
            ...setting,
            organisation_default: true,
          };
        } else {
          return {
            ...setting,
            organisation_default: false,
          };
        }
      });

      dispatch({
        type: "setListOfUserSavedFilters",
        payload: updatedList,
      });

      showNotification({
        title: "Success",
        message: "Setting set as default",
        color: "green",
      });
    }
  };

  const handleClearSelectedFilter = () => {
    setSelectedFilterName("custom");
    setDemographicsSettingsId(null);
    resetFilters();
  };

  return (
    <Stack className={classes.sidebarContainer} spacing="xs">
      {listOfUserSavedFilters.map((setting) => (
        <Flex
          key={setting.demographics_settings_id}
          className={`${classes.filterItem} ${
            selectedSetting?.demographics_settings_id ===
            setting.demographics_settings_id
              ? classes.filterItemSelected
              : ""
          }`}
          align="center"
        >
          <UnstyledButton
            onClick={() => handleSelectFilter(setting)}
            className={classes.filterButton}
            disabled={editingSettingId === setting.demographics_settings_id}
          >
            <Group spacing="xs" style={{ flex: 1 }}>
              {selectedSetting?.demographics_settings_id ===
                setting.demographics_settings_id && (
                <ActionIcon
                  className={classes.actionButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClearSelectedFilter();
                  }}
                >
                  <X size={16} />
                </ActionIcon>
              )}
              {editingSettingId === setting.demographics_settings_id ? (
                <Input
                  value={editingSettingName}
                  onChange={(e) => setEditingSettingName(e.target.value)}
                  onBlur={handleSaveSettingName}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSaveSettingName();
                    }
                  }}
                />
              ) : (
                <Text className={classes.filterText} truncate>
                  {setting.settings_name}
                </Text>
              )}
            </Group>
          </UnstyledButton>

          <Menu
            shadow="md"
            position="bottom-end"
            classNames={{ dropdown: classes.menuDropdown }}
          >
            <Menu.Target>
              <ActionIcon
                className={classes.actionButton}
                size="sm"
                onClick={(e) => e.preventDefault()} // Prevent click on button from triggering filter selection
              >
                <DotsVertical size={16} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={<Star style={{ color: "#FFD700" }} size={16} />}
                onClick={() => handleDemographicSetAsDefault(setting)}
                className={classes.menuItem}
              >
                Set as default
              </Menu.Item>
              <Menu.Item
                icon={<Edit style={{ color: "#4169E1" }} size={16} />}
                onClick={() => handleDemographicEdit(setting)}
                className={classes.menuItem}
              >
                Rename
              </Menu.Item>
              <Menu.Item
                icon={
                  <TrashX
                    style={{
                      color: setting.organisation_default
                        ? theme.colors.gray[4]
                        : theme.colors.red[6],
                    }}
                    size={16}
                  />
                }
                onClick={() => handleDemographicDelete(setting)}
                disabled={setting.organisation_default}
                className={classes.menuItem}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      ))}
    </Stack>
  );
};

export default Sidebar;
